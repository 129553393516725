<template>
  <layout active="/system/pins">
    <div class="wrapper">
      <panel title="System PINS">
        <div class="table-wrapper">
          <el-table :data="data" style="min-width: 100%" stripe>
            <el-table-column label="Type">
              <template slot-scope="scope">
                <span>{{ scope.row.type }}</span>
              </template>
            </el-table-column>

            <el-table-column label="PIN Code">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">{{ scope.row.code }}</span>
                <ex-input v-else v-model="scope.row.code" :validator="$v.data.$each.$iter[scope.$index].code" no-style :maxlength="4"/>
              </template>
            </el-table-column>

            <el-table-column label="Description">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">{{ scope.row.description }}</span>
                <ex-input v-else v-model="scope.row.description" :validator="$v.data.$each.$iter[scope.$index].description" no-style :maxlength="50"/>
              </template>
            </el-table-column>

            <el-table-column label="Actions" width="100" align="right">
              <template slot-scope="scope">
                <template v-if="!scope.row.id || scope.row.edit">
                  <el-button v-if="!scope.row.id" size="mini" type="danger" icon="el-icon-close" circle @click="remove(scope)" />
                  <el-button size="mini" type="success" icon="el-icon-check" circle @click="onSave(scope.$index)" />
                </template>
                <template v-else>
                  <el-button size="mini" icon="el-icon-edit" circle @click="openEdit(scope.$index)" />
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </panel>
    </div>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import { required, numeric, email, alpha, maxLength } from 'vuelidate/lib/validators'

import Panel from '../common/Panel'
import Layout from '../common/Layout'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'

export default {
  name: 'system-pins',

  components: {
    Panel,
    Layout,
    ExInput,
    ExSelect
  },

  data () {
    return {
      data: [],
    }
  },

  async created () {
    this.data = [...(await this.load({ params: { type: ['!=', 'System'] } }))]
  },
  
  methods: {
    ...mapActions({
      load: 'system/pin/load',
      create: 'system/pin/create',
      delete: 'system/pin/delete',
      update: 'system/pin/update'
    }),

    async onSave (index) {
      this.$v.data.$each.$iter[index].$touch()
      if(!this.$v.data.$each.$iter[index].$invalid) {
        const loading = this.$loading()
        let data = null
        if (!this.data[index].id) {
          const payload = {
            ...this.data[index],
          }
          data = await this.create(payload)
        } else {
          const payload = {
            id: this.data[index].id,
            payload: this.data[index]
          }
          data = await this.update(payload)
        }
        this.$set(this.data, index, data)
        loading.close()
      } else {
        this.$message.error('In order to save PIN, all fields need to be filled!')
      }
    },

    openEdit (index) {
      this.$set(this.data[index], 'edit', true)
    },

    remove (scope) {
      if (scope.row.id) {
        this.$set(this.data[scope.$index], 'edit', false)
      } else {
        this.data.splice(scope.$index, 1)
      }
    },
  },

  validations: {
    data: {
      required,
      $each: {
        code: { required, numeric },
        description: { }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
</style>