var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: "/system/integrations" } },
    [
      _c("panel", { attrs: { title: "APIX Connection Manager" } }, [
        _c(
          "form",
          { staticClass: "form apix" },
          [
            _c("ex-select", {
              attrs: {
                disabled: !_vm.updateApix,
                validator: _vm.$v.apixForm.apix_connect,
                options: _vm.yesNoOptions,
                label: "APIX Connect",
                classes: { disabled: !_vm.updateApix, apix: true }
              },
              model: {
                value: _vm.apixForm.apix_connect,
                callback: function($$v) {
                  _vm.$set(_vm.apixForm, "apix_connect", $$v)
                },
                expression: "apixForm.apix_connect"
              }
            }),
            _vm._v(" "),
            _c("ex-input", {
              attrs: {
                disabled: !_vm.updateApix,
                validator: _vm.$v.apixForm.version,
                label: "Version",
                classes: { disabled: !_vm.updateApix, apix: true }
              },
              model: {
                value: _vm.apixForm.version,
                callback: function($$v) {
                  _vm.$set(_vm.apixForm, "version", $$v)
                },
                expression: "apixForm.version"
              }
            }),
            _vm._v(" "),
            _c("ex-input", {
              attrs: {
                disabled: !_vm.updateApix,
                validator: _vm.$v.apixForm.username,
                label: "Username",
                classes: { disabled: !_vm.updateApix, apix: true }
              },
              model: {
                value: _vm.apixForm.username,
                callback: function($$v) {
                  _vm.$set(_vm.apixForm, "username", $$v)
                },
                expression: "apixForm.username"
              }
            }),
            _vm._v(" "),
            _c("ex-input", {
              attrs: {
                disabled: !_vm.updateApix,
                validator: _vm.$v.apixForm.code,
                label: "Key",
                classes: { disabled: !_vm.updateApix, apix: true }
              },
              model: {
                value: _vm.apixForm.code,
                callback: function($$v) {
                  _vm.$set(_vm.apixForm, "code", $$v)
                },
                expression: "apixForm.code"
              }
            }),
            _vm._v(" "),
            _c("ex-select", {
              attrs: {
                disabled: !_vm.updateApix,
                options: _vm.statusOptions,
                label: "Status",
                classes: { disabled: !_vm.updateApix, apix: true }
              },
              model: {
                value: _vm.apixForm.status,
                callback: function($$v) {
                  _vm.$set(_vm.apixForm, "status", $$v)
                },
                expression: "apixForm.status"
              }
            }),
            _vm._v(" "),
            _c("ex-input", {
              attrs: {
                disabled: !_vm.updateApix,
                validator: _vm.$v.apixForm.notes,
                label: "Notes",
                classes: { disabled: !_vm.updateApix, apix: true }
              },
              model: {
                value: _vm.apixForm.notes,
                callback: function($$v) {
                  _vm.$set(_vm.apixForm, "notes", $$v)
                },
                expression: "apixForm.notes"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button" },
              [
                !_vm.updateApix
                  ? _c("el-button", {
                      attrs: { icon: "el-icon-edit", circle: "" },
                      on: {
                        click: function($event) {
                          return _vm.updateApixConnection()
                        }
                      }
                    })
                  : _c("el-button", {
                      attrs: {
                        type: "success",
                        icon: "el-icon-check",
                        circle: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveApix()
                        }
                      }
                    })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }