<template>
  <div class="logo">
    <div class="logo-img"><img src="/assets/images/plug-logo-big.png"></div>
    <div class="logo-text-img"><img src="/assets/images/logo.png"></div>
    <h1>CENTRAL</h1>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  flex-direction: column;
  align-items:  center;
  justify-content:  center;
  margin-bottom: 25px;
  .logo-img {
    display: block;
    width: 82px;
    height: 82px;
    margin-bottom: 20px;
  }
  .logo-text-img {
    max-width: 185px;
    margin-bottom: 15px;
  }
  h1 {
    font-size: 21px;
    font-weight: 700;
    font-family:  'Roboto', sans-serif;
    color: #4c00be;
    letter-spacing:  12px;
  }
}
</style>