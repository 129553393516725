<template>
  <div class="login-page">
    <div class="container">
      <div class="content">
        <logo />

        <form @submit.prevent="login()">
          <input v-model="form.username" type="text" placeholder="Username" class="form-inp">
          <input v-model="form.password" type="password" placeholder="Password" class="form-inp">
          <button class="login-button">LOGIN</button>
        </form>

        <get-plugged-footer />
      </div>
    </div>

    <activate-account
      v-if="showActivateAcc"
      :user="showActivateAcc"
      @activated="onActivate()"
      @close="showActivateAcc = null" />

    <reset-password
      v-if="showResetPw"
      :user="showResetPw"
      @reset="onReset()"
      @close="showResetPw = null" />
  </div>
</template>

<script>
import helperMixin from '../common/helpers-mixin'

import Logo from '../common/Logo'
import ResetPassword from '../common/ResetPassword'
import ActivateAccount from '../common/ActivateAccount'
import GetPluggedFooter from '../common/GetPluggedFooter'

export default {
  name: 'Login',

  mixins: [helperMixin],

  components: {
    Logo,
    ResetPassword,
    ActivateAccount,
    GetPluggedFooter
  },

  data () {
    return {
      form: {
        username: null,
        password: null
      },
      showResetPw: null,
      showActivateAcc: null
    }
  },

  mounted () {
    const username = this.getUrl('username')
    const resetcode = this.getUrl('resetcode')
    if (username && resetcode) {
      this.form.username = username
      this.form.password = resetcode
      this.login()
    }
  },

  methods: {
    onReset () {
      this.showResetPw = null
      this.form.password = null
    },

    onActivate () {
      this.showActivateAcc = null
      this.form.username = null
      this.form.password = null
    },

    async login () {
      const response = await api.login(this.form.username, this.form.password)
      if (response.success) {
        this.$message.success('Successfully logged in.')
        location.href = "/"
      } else {
        if (response.activation) {
          this.showActivateAcc = response.user
        } else if (response.passwordChange) {
          this.showResetPw = response.user
        } else {
          this.$message.error('Bad username/password combination.')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  background-image: url("/assets/images/login-bg.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
  .content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  center;
    .logo {
      display: flex;
      flex-direction: column;
      align-items:  center;
      justify-content:  center;
      margin-bottom: 25px;
      .logo-img {
        display: block;
        width: 82px;
        height: 82px;
        margin-bottom: 20px;
      }
      .logo-text-img {
        max-width: 185px;
        margin-bottom: 15px;
      }
      h1 {
        font-size: 21px;
        font-weight: 700;
        font-family:  'Roboto', sans-serif;
        color: #4c00be;
        letter-spacing:  12px;
      }
    }
    form {
      width: 100%;
      max-width: 320px;
      display: flex;
      flex-direction: column;
      align-items:  center;
      min-height: 175px;
      margin-bottom: 85px;
      .form-inp {
        text-align: center;
      }
    }
    
    .plugged {
      text-align: center;
      h1 {
        font-size: 18px;
        color: #fff;
        font-weight: 900;
        font-family:  'Roboto', sans-serif;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        font-family:  'Roboto', sans-serif;
        margin-bottom: 20px;
        span a {
          color: #8432ff;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
