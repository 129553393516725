import single from './single'
import notes from './notes'
import myAccount from './my_account'
import subscriptions from './subscriptions'
import statements from './statements'
import invoices from './invoices'
import registration from './registration'


const state = {
  registrations: null
};

const actions = {
  async get ({ state, commit }, force = false) {
    if (state.registrations && !force) return state.registrations
    const data = (await api.list('ClientRegistrationFull', {}, ['created:DESC'])).items
    commit('setRegistrations', data)
    return data
  },

  async remove ({ commit }, id) {
    await api.delete('ClientRegistration', id)
    commit('removeRegistration', id)
  }
};

const mutations = {
  setRegistrations (state, data) {
    state.registrations = data
  },

  removeRegistration (state, id) {
    const idx = state.registrations.findIndex(item => item.id === id)
    state.registrations.splice(idx, 1)
  }
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
  modules: {
    single,
    my_account: myAccount,
    subscriptions,
    statements,
    notes,
    invoices,
    registration: registration,
  }
}