var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: "/dashboard" } }, [
    _vm._v("\n  Dashboard\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }