var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.userId
      ? _c("div", { staticClass: "form-wrapper" }, [
          _c(
            "div",
            { staticClass: "type" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Type",
                  validator: _vm.$v.form.type,
                  "no-style": "",
                  full: ""
                },
                model: {
                  value: _vm.form.type,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-edit",
                    type: _vm.chooseFileColor
                  },
                  on: {
                    click: function($event) {
                      return _vm.$refs.upload.click()
                    }
                  }
                },
                [_vm._v("Choose file")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-edit",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Upload")]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" }
      ],
      ref: "upload",
      attrs: { type: "file" },
      on: { change: _vm.uploadFile }
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-wrapper" },
      [
        _c(
          "el-table",
          {
            staticStyle: { "min-width": "100%" },
            attrs: { data: _vm.documents, stripe: "" }
          },
          [
            _c("el-table-column", {
              attrs: { label: "Type" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(scope.row.type) + "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Filename" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(scope.row.file) + "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Uploaded" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("date")(scope.row.createdAt)) +
                          "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Actions", align: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/download/ClientDocument/" + scope.row.id,
                            target: "_blank"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-download",
                                type: "primary"
                              }
                            },
                            [_vm._v("View / Download")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isUserAdmin
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-delete",
                                type: "danger"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.remove(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }