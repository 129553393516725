var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "New Client Profile Setup Worksheet", width: 1200 },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("h3", [_vm._v("GENERAL INFORMATION")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", [_vm._v("Registration ID")]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.data.id))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("label", [_vm._v("Registration Date")]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm._f("date")(_vm.data.created)))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-select", {
                  attrs: {
                    label: "Company Type",
                    validator: _vm.$v.form.companyType,
                    options: _vm.companyTypeOptions,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.form.companyType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyType", $$v)
                    },
                    expression: "form.companyType"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "mt" }, [
            _vm._v("CLIENT COMPANY INFORMATION")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-input", {
                  attrs: {
                    label: "Company Name",
                    validator: _vm.$v.form.company,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.form.company,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "company", $$v)
                    },
                    expression: "form.company"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-input", {
                  attrs: {
                    type: "email",
                    label: "Company Email",
                    validator: _vm.$v.form.email,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.form.email,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-input", {
                  attrs: {
                    label: "Company Phone",
                    validator: _vm.$v.form.companyPhone,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.form.companyPhone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyPhone", $$v)
                    },
                    expression: "form.companyPhone"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-select", {
                  attrs: {
                    label: "Country",
                    validator: _vm.$v.form.country,
                    options: _vm.countryOptions,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.selectedCountry,
                    callback: function($$v) {
                      _vm.selectedCountry = $$v
                    },
                    expression: "selectedCountry"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-input", {
                  attrs: {
                    label: "Address",
                    validator: _vm.$v.form.address,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.form.address,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "address", $$v)
                    },
                    expression: "form.address"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-input", {
                  attrs: {
                    label: "City",
                    validator: _vm.$v.form.city,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.form.city,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "city", $$v)
                    },
                    expression: "form.city"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-select", {
                  attrs: {
                    label: "State/Province",
                    disabled: !_vm.stateOptions,
                    validator: _vm.$v.form.country,
                    options: _vm.stateOptions,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.selectedState,
                    callback: function($$v) {
                      _vm.selectedState = $$v
                    },
                    expression: "selectedState"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("ex-input", {
                  attrs: {
                    label: "ZIP/Postal code",
                    validator: _vm.$v.form.postal,
                    full: "",
                    "no-style": ""
                  },
                  model: {
                    value: _vm.form.postal,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "postal", $$v)
                    },
                    expression: "form.postal"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "mt" }, [
            _vm._v("CLIENT CONTACT INFORMATION")
          ]),
          _vm._v(" "),
          _c("worksheet-contacts", {
            attrs: { v: _vm.$v },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          }),
          _vm._v(" "),
          _c("h3", { staticClass: "mt" }, [_vm._v("ACCOUNT SETTINGS")]),
          _vm._v(" "),
          _c("table", { staticClass: "form-table" }, [
            _c("tr", [
              _c("td", [_vm._v("Plugg Account")]),
              _vm._v(" "),
              _c(
                "td",
                { attrs: { colspan: "2" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.setCheckbox(
                            $event,
                            "pluggAccount",
                            "existing"
                          )
                        }
                      },
                      model: {
                        value: _vm.pluggAccount.existing,
                        callback: function($$v) {
                          _vm.$set(_vm.pluggAccount, "existing", $$v)
                        },
                        expression: "pluggAccount.existing"
                      }
                    },
                    [_vm._v("Link Existing - Account # ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inline" },
                    [
                      _c("ex-input", {
                        attrs: {
                          disabled: _vm.form.pluggAccount !== "existing",
                          validator: _vm.$v.form.existingAccount,
                          "no-style": ""
                        },
                        model: {
                          value: _vm.form.existingAccount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "existingAccount", $$v)
                          },
                          expression: "form.existingAccount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.setCheckbox($event, "pluggAccount", "new")
                        }
                      },
                      model: {
                        value: _vm.pluggAccount.new,
                        callback: function($$v) {
                          _vm.$set(_vm.pluggAccount, "new", $$v)
                        },
                        expression: "pluggAccount.new"
                      }
                    },
                    [_vm._v("New Account")]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Service Fees")]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.setCheckbox(
                            $event,
                            "servicesFees",
                            "pluggAccount"
                          )
                        }
                      },
                      model: {
                        value: _vm.servicesFees.pluggAccount,
                        callback: function($$v) {
                          _vm.$set(_vm.servicesFees, "pluggAccount", $$v)
                        },
                        expression: "servicesFees.pluggAccount"
                      }
                    },
                    [_vm._v("Plugg Account")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.setCheckbox(
                            $event,
                            "servicesFees",
                            "prepay"
                          )
                        }
                      },
                      model: {
                        value: _vm.servicesFees.prepay,
                        callback: function($$v) {
                          _vm.$set(_vm.servicesFees, "prepay", $$v)
                        },
                        expression: "servicesFees.prepay"
                      }
                    },
                    [_vm._v("Prepay")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.setCheckbox(
                            $event,
                            "servicesFees",
                            "postPay"
                          )
                        }
                      },
                      model: {
                        value: _vm.servicesFees.postPay,
                        callback: function($$v) {
                          _vm.$set(_vm.servicesFees, "postPay", $$v)
                        },
                        expression: "servicesFees.postPay"
                      }
                    },
                    [_vm._v("Post-Pay")]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Billing Cycle")]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.setCheckbox(
                            $event,
                            "billingCycle",
                            "weekly"
                          )
                        }
                      },
                      model: {
                        value: _vm.billingCycle.weekly,
                        callback: function($$v) {
                          _vm.$set(_vm.billingCycle, "weekly", $$v)
                        },
                        expression: "billingCycle.weekly"
                      }
                    },
                    [_vm._v("Weekly")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.setCheckbox(
                            $event,
                            "billingCycle",
                            "monthly"
                          )
                        }
                      },
                      model: {
                        value: _vm.billingCycle.monthly,
                        callback: function($$v) {
                          _vm.$set(_vm.billingCycle, "monthly", $$v)
                        },
                        expression: "billingCycle.monthly"
                      }
                    },
                    [_vm._v("Monthly")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-check", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }