var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("panel", { attrs: { title: "Client Notes" } }, [
        _c(
          "ul",
          _vm._l(_vm.notes, function(note) {
            return _c(
              "li",
              { key: note.id, staticClass: "note-item" },
              [
                _c("b", [
                  _vm._v(_vm._s(note.createdAt) + "\n                    "),
                  _c("em", { staticClass: "link" }, [
                    _vm._v(
                      _vm._s(note.createdBy.firstName) +
                        " " +
                        _vm._s(note.createdBy.lastName)
                    )
                  ]),
                  _vm._v(":\n                ")
                ]),
                _vm._v(
                  "\n                " +
                    _vm._s(note.note) +
                    "\n                "
                ),
                note.createdBy.id === _vm.currentUser.id
                  ? _c("el-button", {
                      staticClass: "floatr",
                      attrs: {
                        size: "mini",
                        type: "danger",
                        icon: "el-icon-delete",
                        circle: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.remove(note.id)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Add Note" } },
        [
          _c("ex-textarea", {
            attrs: {
              validator: _vm.$v.form.note,
              rows: "5",
              label: "Note",
              "no-style": "",
              full: ""
            },
            model: {
              value: _vm.form.note,
              callback: function($$v) {
                _vm.$set(_vm.form, "note", $$v)
              },
              expression: "form.note"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Create")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }