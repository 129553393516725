var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: _vm.active } },
    [
      _c(
        "tabs",
        {
          attrs: { header: _vm.headerData, tabs: _vm.tabs },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _vm._l(_vm.subTabs[_vm.activeTab], function(tab, idx) {
            return _c(
              "el-button",
              {
                key: tab,
                attrs: {
                  size: "mini",
                  type: _vm.activeSubTab === idx ? "primary" : null
                },
                on: {
                  click: function($event) {
                    _vm.activeSubTab = idx
                  }
                }
              },
              [_vm._v(_vm._s(tab))]
            )
          }),
          _vm._v(" "),
          _vm.setupDone && !_vm.data.userId
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    icon: "el-icon-check",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setup()
                    }
                  }
                },
                [_vm._v("Client Setup")]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.data
        ? _c(
            "panel",
            [
              _c(
                "keep-alive",
                [
                  _vm.activeTab === "Registration"
                    ? [
                        _vm.activeSubTab === 0 ? _c("client-info") : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 1
                          ? _c("setup-checklist", {
                              attrs: { id: _vm.id, registration: _vm.data }
                            })
                          : _vm._e()
                      ]
                    : _vm.activeTab === "General"
                    ? [_c("client-general-info")]
                    : _vm.activeTab === "Client Info"
                    ? [
                        _vm.activeSubTab === 0
                          ? _c("client-info-company")
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 1
                          ? _c("client-info-contacts")
                          : _vm._e()
                      ]
                    : _vm.activeTab === "Billing"
                    ? [
                        _vm.activeSubTab === 0 ? _c("billing-info") : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 1
                          ? _c("billing-my-account")
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 2
                          ? _c("billing-invoices")
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 3
                          ? _c("billing-statements")
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 4
                          ? _c("billing-subscriptions")
                          : _vm._e()
                      ]
                    : _vm.activeTab === "Documents"
                    ? _c("client-documents")
                    : _vm.activeTab === "Access"
                    ? [
                        _vm.activeSubTab === 0 ? _c("client-users") : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 1
                          ? _c("client-user-log")
                          : _vm._e()
                      ]
                    : _vm.activeTab === "Projects"
                    ? [
                        _vm.activeSubTab === 0
                          ? _c("project-scopes")
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSubTab === 1 ? _c("project-builds") : _vm._e()
                      ]
                    : _vm.activeTab === "Notes"
                    ? [_vm.activeSubTab === 0 ? _c("notes") : _vm._e()]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }