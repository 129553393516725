var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "layout",
        { attrs: { active: "/system/employees" } },
        [
          _c(
            "tabs",
            {
              attrs: { tabs: _vm.tabs },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _vm.activeTab === "Access"
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.isActive,
                          size: "mini",
                          icon: "el-icon-refresh"
                        },
                        on: {
                          click: function($event) {
                            return _vm.newCode()
                          }
                        }
                      },
                      [_vm._v("Reset Activation Code")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.isActive,
                          size: "mini",
                          icon: "el-icon-key"
                        },
                        on: {
                          click: function($event) {
                            return _vm.resetPassword()
                          }
                        }
                      },
                      [_vm._v("Reset Password")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.isActive,
                          size: "mini",
                          type: "danger",
                          icon: "el-icon-remove"
                        },
                        on: {
                          click: function($event) {
                            return _vm.block()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.isBlocked ? "Unblock" : "Block"))]
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "panel",
            [
              _vm.activeTab === "Employee Info"
                ? [
                    _c("div", { staticClass: "info-box" }, [
                      _c("h3", [_vm._v("General Information")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Account ID")]),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.data.uuid))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("First Name")]),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.data.firstName))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Last Name")]),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.data.lastName))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Job Title")]),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.data.job_title || "N/A"))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Role")]),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm._f("role")(_vm.data.role)))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-box" }, [
                      _c("h3", [_vm._v("Contact Information")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Email")]),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.data.email))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Phone")]),
                        _vm._v(" "),
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.data.phone) +
                              "-" +
                              _vm._s(_vm.data.phoneExt)
                          )
                        ])
                      ])
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.activeTab === "Access"
                ? [
                    _c("div", { staticClass: "info-box" }, [
                      _c("h3", [_vm._v("Access Information")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("span", [_vm._v("Activation Code")]),
                          _vm._v(" "),
                          _vm.isActive
                            ? _c("b", [_vm._v("N/A")])
                            : [
                                !_vm.isACExpired
                                  ? _c("b", [
                                      _vm._v(_vm._s(_vm.data.activationCode))
                                    ])
                                  : _c("b", { staticClass: "red" }, [
                                      _vm._v("Activation Code has expired!")
                                    ])
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Activation Code Expires")]),
                        _vm._v(" "),
                        !_vm.isActive &&
                        !_vm.isACExpired &&
                        _vm.activationExpires
                          ? _c("b", [
                              _vm._v(
                                _vm._s(_vm._f("date")(_vm.activationExpires))
                              )
                            ])
                          : _c("b", [_vm._v("N/A")])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Activation Date")]),
                        _vm._v(" "),
                        _vm.isActive
                          ? _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(_vm.data.activationDate, "PD")
                                )
                              )
                            ])
                          : _c("b", [_vm._v("N/A")])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Reset Request")]),
                        _vm._v(" "),
                        _vm.data.pwResetRequested
                          ? _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.data.pwResetRequested,
                                    "PD"
                                  )
                                )
                              )
                            ])
                          : _c("b", [_vm._v("N/A")])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Reset Complete")]),
                        _vm._v(" "),
                        _vm.data.resetPwDate
                          ? _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(_vm.data.resetPwDate, "PD")
                                )
                              )
                            ])
                          : _c("b", [_vm._v("N/A")])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Blocked")]),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.isBlocked ? "Yes" : "No"))])
                      ])
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }