<template>
  <div>
    <div class="table-wrapper">
      <div class="info-box">
        <h3>Client Assign</h3>
      </div>
      <el-table :data="[1]" v-loading="loading" style="min-width: 100%" stripe>
        <el-table-column label="Status">
          <div class="status">
            <i :class="data ? 'el-icon-s-claim success' : 'el-icon-s-release'" />
          </div>
        </el-table-column>

        <el-table-column label="Account Manager">
          <template v-if="!edit">
            <template v-if="!data">N/A</template>
            <template v-else>{{ data.accountManager.firstName }} {{ data.accountManager.lastName }}</template>
          </template>
          <ex-select v-else v-model="form.accountManager" :options="accountManagerUserOptions" no-style full />
        </el-table-column>

        <el-table-column label="Assigned On">
          <template v-if="!data">N/A</template>
          <template v-else>{{ data.accountManagerDate | date }}</template>
        </el-table-column>

        <el-table-column label="Project Manager">
          <template v-if="!edit">
            <template v-if="!data">N/A</template>
            <template v-else>{{ data.clientServicesRep.firstName }} {{ data.clientServicesRep.lastName }}</template>
          </template>
          <ex-select v-else v-model="form.clientServicesRep" :options="pmOptions" no-style full />
        </el-table-column>

        <el-table-column label="Assigned On">
          <template v-if="!data">N/A</template>
          <template v-else>{{ data.clientServicesRepDate | date }}</template>
        </el-table-column>

        <el-table-column v-if="!registration.userId" label="Actions" align="right" width="200">
          <el-button v-if="!edit" size="mini" type="primary" @click="edit = true">{{ data ? 'Edit ' : '' }}Assign</el-button>
          <template v-if="edit">
            <el-button v-if="edit || data" :disabled="$v.form.$invalid" size="mini" type="success" icon="el-icon-check" @click="save()">{{ data ? 'Edit' : 'Complete Assign' }}</el-button>
            <el-button v-if="edit && data" size="mini" @click="edit = false">Cancel</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import ExSelect from '../../common/Select'

import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ClientAssign',

  components: {
    ExSelect
  },

  data () {
    return {
      type: 'ClientAssign',
      edit: false,
      
      form: {
        accountManager: null,
        clientServicesRep: null
      },

      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      getUsersByType: 'users/getUsersByType'
    }),

    projectManagers () {
      return this.getUsersByType('project_manager')
    },

    accountManagers () {
      return this.getUsersByType('sales')
    },

    data () {
      return this.registration[this.type] || null
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    pmOptions () {
      return this.projectManagers.map(user => {
        return {
          id: user.id,
          text: `${user.firstName} ${user.lastName}`
        }
      })
    },

    accountManagerUserOptions () {
      return this.accountManagers.map(user => {
        return {
          id: user.id,
          text: `${user.firstName} ${user.lastName}`
        }
      })
    }
  },

  watch: {
    data (data) {
      this.form.accountManager = data.accountManager.id
      this.form.clientServicesRep = data.clientServicesRep.id
    }
  },

  methods: {
    ...mapActions({
      createChecklist: 'client/single/createChecklist',
      updateChecklist: 'client/single/updateChecklist'
    }),

    async save () {
      if (!this.$v.$invalid) {
        this.loading = true
        if (this.data) {
          const am = this.form.accountManager !== this.data.accountManager.id
          const cs = this.form.clientServicesRep !== this.data.clientServicesRep.id
          if (am || cs) {
            const payload = {}
            if (am) {
              payload.accountManager = this.form.accountManager
              payload.accountManagerDate = 'CURRENT_TIMESTAMP'
            }
            if (cs) {
              payload.clientServicesRep = this.form.clientServicesRep
              payload.clientServicesRepDate = 'CURRENT_TIMESTAMP'
            }

            this.updateChecklist({ id: this.data.id, type: this.type, payload })
          }
        } else {
          const payload = {
            ...this.form,
            clientRegId: this.registration.id,
            accountManagerDate: 'CURRENT_TIMESTAMP',
            clientServicesRepDate: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id
          }
          this.createChecklist({ type: this.type, payload })
        }
        this.edit = false
        this.loading = false
      }
    },
  },

  validations: {
    form: {
      accountManager: { required },
      clientServicesRep: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
}
</style>