<template>
  <modal
    title="Project Build and Schedule A Pricing"
    :width="1200"
    @close="$emit('close')">
    <div class="tabs">
      <el-button
        :type="activeTab ? 'default' : 'primary'"
        class="tab"
        @click="activeTab = 0">
        Project Scope
      </el-button>
      <el-button
        :type="!activeTab ? 'default' : 'primary'"
        class="tab"
        @click="activeTab = 1">
        Schedule A Pricing
      </el-button>
    </div>

    <project-build-form
      v-show="activeTab === 0"
      v-model="form"
      :validator="$v"
      :edit="edit" />

    <schedule-pricing-form
      v-show="activeTab === 1"
      v-model="form"
      :validator="$v" />

    <project-tracker ref="tracker" :trackers="edit ? edit.trackers : []" />

    <div
      v-if="edit"
      class="text-center mt-20">
      Status: <ex-select v-model="form.status" :validator="$v.form.status" :options="projectStatuses" class="m-0" no-style />
    </div>

    <div class="buttons">
      <el-button icon="el-icon-check" type="primary" @click="save()">Submit</el-button>
      <el-button @click="$emit('close')">Cancel</el-button>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import schedulePricingMixin from '../setup-checklist/schedule-pricing-mixin'

import Modal from '../../common/Modal'
import ExSelect from '../../common/Select'
import ProjectTracker from './ProjectTracker'
import ProjectBuildForm from './ProjectBuildForm'
import SchedulePricingForm from '../setup-checklist/SchedulePricingForm'

export default {
  components: {
    Modal,
    ExSelect,
    ProjectTracker,
    ProjectBuildForm,
    SchedulePricingForm
  },

  mixins: [ schedulePricingMixin ],

  props: {
    edit: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    form: {
      start_date: null,
      end_date: null,
      name: null,
      scope_of_work: null,
      customizations: null,
      client_to_provide: null,
      additional_notes: null,
      status: null,      
      hosted: false,
      dev_domain: null,
      dev_username: null,
      dev_password: null,
      staging_domain: null,
      staging_username: null,
      staging_password: null,
      live_domain: null,
      live_username: null,
      live_password: null
    },

    keys: ['start_date', 'end_date', 'name', 'scope_of_work', 'customizations', 'client_to_provide', 'additional_notes', 'status', 'hosted', 'dev_domain', 'dev_username', 'dev_password', 'staging_domain', 'staging_username', 'staging_password', 'live_domain', 'live_username', 'live_password'],
    activeTab: 0
  }),

  computed: {
    accountManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.accountManager : {}
    },

    projectManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.clientServicesRep : {}
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    projectStatuses () {
      return Object.keys(window.projectStatuses).map(key => {
        return {
          id: key,
          text: `${key} - ${window.projectStatuses[key]}`
        }
      })
    }
  },

  created () {
    if (this.edit) {
      this.keys.forEach(key => this.form[key] = this.edit[key])
      const pricings = JSON.parse(this.edit.schedule_pricing)
      Object.keys(pricings).forEach(key => this.form[key] = pricings[key])
      this.form.hosted = this.form.hosted === 'Yes'
    } else {
      this.form.status = 'NWP'
    }
  },

  methods: {
    ...mapActions({
      create: 'projects/create',
      update: 'projects/update',
      createTracker: 'projects/trackers/create'
    }),

    async save () {
      this.$v.$touch()
      if (!this.$v.form.$invalid) {
        const loading = this.$loading()

        const pricings = {}
        const payload = {
          accountManager: this.accountManager.id,
          projectManager: this.projectManager,
          client: this.registration.id
        }

        if (!this.edit) {
          payload.client = this.registration.id
          payload.createdAt = 'CURRENT_TIMESTAMP'
          payload.createdBy = window.appData.currentUser.id
        }

        Object.keys(this.form).forEach(key => this.keys.includes(key) ? payload[key] = this.form[key] : pricings[key] = this.form[key])
        payload.start_date = moment(payload.start_date).format('YYYY-MM-DD')
        payload.end_date = moment(payload.end_date).format('YYYY-MM-DD')
        payload.schedule_pricing = JSON.stringify(pricings)
        payload.hosted = payload.hosted ? 'Yes' : 'No'
  
  
        let message = 'Successfully created Project Build.'
        let id
        if (this.edit) {
          await this.update({ id: this.edit.id, payload })
          message = 'Successfully edited Project Build.'
          id = this.edit.id
        } else {
          id = (await this.create(payload)).id
        }
        await this.addTracker(id)
        if (this.edit && this.edit.status !== payload.status) await this.createTrackerForStatus(id, payload.status)

        this.$emit('close')
        this.$message.success(message)
        loading.close()
      } else {
        const projectFields = ['start_date', 'end_date', 'name', 'status', 'dev_domain', 'dev_username', 'dev_password', 'staging_domain', 'staging_username', 'staging_password', 'live_domain', 'live_username', 'live_password']
        const projectInvalid = projectFields.some(key => this.$v.form[key].$invalid)
        if (!projectInvalid && this.activeTab === 0) this.activeTab = 1
        if (projectInvalid && this.activeTab === 1) this.activeTab = 0
      }
    },

    createTrackerForStatus (id, status) {
      return this.createTrackerFactory({
        name: `Project Status updated to ${status} - ${window.projectStatuses[status]}`,
        hours: 0,
        cost: 0
      }, id)
    },

    addTracker (project) {
      if (!this.$refs.tracker.$v.$invalid) {
        const form = this.$refs.tracker.form
        if (!form.hours) form.hours = 0
        if (!form.cost) form.cost = 0
        return this.createTrackerFactory(form, project)
      }
    },

    async createTrackerFactory (form, project) {
      const tracker = await this.createTracker({
        ...form,
        project,
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id
      })
      return this.$store.commit('projects/addTrackerToProject', { id: project, data: tracker })
    }
  },

  validations () {
    const result = {
      form: {
        start_date: { required },
        end_date: { required },
        name: { required },
        status: { required },
        dev_domain: { required },
        staging_domain: { required },
        live_domain: { required },
      }
    }
    this.prices.forEach(key => {
      result.form[key] = { required }
    })
    return result
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .tab {
    width: 100%;
  }
}
</style>