<template>
  <layout active="/system/changelog">
    <div class="wrapper">
    <panel title="Change Log">
      <div class="add-btn">
        <el-button size="mini" type="success" icon="el-icon-circle-plus" circle @click="add()"/>
      </div>

      <div class="ex-table">
        <header>
          <div class="row">
            <div class="col" style="width: 230px">Date</div>
            <div class="col" style="width: 230px">Version</div>
            <div class="col">Description</div>
          </div>
        </header>
        <main>
          <div
            v-for="(item, idx) in form"
            :key="idx"
            class="row">
            <div class="col" style="width: 250px">
              <datepicker
                v-model="form[idx].start_date"
                :class="{ error: $v.form.$each.$iter[idx].start_date.$dirty && $v.form.$each.$iter[idx].start_date.$invalid }"
                calendar-button
                calendar-button-icon="el-icon-date"
                format="d MMM yyyy"
                placeholder="Date" 
                :disabled="!form[idx].disabled"
                :input-class="{'disabled': !form[idx].disabled, 'datepicker': true}"/>
            </div>
            <div class="col" style="width: 230px">
              <ex-input :classes="{'disabled': !form[idx].disabled, 'changelog-input': true}" v-model="form[idx].version" :validator="$v.form.$each.$iter[idx].version" no-style full :disabled="!form[idx].disabled" />
            </div>
            <div class="col">
              <ex-textarea :classes="{'disabled': !form[idx].disabled, 'textarea': true}" v-model="form[idx].description" :validator="$v.form.$each.$iter[idx].description" no-style full :disabled="!form[idx].disabled" />
            </div>
            <div class="edit-buttons">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="edit(item, idx)"/>
              <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="remove(item, idx)"/>
            </div>
          </div>
        </main>
      </div>

      <div class="button">
        <el-button type="primary" @click="save()">Submit</el-button>
      </div>
    </panel>
  </div>
  </layout>
</template>

<script>
import Layout from '../common/Layout'
import Panel from '../common/Panel'
import Datepicker from 'vuejs-datepicker'
import ExInput from '../common/Input'
import ExTextarea from '../common/Textarea'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Changelog',

  components: {
    Panel,
    Layout,
    ExInput, 
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: [],
      deleted: []
    }
  },

  async created () {
    await this.$store.dispatch('system/changelog/load', { order: ['id:DESC'] })
    this.form = [ ...this.$store.state.system.changelog.data ]
  },

  methods: {
    add () {
      this.form.unshift({ start_date: null, version: null, description: null, disabled: true })
    },

    async remove (item, idx) {
      try {
        await this.$confirm('Are you sure you want to delete Change Log?')
        this.form.splice(idx, 1)
        if (item.id) this.deleted.push(item.id)
      } catch {}
    },

    edit (item, idx) {
      this.$set(this.form[idx], 'disabled', true)
    },

    save () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid || !this.form.length) {
        const loading = this.$loading()


        const promises = []
        const added = this.formatChangelogDate(this.form.filter(item => !item.id))
        const updated = this.formatChangelogDate(this.form.filter(item => item.id))

        added.forEach(item => promises.push(this.$store.dispatch('system/changelog/create', item)))
        updated.forEach(item => promises.push(this.$store.dispatch('system/changelog/update', { id: item.id, payload: item })))
        this.deleted.forEach(id => promises.push(this.$store.dispatch('system/changelog/delete', id)))

        Promise.all(promises).then(() => {
          this.deleted = []
          this.form = [ ...this.$store.state.system.changelog.data ]
          loading.close()
          this.$message.success('Change Log successfully updated')
        })
      }
    },

    formatChangelogDate (changelogs) {
      return changelogs.map(item => {
        const fixedDate = new Date(item.start_date);
        item.start_date = moment(fixedDate).format('YYYY/MM/DD')
        return item
      })
    }
  },

  validations: {
    form: {
      required,
      $each: {
        start_date: { required },
        version: { required },
        description: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 900px;
    margin: 0 auto;
    .button {
      text-align: center;
      margin-top: 20px;
    }
  }
  .add-btn { 
    margin: 10px auto; 
    text-align: center;
  }
  .edit-buttons {
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .disabled {
    background: #fff;
    border: none; 
  }
  .datepicker {
    font-size: 12px; 
  }
</style>