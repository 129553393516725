var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: "/system/document-depot" } },
    [
      _c("document-depot-menu"),
      _vm._v(" "),
      _c("panel", { attrs: { title: "System NDA" } }, [
        _c("div", { staticClass: "form" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "First Name",
                  validator: _vm.$v.form.firstName,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.firstName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "firstName", $$v)
                  },
                  expression: "form.firstName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Last Name",
                  validator: _vm.$v.form.lastName,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.lastName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "lastName", $$v)
                  },
                  expression: "form.lastName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Job Title",
                  validator: _vm.$v.form.jobTitle,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.jobTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "jobTitle", $$v)
                  },
                  expression: "form.jobTitle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col w100" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Client Company Name",
                  validator: _vm.$v.form.company,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.company,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "company", $$v)
                  },
                  expression: "form.company"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-select", {
                attrs: {
                  label: "Country",
                  validator: _vm.$v.form.country,
                  options: _vm.countryOptions,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.selectedCountry,
                  callback: function($$v) {
                    _vm.selectedCountry = $$v
                  },
                  expression: "selectedCountry"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-select", {
                attrs: {
                  label: "State/Province",
                  disabled: !_vm.stateOptions,
                  validator: _vm.$v.form.country,
                  options: _vm.stateOptions,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.selectedState,
                  callback: function($$v) {
                    _vm.selectedState = $$v
                  },
                  expression: "selectedState"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Address",
                  validator: _vm.$v.form.address,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.address,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "City",
                  validator: _vm.$v.form.city,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.city,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "city", $$v)
                  },
                  expression: "form.city"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "ZIP/Postal code",
                  validator: _vm.$v.form.postal,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.postal,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "postal", $$v)
                  },
                  expression: "form.postal"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col no-padding" }),
          _vm._v(" "),
          _c("div", { staticClass: "col pt-10" }, [_vm._v("Signer:")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "First Name",
                  validator: _vm.$v.form.signerFirstName,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.signerFirstName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "signerFirstName", $$v)
                  },
                  expression: "form.signerFirstName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Last Name",
                  validator: _vm.$v.form.signerLastName,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.signerLastName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "signerLastName", $$v)
                  },
                  expression: "form.signerLastName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Job Title",
                  validator: _vm.$v.form.signerJobTitle,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.signerJobTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "signerJobTitle", $$v)
                  },
                  expression: "form.signerJobTitle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col no-padding" },
            [
              _c(
                "el-button",
                {
                  staticClass: "save",
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-check",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c("div", { staticClass: "info-box" }, [
              _c("h3", [_vm._v("NDA History")])
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticStyle: { "min-width": "100%" },
                attrs: { data: _vm.data, stripe: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Created by" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.createdBy.firstName) +
                              " " +
                              _vm._s(scope.row.createdBy.lastName) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Company name" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.company) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Created" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("date")(scope.row.createdAt)) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Actions", align: "right", width: "350" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.viewUrl(scope.row.file),
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-document"
                                  }
                                },
                                [_vm._v("View")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-delete",
                                type: "danger"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.remove(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("Delete")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }