var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "plugged" }, [
    _c("h1", [_vm._v("GET PLUGGED ")]),
    _vm._v(" "),
    _vm.isLogin
      ? _c("h3", [
          _vm._v("Not Signed Up Yet? "),
          _vm._m(0),
          _vm._v(" to get started.")
        ])
      : _c("h3", [
          _vm._v("Already a user? "),
          _vm._m(1),
          _vm._v(" to get started.")
        ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("h3", [
      _vm._v(
        "Use of this site is restricted for the use of Plugg & Co. Clients & Associates."
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { attrs: { href: "/client-registration" } }, [
        _vm._v("Create your profile")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { attrs: { href: "/login" } }, [_vm._v("Login")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("Trouble logging in? Please contact: "),
      _c("span", [
        _c("a", { attrs: { href: "#" } }, [_vm._v("support@pluggco.com")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }