var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form" },
    [
      _c(
        "div",
        { staticClass: "form-group form-subject" },
        [
          _c("label", [_vm._v("Subject:")]),
          _vm._v(" "),
          _c("ex-input", {
            attrs: {
              validator: _vm.$v.form.subject,
              disabled: _vm.readonly,
              "no-style": "",
              full: ""
            },
            model: {
              value: _vm.form.subject,
              callback: function($$v) {
                _vm.$set(_vm.form, "subject", $$v)
              },
              expression: "form.subject"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "inline-form-group" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Date:")]),
            _vm._v(" "),
            _c("datepicker", {
              class: {
                error: _vm.$v.form.date.$dirty && _vm.$v.form.date.$invalid
              },
              attrs: {
                "calendar-button": "",
                "calendar-button-icon": "el-icon-date",
                maxlength: "100",
                format: "d MMM yyyy",
                "open-date": new Date(),
                disabled: _vm.readonly,
                disabledDates: _vm.disabledDates
              },
              model: {
                value: _vm.form.date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "date", $$v)
                },
                expression: "form.date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Time from:")]),
            _vm._v(" "),
            _c("ex-select", {
              staticClass: "time-select",
              attrs: {
                options: _vm.hourOptions,
                validator: _vm.$v.form.time_from_hours,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.time_from_hours,
                callback: function($$v) {
                  _vm.$set(_vm.form, "time_from_hours", $$v)
                },
                expression: "form.time_from_hours"
              }
            }),
            _vm._v(" "),
            _c("ex-select", {
              staticClass: "time-select",
              attrs: {
                options: _vm.timeOptionsOpt,
                validator: _vm.$v.form.time_from_time,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.time_from_time,
                callback: function($$v) {
                  _vm.$set(_vm.form, "time_from_time", $$v)
                },
                expression: "form.time_from_time"
              }
            }),
            _vm._v(" "),
            _c("ex-select", {
              staticClass: "time-select",
              attrs: {
                options: _vm.amPmOptions,
                validator: _vm.$v.form.time_from_am_pm,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.time_from_am_pm,
                callback: function($$v) {
                  _vm.$set(_vm.form, "time_from_am_pm", $$v)
                },
                expression: "form.time_from_am_pm"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Time to")]),
            _vm._v(" "),
            _c("ex-select", {
              staticClass: "time-select",
              attrs: {
                options: _vm.hourOptions,
                validator: _vm.$v.form.time_to_hours,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.time_to_hours,
                callback: function($$v) {
                  _vm.$set(_vm.form, "time_to_hours", $$v)
                },
                expression: "form.time_to_hours"
              }
            }),
            _vm._v(" "),
            _c("ex-select", {
              staticClass: "time-select",
              attrs: {
                options: _vm.timeOptionsOpt,
                validator: _vm.$v.form.time_to_time,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.time_to_time,
                callback: function($$v) {
                  _vm.$set(_vm.form, "time_to_time", $$v)
                },
                expression: "form.time_to_time"
              }
            }),
            _vm._v(" "),
            _c("ex-select", {
              staticClass: "time-select",
              attrs: {
                options: _vm.amPmOptions,
                validator: _vm.$v.form.time_to_am_pm,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.time_to_am_pm,
                callback: function($$v) {
                  _vm.$set(_vm.form, "time_to_am_pm", $$v)
                },
                expression: "form.time_to_am_pm"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Timezone:")]),
            _vm._v(" "),
            _c("ex-select", {
              attrs: {
                label: "Timezone",
                options: _vm.timeZoneOptions,
                validator: _vm.$v.form.timezone,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.timezone,
                callback: function($$v) {
                  _vm.$set(_vm.form, "timezone", $$v)
                },
                expression: "form.timezone"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Color Code:")]),
            _vm._v(" "),
            _c("ex-select", {
              attrs: {
                label: "Color",
                options: _vm.colorOptions,
                validator: _vm.$v.form.color,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.color,
                callback: function($$v) {
                  _vm.$set(_vm.form, "color", $$v)
                },
                expression: "form.color"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.isScheduler
        ? _c("tagEmployees", {
            ref: "tagEmployees",
            attrs: { readonly: _vm.readonly, "self-remove": _vm.selfTagRemove },
            on: {
              remove: function($event) {
                return _vm.$emit("remove", $event)
              }
            },
            model: {
              value: _vm.selectedEmployees,
              callback: function($$v) {
                _vm.selectedEmployees = $$v
              },
              expression: "selectedEmployees"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group form-note" },
        [
          _c("label", [_vm._v("Note:")]),
          _vm._v(" "),
          _c("ex-textarea", {
            attrs: {
              maxlength: "500",
              disabled: _vm.readonly,
              full: "",
              "no-margin": ""
            },
            model: {
              value: _vm.form.note,
              callback: function($$v) {
                _vm.$set(_vm.form, "note", $$v)
              },
              expression: "form.note"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }