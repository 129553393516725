var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: "/scheduler/calendar" } }, [
    _c(
      "div",
      [
        _c("schedulerMenu", { attrs: { page: _vm.currentPage } }),
        _vm._v(" "),
        _c("panel", { attrs: { title: _vm.currentPage.text } }, [
          _c("div", { staticClass: "scheduler-panel" }, [
            _c("div", { staticClass: "panel-title" }, [
              _c(
                "div",
                { staticClass: "panel-action" },
                [
                  _c("calendar-date-select", {
                    attrs: { month: _vm.month, year: _vm.year },
                    on: {
                      "update:month": function($event) {
                        _vm.month = $event
                      },
                      "update:year": function($event) {
                        _vm.year = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-date" },
                [
                  _c(
                    "ch-button",
                    {
                      attrs: { color: "gray", disabled: !_vm.selected },
                      on: {
                        click: function($event) {
                          return _vm.viewSelectedDate()
                        }
                      }
                    },
                    [_vm._v("View selected day")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ch-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.openAddEvent = true
                        }
                      }
                    },
                    [_vm._v("Add new Event")]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "panel-body-scheduler" },
              [
                _c("calendar", {
                  ref: "calendar",
                  attrs: { type: _vm.type, month: _vm.month, year: _vm.year },
                  on: {
                    selected: function($event) {
                      _vm.selected = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("manage-event-modal", {
          attrs: {
            type: _vm.type,
            preselectedDate:
              _vm.selected && _vm.selected.isInFuture ? _vm.selected.date : null
          },
          on: {
            save: function($event) {
              return _vm.addedEvent($event)
            }
          },
          model: {
            value: _vm.openAddEvent,
            callback: function($$v) {
              _vm.openAddEvent = $$v
            },
            expression: "openAddEvent"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }