<template>
  <div>
    <div class="table-wrapper">
      <div class="info-box">
        <h3>Client NDA</h3>
      </div>
      <el-table
        v-loading="loading"
        :data="[null]" style="min-width: 100%" stripe>
        <el-table-column label="Status">
          <div class="status">
            <i :class="signed ? 'el-icon-s-claim success' : 'el-icon-s-release'" />
          </div>
        </el-table-column>

        <el-table-column v-if="signed" label="Filename">
          <a :href="signedFileUrl" target="_blank">{{ nda.signedFile.file }}</a>
        </el-table-column>

        <el-table-column label="Name">
          Client NDA
        </el-table-column>

        <el-table-column label="Created by">
          <template v-if="!nda">N/A</template>
          <template v-else>
            {{ nda.createdBy.firstName }} {{ nda.createdBy.lastName }}
          </template>
        </el-table-column>

        <el-table-column label="Created at">
          <template v-if="!nda">N/A</template>
          <template v-else>
            {{ nda.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Signed at">
          <template v-if="!signed">N/A</template>
          <template v-else>
            {{ nda.signedAt | date }}
          </template>
        </el-table-column>

        <el-table-column v-if="!registration.userId" label="Actions" align="right" width="350">
          <el-button v-if="!nda" size="mini" type="primary" @click="create()">New</el-button>
          <el-button v-else size="mini" type="danger" @click="remove()">{{ !signed ? 'Remove' : 'Remove Signed' }}</el-button>
          <a :href="viewUrl" target="_blank">
            <el-button size="mini" :disabled="!nda" icon="el-icon-document">View</el-button>
          </a>
          <el-button v-if="!signed" size="mini" :disabled="!nda" icon="el-icon-edit" @click="$refs.upload.click()">Upload signed</el-button>
          <input v-show="false" ref="upload" type="file" @change="uploadFile" />
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import helperMixin from '../../common/helpers-mixin'

export default {
  name: 'NDA',

  mixins: [helperMixin],

  data () {
    return {
      type: 'NDA',
      loading: false
    }
  },

  computed: {
    nda () {
      return this.registration[this.type]
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    signedFileUrl () {
      if (!this.nda || !this.nda.signedFile) return null
      return `/download/ClientDocument/${this.nda.signedFile.id}`
    },

    signed () {
      return this.nda && this.nda.signedAt && this.nda.signedFile
    },

    viewUrl () {
      if (!this.nda || !this.nda.file) return null
      return `/download/ClientDocument/${this.nda.file.id}`
    }
  },

  methods: {
    ...mapActions({
      createChecklist: 'client/single/createChecklist',
      deleteChecklist: 'client/single/deleteChecklist',
      generateNdaPdf: 'client/single/generateNdaPdf',
      updateChecklist: 'client/single/updateChecklist',
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument',
      loadChecklist: 'client/single/loadChecklist'
    }),

    async remove () {
      if (this.signed) {
        const payload = {
          signedAt: null,
          signedFile: null
        }
        await this.updateChecklist({ id: this.nda.id, type: this.type, payload })
      } else {
        await this.deleteChecklist({ type: this.type, id: this.nda.id })
      }
    },

    async uploadFile ($e) {
      let file = $e.target.files[0]
      if (file) {
        this.loading = true
        file = this.changeFileName(file, `${this.registration.company} - CardBiller Corp NDA`)
        const document = await this.createDocument({
          type: 'Client NDA',
          clientRegId: this.registration.id,
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.uploadDocument({ id: document.id, file })
        await this.updateChecklist({
          id: this.nda.id,
          type: this.type,
          payload: {
            signedAt: 'CURRENT_TIMESTAMP',
            signedFile: document.id,
          }
        })
        this.loading = false
      }
    },

    async create () {
      this.loading = true
      const payload = {
        clientRegId: this.registration.id,
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id,
        signedAt: null,
        signedFile: null
      }
      const id = (await this.createChecklist({ type: this.type, payload })).id
      await this.generateNdaPdf(id)
      await this.loadChecklist({ type: 'NDA', id: this.registration.id, force: true })
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
}
</style>