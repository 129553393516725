<template>
  <footer class="plugged">
    <h1>GET PLUGGED </h1>
    <h3 v-if="isLogin">Not Signed Up Yet? <span><a href="/client-registration">Create your profile</a></span> to get started.</h3>
    <h3 v-else>Already a user? <span><a href="/login">Login</a></span> to get started.</h3>
    <h3>Trouble logging in? Please contact: <span> <a href="#">support@pluggco.com</a></span></h3>
    <h3>Use of this site is restricted for the use of Plugg & Co. Clients & Associates.</h3>
  </footer>
</template>

<script>
export default {
  name: 'GetPluggedFooter',

  props: {
    isLogin: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.plugged {
  text-align:center;
  h1 {
    font-size:18px;
    color:#fff;
    font-weight:900;
    font-family: 'Roboto', sans-serif;
    margin-bottom:20px;
  }
  h3 {
    font-size:14px;
    font-weight:400;
    color:#fff;
    font-family: 'Roboto', sans-serif;
    margin-bottom:20px;
  }
}
</style>
