<template>
  <div>
    <div class="table-wrapper">
      <div class="info-box">
        <h3>Project Scope and Schedule A Pricing</h3>
      </div>

      <el-table
        v-loading="loading"
        :data="[1]" style="min-width: 100%" stripe>
        <el-table-column label="Status">
          <div class="status">
            <i :class="{
              'el-icon-s-claim': data,
              'el-icon-s-release': !data,
              success: signed,
              warning: data && !signed
            }" />
          </div>
        </el-table-column>

        <el-table-column v-if="signed" label="Filename">
          <a :href="`/download/ClientDocument/${data.signedFile.id}`" target="_blank">
            {{ data.signedFile.file }}
          </a>
        </el-table-column>

        <el-table-column label="Name">
          Project Scope and Schedule A Pricing
        </el-table-column>

        <el-table-column label="Created by">
          <template v-if="!data">N/A</template>
          <template v-else>{{ data.createdBy.firstName }} {{ data.createdBy.lastName }}</template>
        </el-table-column>

        <el-table-column label="Created at">
          <template v-if="!data">N/A</template>
          <template v-else>
            {{ data.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Signed at">
          <template v-if="!signed">N/A</template>
          <template v-else>
            {{ data.signedAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Completed by">
          <template v-if="!data || !data.completedBy">N/A</template>
          <template v-else>
            {{ data.completedBy.firstName }} {{ data.completedBy.lastName }}
          </template>
        </el-table-column>

        <el-table-column v-if="!registration.userId" label="Actions" align="right" width="300">
          <el-button
            v-if="!signed"
            size="mini"
            :type="!data ? 'primary' : 'default'"
            :disabled="!clientAssign"
            @click="completeForm = true">
            {{ data ? 'Edit' : 'Create' }}
          </el-button>
          <template v-if="data && !signed">
            <a :href="`/client/pssp/${data.id}/pdf`" target="_blank">
              <el-button size="mini">View</el-button>
            </a>
            <el-button size="mini" :disabled="!data" type="primary" icon="el-icon-edit" @click="$refs.upload.click()">Upload signed</el-button>
          </template>
          <input v-show="false" ref="upload" type="file" @change="uploadFile" />
          
          <template v-if="signed">
            <a :href="`/download/ClientDocument/${data.signedFile.id}`" target="_blank">
              <el-button size="mini">View Signed</el-button>
            </a>
            <el-button size="mini" type="danger" @click="remove()">Remove Signed</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <project-scope-schedule-pricing-modal v-if="completeForm" @close="completeForm = false" @submit="create($event)" :data="formData" :next-id="nextId" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import helperMixin from '../../common/helpers-mixin'
import ProjectScopeSchedulePricingModal from './ProjectScopeSchedulePricingModal'

export default {
  name: 'ProjectScopeSchedulePricing',

  mixins: [helperMixin],

  components: { ProjectScopeSchedulePricingModal },

  data () {
    return {
      type: 'ProjectScopeSchedulePricing',
      completeForm: false,
      loading: false,
      nextId: null
    }
  },

  computed: {
    formData () {
      return this.data ? JSON.parse(this.data.data) : null
    },

    data () {
      return this.registration[this.type]
    },

    clientAssign () {
      return this.registration['ClientAssign']
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    signed () {
      return this.data && this.data.signedAt && this.data.signedFile
    }
  },

  async created () {
    const items = (await api.list('ProjectScopeSchedulePricing', {}, ["id:DESC"])).items
    this.nextId = this.data ? parseInt(this.data.id) : items.length ? parseInt(items[0].id) + 1 : 1000
  },

  methods: {
    ...mapActions({
      createChecklist: 'client/single/createChecklist',
      deleteChecklist: 'client/single/deleteChecklist',
      generateNdaPdf: 'client/single/generateNdaPdf',
      updateChecklist: 'client/single/updateChecklist',
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument'
    }),

    async remove () {
      this.loading = true
      await this.updateChecklist({
        id: this.data.id,
        type: this.type,
        payload: {
          signedAt: null,
          signedFile: null,
          completedBy: null
        }
      })
      
      this.loading = false
    },

    async create (form) {
      this.loading = true
      this.completeForm = false
      const payload = {
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id,
        data: JSON.stringify(form)
      }
      if (this.data) {
        await this.updateChecklist({ type: this.type, id: this.data.id, payload })
      } else {
        payload.clientRegId = this.registration.id,
        await this.createChecklist({ type: this.type, payload })
      }
      this.loading = false
    },

    async uploadFile ($e) {
      let file = $e.target.files[0]
      if (file) {
        this.loading = true
        file = this.changeFileName(file, `${this.registration.company}-project-scope-and-schedule-a-pricing`)
        const document = await this.createDocument({
          type: 'Project Scope and Schedule A Pricing',
          clientRegId: this.registration.id,
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.uploadDocument({ id: document.id, file })
        await this.updateChecklist({
          id: this.data.id,
          type: this.type,
          payload: {
            signedAt: 'CURRENT_TIMESTAMP',
            signedFile: document.id,
            completedBy: window.appData.currentUser.id
          }
        })
        this.loading = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>