var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: "/system/messages" } }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("panel", { attrs: { title: "Enter new system message" } }, [
          _c(
            "form",
            { staticClass: "form" },
            [
              _c("el-input", {
                attrs: {
                  rows: 10,
                  type: "textarea",
                  resize: "none",
                  placeholder: "Enter new system message"
                },
                model: {
                  value: _vm.form.message,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message"
                }
              }),
              _vm._v(" "),
              _vm.$v.form.message.$dirty && _vm.$v.form.message.$invalid
                ? _c("span", { staticClass: "error" }, [
                    _vm._v("This field is required !")
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Create")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c(
              "el-table",
              {
                staticStyle: { "min-width": "100%" },
                attrs: { data: _vm.data, stripe: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Message" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.message) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Created By" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.createdBy.firstName) +
                              " " +
                              _vm._s(scope.row.createdBy.lastName) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Created At" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("date")(scope.row.createdAt)) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Actions", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-remove"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.remove(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("Delete")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }