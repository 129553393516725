var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plugg-tabs" }, [
    _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("Plugg Client v1.0")]),
      _vm._v(" "),
      _c("span", { staticClass: "right" }, [
        _vm._v(_vm._s(_vm._f("date")(_vm.now, "PD")))
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "empty" },
      [
        _vm.header
          ? _vm._l(_vm.header, function(item) {
              return _c("span", { key: item.text }, [
                _vm._v(_vm._s(item.text) + ": "),
                _c("b", [_vm._v(_vm._s(item.value))])
              ])
            })
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tabs" },
      _vm._l(_vm.tabs, function(tab, idx) {
        return _c(
          "div",
          { key: idx },
          _vm._l(tab, function(item) {
            return _c(
              "button",
              {
                key: item,
                staticClass: "btn",
                class: { active: _vm.activeTab === item },
                on: {
                  click: function($event) {
                    _vm.activeTab = item
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(item) + "\n      ")]
            )
          }),
          0
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tab-items" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }