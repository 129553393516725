var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("label", [_vm._v("Date: " + _vm._s(_vm._f("date")(_vm.now, "LL")))])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("ex-select", {
            attrs: {
              label: "Plugg Build",
              validator: _vm.validator.form.build,
              options: _vm.buildOptions,
              full: "",
              "no-style": ""
            },
            model: {
              value: _vm.form.build,
              callback: function($$v) {
                _vm.$set(_vm.form, "build", $$v)
              },
              expression: "form.build"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("ex-select", {
            attrs: {
              label: "Plugg Build 2",
              options: _vm.buildOptions,
              full: "",
              "no-style": ""
            },
            model: {
              value: _vm.form.build2,
              callback: function($$v) {
                _vm.$set(_vm.form, "build2", $$v)
              },
              expression: "form.build2"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("ex-select", {
            attrs: {
              label: "Plugg Build 3",
              options: _vm.buildOptions,
              full: "",
              "no-style": ""
            },
            model: {
              value: _vm.form.build3,
              callback: function($$v) {
                _vm.$set(_vm.form, "build3", $$v)
              },
              expression: "form.build3"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "form-table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Client Name")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.registration.company))]),
        _vm._v(" "),
        _c("td", [_vm._v("Client Reg Number")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.registration.id))]),
        _vm._v(" "),
        _c("td", [_vm._v("Project Scope Number")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.nextId))])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Project Name")]),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("ex-input", {
              attrs: {
                validator: _vm.validator.form.name,
                full: "",
                "no-style": ""
              },
              model: {
                value: _vm.form.name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("td", [_vm._v("Est. Project Start")]),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("datepicker", {
              class: {
                error:
                  _vm.validator.form.start_date.$dirty &&
                  _vm.validator.form.start_date.$invalid
              },
              attrs: {
                "calendar-button": "",
                "calendar-button-icon": "el-icon-date",
                placeholder: "Est. Project Start"
              },
              model: {
                value: _vm.form.start_date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "start_date", $$v)
                },
                expression: "form.start_date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("td", [_vm._v("Est. Project Deadline")]),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("datepicker", {
              class: {
                error:
                  _vm.validator.form.end_date.$dirty &&
                  _vm.validator.form.end_date.$invalid
              },
              attrs: {
                "calendar-button-icon": "el-icon-date",
                "calendar-button": "",
                placeholder: "Est. Project Deadline"
              },
              model: {
                value: _vm.form.end_date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "end_date", $$v)
                },
                expression: "form.end_date"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Account Manager")]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.accountManager.firstName) +
              " " +
              _vm._s(_vm.accountManager.lastName)
          )
        ]),
        _vm._v(" "),
        _c("td", [_vm._v("Project Manager")]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            _vm._s(_vm.projectManager.firstName) +
              " " +
              _vm._s(_vm.projectManager.lastName)
          )
        ]),
        _vm._v(" "),
        _c("td"),
        _vm._v(" "),
        _c("td")
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("tr", [
        _c(
          "td",
          { staticClass: "p-0", attrs: { colspan: "6" } },
          [
            _c("ex-textarea", {
              attrs: { maxlength: "5000", full: "", "no-margin": "" },
              model: {
                value: _vm.form.scope_of_work,
                callback: function($$v) {
                  _vm.$set(_vm.form, "scope_of_work", $$v)
                },
                expression: "form.scope_of_work"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("tr", [
        _c(
          "td",
          { staticClass: "p-0", attrs: { colspan: "6" } },
          [
            _c("ex-textarea", {
              attrs: { maxlength: "5000", full: "", "no-margin": "" },
              model: {
                value: _vm.form.customizations,
                callback: function($$v) {
                  _vm.$set(_vm.form, "customizations", $$v)
                },
                expression: "form.customizations"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("tr", [
        _c(
          "td",
          { staticClass: "p-0", attrs: { colspan: "6" } },
          [
            _c("ex-textarea", {
              attrs: { maxlength: "5000", full: "", "no-margin": "" },
              model: {
                value: _vm.form.client_to_provide,
                callback: function($$v) {
                  _vm.$set(_vm.form, "client_to_provide", $$v)
                },
                expression: "form.client_to_provide"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("tr", [
        _c(
          "td",
          { staticClass: "p-0", attrs: { colspan: "6" } },
          [
            _c("ex-textarea", {
              attrs: { maxlength: "5000", full: "", "no-margin": "" },
              model: {
                value: _vm.form.additional_notes,
                callback: function($$v) {
                  _vm.$set(_vm.form, "additional_notes", $$v)
                },
                expression: "form.additional_notes"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("PROJECT INFORMATION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("SCOPE OF WORK")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("CUSTOMIZATIONS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("CLIENT TO PROVIDE")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [
        _vm._v("ADDITIONAL PROJECT NOTES")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }