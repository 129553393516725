<template>
<table class="calendar">
  <thead>
    <tr>
      <th
        v-for="day in daysOfWeek"
        :key="day">
        {{ day }}
      </th>
    </tr>
    <tr
      v-for="(week, index) in weeks"
      :key="year + month + index">
      <td
        v-for="(day, idx) in week"
        :key="idx"
        :class="getDayClasses(day)"
        @click="selectDate(day)">
        <div v-if="day">
          <span
            class="day"
            :class="{ today: day.isToday }">
            {{ day.day }}
          </span>
          <span
            v-for="event in day.events"
            :key="event.id"
            class="event"
            :class="event.color ? `event-${event.color.toLowerCase()}` : 'event-blue'">
            {{ formatEventText(event) }}
          </span>
        </div>
      </td>
    </tr>
  </thead>
</table>
</template>

<script>
export default {
  name: 'Calendar',

  props: {
    month: {
      type: Number,
      required: true
    },
    year: {
      type: Number,
      required: true
    },

    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      now: new Date(),
      selected: null,
      events: [],
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednnesday', 'Thursday', 'Friday', 'Saturday']
    }
  },

  computed: {
    isScheduler () {
      return this.type === 'SCHEDULER'
    },

    firstDay () {
      return moment(new Date(this.year, this.month, 1)).day()
    },

    lastDay () {
      return moment(new Date(this.year, this.month, this.daysInMonth)).day()
    },

    daysInMonth() { 
      return 32 - new Date(this.year, this.month, 32).getDate()
    },

    today () {
      return new Date()
    },

    weeks () {
      let weeks = []
      const days = []

      if (this.firstDay > 0)
        for(let i = 1; i <= this.firstDay; i++)
          days.push(null)
      
      for(let i = 1; i <= this.daysInMonth; i++) days.push(i)

      if (this.lastDay !== 6)
        for(let i = this.lastDay; i <= 6; i++)
          days.push(null)

      let week = []
      days.forEach((day, index) => {
        const date = new Date(this.year, this.month, day)
        week.push(day ? {
          day: day,
          date,
          isToday: date.toDateString() === this.today.toDateString(),
          isInFuture: date.getTime() > this.today.getTime(),
          events: this.getEventsForDay(date)
        } : null)

        if ((index + 1) % 7 === 0) {
          weeks.push(week)
          week = []
        }
      })

      return weeks
    }
  },

  watch: {
    selected (value) {
      this.$emit('selected', value)
    }
  },

  created () {
    this.loadEvents()
  },

  methods: {
    async loadEvents () {
      const payload = { type: this.type }
      const userId = window.appData.currentUser.id
      if (this.isScheduler) payload.createdBy = userId
      this.events = (await api.list("Event", payload)).items
      
      if (this.isScheduler) {
        payload.createdBy = ['!=', userId]
        payload['employees.user_id'] = userId
        this.events = this.events.concat((await api.list("Event", payload)).items)
      }

      this.events.forEach(event => {
      })
    },

    getEventsForDay (date) {
      return this.events.filter(event => moment(event.date_from).format('YYYY/MM/DD') === moment(date).format('YYYY/MM/DD'))
    },

    getDayClasses (day) {
      return {
        selectable: day,
        selected: day && this.selected === day
      }
    },

    selectDate (day) {
      if (this.selected === day) {
        this.selected = null
      } else if (day) {
        this.selected = day
      }
    },

    formatEventText (event) {
      const from = moment(event.date_from, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      const to = moment(event.date_to, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      return `${from} - ${to}: ${event.subject}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .calendar {
    width: 100%;
    border-collapse: collapse;
  }
  .calendar tr th {
    text-align: center;
    width: calc(100% / 7);
    padding-bottom: 10px;
  }
  .calendar tr td {
    padding: 5px;
    width: calc(100% / 7);
    height: 100px;
    border: 1px solid rgba(0, 0, 0, .1);
    vertical-align: top;
  }
  .calendar tr td .day {
    color: #999;
    padding: 2px 10px;
  }
  .calendar tr td .day.today {
    background-color: #4d00be;
    border-radius: 2px;
    color: #fff;
  }

  .calendar tr td .event {
    display: inline-block;
    border-radius: 2px;
    padding: 3px 6px;
    font-size: 13px;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 2px;
    &.event-blue {
      background-color: #4299e1;
    }
    &.event-red {
      background-color: #ce1241;
    }
    &.event-green {
      background-color: #0ec554;
    }
    &.event-orange {
      background-color: #ee8c0c;
    }
    &.event-yellow {
      background-color: #ffe704;
      color: #666;
    }
  }
  

  .calendar tr td.selectable {
    transition: all ease .2s;
  }
  .calendar tr td.selectable:hover {
    background-color: #ebf2f7;
    cursor: pointer;
  }
  .calendar tr td.selected {
    background-color: #f1f5f8;
    border-bottom-color: #4299e1;
    border-bottom-width: 3px;
  }
  .calendar-selectd-date {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    width: 190px;
    margin: 0 5px;
  }
  .calendar-selectd-date select {
    float: right;
  }
  .form .form-group {
    display: block;
    margin-bottom: 10px;
  }
  .form .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  .inline-form-group .form-group {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .event-list h1 {
    font-size: 22px;
  }
  ul.list {
    margin-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, .1);
  }
  ul.list li.list-item {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    transition: all ease .2s;
  }
  ul.list li.list-item .circle {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  ul.list li.list-item:hover {
    background-color: #ebf2f7;
    cursor: pointer;
  }
  ul.list li.list-item.selected {
    background-color: #f1f5f8;
    border-bottom-color: #4299e1;
    border-bottom-width: 3px;
  }
</style>
