<template>
  <div>
    <div class="info-header">
      <div class="item">
        Account Number: <span class="dark-text">{{ data.userId ? data.userId.accountId : 'N/A' }}</span>
      </div>
      <div class="item text-right">
        Account Balance: <span class="dark-text"> ${{ balance }}</span>
      </div>
    </div>

    <div class="mt-20">
      <div class="cof-multi flex">
        <date-time-picker
          v-model="filters.from"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01">
        </date-time-picker>
        <span class="calendar-text">To</span>
        <date-time-picker
          v-model="filters.to"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01">
        </date-time-picker>
        <el-button size="mini" type="primary" @click="search()">Search</el-button>
      </div>
    </div>

    <div class="ex-table">
      <header>
        <div class="row">
          <div class="col" style="width: 275px">Date</div>
          <div class="col">Note</div>
          <div class="col" style="width: 250px">Debit</div>
          <div class="col" style="width: 250px">Credit</div>
          <div class="col text-right" style="width: 200px">Action</div>
        </div>
      </header>
      <main>
        <div
          v-for="item in myAccount"
          :key="item.id"
          class="row">
          <template v-if="!editing[item.id]">
            <div class="col" style="width: 275px">
              {{ item.createdAt | date('LL') }}
            </div>
            <div class="col">
              {{ item.note}}
            </div>
            <div class="col" style="width: 250px">
              {{ item.debit ? `$${parseFloat(item.debit).toFixed(2)}` : '' }}
            </div>
            <div class="col" style="width: 250px">
              {{ item.credit ? `$${parseFloat(item.credit).toFixed(2)}` : '' }}          
            </div>
            <div class="col flex-end" style="width: 200px">
              <el-button  size="mini" type="primary" icon="el-icon-edit" circle @click="openEdit(item)" />
              <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="remove(item.id)" />
            </div>
          </template>

          <template v-else>
            <div class="col" style="width: 275px">
              <datepicker
                v-model="editing[item.id].createdAt"
                :class="{ error: $v.editing.$each.$iter[item.id].createdAt.$dirty && $v.editing.$each.$iter[item.id].createdAt.$invalid }"
                calendar-button-icon="el-icon-date"
                calendar-button
                placeholder="Date" />
            </div>
            <div class="col">
              <ex-input v-model="editing[item.id].note" :validator="$v.editing.$each.$iter[item.id].note" no-style full />
            </div>
            <div class="col value-inputs" style="width: 250px">
              <input class="value-check" type="checkbox" value="debit" v-model="editing[item.id].debitCheck" @change="uncheck($event, item.id)">
              <ex-input v-model="editing[item.id].debit" :disabled="!editing[item.id].debitCheck" :validator="$v.editing.$each.$iter[item.id].debit" @blur="formatCurrency('debit')" no-style full/>
            </div>
            <div class="col value-inputs" style="width: 250px">
              <input class="value-check" type="checkbox" value="credit" v-model="editing[item.id].creditCheck" @change="uncheck($event, item.id)">
              <ex-input v-model="editing[item.id].credit" :disabled="!editing[item.id].creditCheck" :validator="$v.editing.$each.$iter[item.id].credit" @blur="formatCurrency('credit')" no-style full />
            </div>
            <div class="col flex-end" style="width: 200px">
              <el-button  size="mini" type="default" icon="el-icon-close" circle @click="cancelEdit(item.id)" />
              <el-button size="mini" type="success" icon="el-icon-check" circle @click="saveEdit(item.id)" />
            </div>
          </template>
        </div>

        <div class="row">
          <div class="col" style="width: 275px">
            {{ date | date('LL') }}
          </div>
          <div class="col">
            <ex-input v-model="form.note" :validator="$v.form.note" no-style full />
          </div>
          <div class="col value-inputs" style="width: 250px">
            <input class="value-check" type="checkbox" value="debit" v-model="debitCheck" @change="uncheck($event)">
            <ex-input v-model="form.debit" :disabled="!debitCheck" :validator="$v.form.debit" @blur="formatCurrency('debit')" no-style full/>
          </div>
          <div class="col value-inputs" style="width: 250px">
            <input class="value-check" type="checkbox" value="credit" v-model="creditCheck" @change="uncheck($event)">
            <ex-input v-model="form.credit" :disabled="!creditCheck" :validator="$v.form.credit" @blur="formatCurrency('credit')" no-style full />
          </div>
          <div class="col" style="width: 200px">
            <el-button type="primary" @click="save()" style="height: 30px; padding: 7px 12px; float: right">Submit</el-button>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import numeral from 'numeral'
import { required, decimal, requiredIf  } from 'vuelidate/lib/validators'

import ExInput from '../common/Input'
import Datepicker from 'vuejs-datepicker'
import helperMixin from '../common/helpers-mixin'
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  components: { 
    ExInput,
    Datepicker,
    DateTimePicker
  },

  mixins: [helperMixin],

  data () {
    return {
      filters: {
        from: null,
        to: null
      },
      appliedFilters: {
        from: null,
        to: null
      },
      editing: {},
      creditCheck: false,
      debitCheck: true,
      form: {
        note: null,
        debit: null,
        credit: null
      }
    }
  },

  computed: {
    date () {
      return moment()
    },

    credit () {
      if (!this.myAccount) return 0
      return this.myAccount.filter(item => item.credit).map( item => parseFloat(item.credit)).reduce((i, acc) => i + acc, 0)
    }, 

    debit () {
      if (!this.myAccount) return 0
      return this.myAccount.filter(item => item.debit).map( item => parseFloat(item.debit)).reduce((i, acc) => i + acc, 0)
    }, 

    balance () {
      return this.data.accountBalance
    },

    data () {
      return this.$store.state.client.single.registration
    },

    myAccount () {
      let myAccount = this.$store.state.client.my_account.data || []
      if (this.appliedFilters.from) {
        myAccount = myAccount.filter(item => moment(moment(item.createdAt).format('YYYY-MM-DD')) >= moment(this.appliedFilters.from))
      }

      if (this.appliedFilters.to) {
        myAccount = myAccount.filter(item => moment(moment(item.createdAt).format('YYYY-MM-DD')) <= moment(this.appliedFilters.to))
      }

      myAccount.sort((a, b) => {
        const d1 = moment(a.createdAt)
        const d2 = moment(b.createdAt)
        return d1.isBefore(d2) ? -1 : d1.isAfter(d2) ? 1 : 0
      })

      return myAccount
    }
  }, 

  created() {
    this.filters.from = moment().add(-7, 'd').format("YYYY-MM-DD")
    this.filters.to = moment().format("YYYY-MM-DD")
    this.search()
    this.$store.dispatch('client/my_account/load', { params: { clientRegId: this.data.id } })
  },

  methods: {
    cancelEdit (id) {
      this.$delete(this.editing, id)
    },

    openEdit (item) {
      this.$set(this.editing, item.id, { ...item, creditCheck: !!item.credit, debitCheck: !item.credit,  })
    },

    search () {
      Object.keys(this.filters).forEach(key => this.appliedFilters[key] = this.filters[key])
    },

    clear() {
      this.form = {
        note: null,
        debit: null,
        credit: null
      }
      this.$v.form.$reset()
    },

    async saveEdit (id) {
      this.$v.editing.$each.$iter[id].$touch()
      if (!this.$v.editing.$each.$iter[id].$invalid) {
        const loading = this.$loading()
        try {
          const payload = { 
            ...this.editing[id],
            createdAt: moment(this.editing[id].createdAt).format('YYYY-MM-DD HH:mm:ss')
          }
          const account = this.myAccount.find(item => item.id === id)
          
          await this.$store.dispatch('client/my_account/update', {
            id,
            payload
          })
          if (account) {
            await this.$store.commit('client/single/updateRegistration', {
              ...this.data,
              accountBalance: (this.balance - parseFloat(account.debit || 0) - parseFloat(account.credit || 0)) + parseFloat(payload.debit || 0) + parseFloat(payload.credit || 0)
            })
          }
          this.$message.success('Changes saved successfully.')
          this.cancelEdit(id)
        } catch (e) {
          console.log(e)
        }
        finally {
          loading.close()
        }
      }
    },

    async save () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        const loading = this.$loading()
        try {
          const payload = { 
            ...this.form, 
            client: this.data.id,
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id
          }
          await this.$store.dispatch('client/my_account/create', payload)
          await this.$store.commit('client/single/updateRegistration', {
            ...this.data,
            accountBalance: this.balance + parseFloat(payload.debit || 0) - parseFloat(payload.credit || 0)
          })
          this.clear()
          this.$message.success('Successfully created.')
        } catch (e) {
          console.log(e)
        }
        finally {
          loading.close()
        }
      }
    },

    async remove (id) {
      try {
        await this.$confirm('Are you sure to delete this billing?')
        const loading = this.$loading()
        try {

          const account = this.myAccount.find(item => item.id === id)
          await this.$store.dispatch('client/my_account/delete', id)
          if (account) {
            await this.$store.commit('client/single/updateRegistration', {
              ...this.data,
              accountBalance: this.balance - parseFloat(account.debit || 0) - parseFloat(account.credit || 0)
            })
          }
          loading.close()
          this.$message.success('Billing deleted.')
        } catch (e) {
          loading.close()
        }
      } catch {}
    },

    uncheck(event, itemId) {
      if(event.target.value === 'debit') {
        if (itemId) {
          this.editing[itemId].creditCheck = false
          this.editing[itemId].credit = null
        } else {
          this.creditCheck = false
          this.form.credit = null
        }
      } else {
        if (itemId) {
          this.editing[itemId].debitCheck = false
          this.editing[itemId].debit = null

        } else {
          this.debitCheck = false
          this.form.debit = null
        }
      }
    },

    formatCurrency (key) {
      this.form[key] = numeral(this.form[key]).format('0.00')
    }
  },

  validations() {
    const res = {
      form: {
        note: { required }
      },
      editing: {
        $each: {
          note: { required },
          createdAt: { required },
          credit: {
            decimal,
            required: requiredIf((item) =>!item.debit)
          },
          debit: {
            decimal,
            required: requiredIf((item) =>!item.credit)
          }
        }
      }
    }

    const type = this.creditCheck ? 'credit' : 'debit'
    res.form[type] = { required, decimal }

    return res
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-bottom: 20px;
}
.info-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EBEEF5;
  padding-bottom: 12px;
  color:#909399;
  .item {
    font-weight: bold;
    .dark-text {
      color: #000;
    }
  }
}
.value-inputs {
  display: flex;
  justify-content: center;
  align-items: center;

  .value-check {
    margin-right: 7px;
  }
}
</style>