var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { "min-width": "100%" },
              attrs: { data: [1], stripe: "" }
            },
            [
              _c("el-table-column", { attrs: { label: "Status" } }, [
                _c("div", { staticClass: "status" }, [
                  _c("i", {
                    class: {
                      "el-icon-s-claim": _vm.data,
                      "el-icon-s-release": !_vm.data,
                      success: _vm.signed,
                      warning: _vm.data && !_vm.signed
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _vm.signed
                ? _c("el-table-column", { attrs: { label: "Filename" } }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/download/ClientDocument/" +
                            _vm.data.signedFile.id,
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.data.signedFile.file) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "Name" } }, [
                _vm._v("\n        Project Scope and Schedule A Pricing\n      ")
              ]),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Created by" } },
                [
                  !_vm.data
                    ? [_vm._v("N/A")]
                    : [
                        _vm._v(
                          _vm._s(_vm.data.createdBy.firstName) +
                            " " +
                            _vm._s(_vm.data.createdBy.lastName)
                        )
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Created at" } },
                [
                  !_vm.data
                    ? [_vm._v("N/A")]
                    : [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(_vm.data.createdAt)) +
                            "\n        "
                        )
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Signed at" } },
                [
                  !_vm.signed
                    ? [_vm._v("N/A")]
                    : [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(_vm.data.signedAt)) +
                            "\n        "
                        )
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Completed by" } },
                [
                  !_vm.data || !_vm.data.completedBy
                    ? [_vm._v("N/A")]
                    : [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.data.completedBy.firstName) +
                            " " +
                            _vm._s(_vm.data.completedBy.lastName) +
                            "\n        "
                        )
                      ]
                ],
                2
              ),
              _vm._v(" "),
              !_vm.registration.userId
                ? _c(
                    "el-table-column",
                    {
                      attrs: { label: "Actions", align: "right", width: "300" }
                    },
                    [
                      !_vm.signed
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: !_vm.data ? "primary" : "default",
                                disabled: !_vm.clientAssign
                              },
                              on: {
                                click: function($event) {
                                  _vm.completeForm = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.data ? "Edit" : "Create") +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data && !_vm.signed
                        ? [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/client/pssp/" + _vm.data.id + "/pdf",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("el-button", { attrs: { size: "mini" } }, [
                                  _vm._v("View")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.data,
                                  type: "primary",
                                  icon: "el-icon-edit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.upload.click()
                                  }
                                }
                              },
                              [_vm._v("Upload signed")]
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        ref: "upload",
                        attrs: { type: "file" },
                        on: { change: _vm.uploadFile }
                      }),
                      _vm._v(" "),
                      _vm.signed
                        ? [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/download/ClientDocument/" +
                                    _vm.data.signedFile.id,
                                  target: "_blank"
                                }
                              },
                              [
                                _c("el-button", { attrs: { size: "mini" } }, [
                                  _vm._v("View Signed")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.remove()
                                  }
                                }
                              },
                              [_vm._v("Remove Signed")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.completeForm
        ? _c("project-scope-schedule-pricing-modal", {
            attrs: { data: _vm.formData, "next-id": _vm.nextId },
            on: {
              close: function($event) {
                _vm.completeForm = false
              },
              submit: function($event) {
                return _vm.create($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-box" }, [
      _c("h3", [_vm._v("Project Scope and Schedule A Pricing")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }