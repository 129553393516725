var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("client-assign"),
      _vm._v(" "),
      _c("nda"),
      _vm._v(" "),
      _c("client-profile-setup-worksheet"),
      _vm._v(" "),
      _c("project-scope-schedule-pricing"),
      _vm._v(" "),
      _c("client-agreement")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }