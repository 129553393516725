var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "form-table mt-20" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.trackers, function(tracker) {
          return _c("tr", { key: tracker.id }, [
            _c(
              "td",
              [
                _c("small", [
                  _vm._v(_vm._s(_vm._f("date")(tracker.createdAt)))
                ]),
                _vm._v(" "),
                _c("el-tag", { attrs: { effect: "dark", size: "mini" } }, [
                  _vm._v(
                    _vm._s(tracker.createdBy.firstName) +
                      " " +
                      _vm._s(tracker.createdBy.lastName)
                  )
                ]),
                _vm._v(":\n        " + _vm._s(tracker.name) + "\n      ")
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(tracker.hours))]),
            _vm._v(" "),
            _c("td", [_vm._v("$" + _vm._s(tracker.cost))])
          ])
        }),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            [
              _c("ex-input", {
                attrs: {
                  validator: _vm.$v.form.name,
                  label: "Note",
                  "no-style": "",
                  full: ""
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("ex-input", {
                attrs: {
                  validator: _vm.$v.form.hours,
                  label: "Hours",
                  "no-style": "",
                  full: ""
                },
                model: {
                  value: _vm.form.hours,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "hours", _vm._n($$v))
                  },
                  expression: "form.hours"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("ex-input", {
                attrs: {
                  validator: _vm.$v.form.cost,
                  label: "Cost",
                  "no-style": "",
                  full: ""
                },
                model: {
                  value: _vm.form.cost,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "cost", _vm._n($$v))
                  },
                  expression: "form.cost"
                }
              })
            ],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", [_vm._v("Note")]),
      _vm._v(" "),
      _c("td", { attrs: { width: "100" } }, [_vm._v("Hours")]),
      _vm._v(" "),
      _c("td", { attrs: { width: "100" } }, [_vm._v("Cost")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }