var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("logo"),
        _vm._v(" "),
        _c(
          "panel",
          {
            staticClass: "panel",
            attrs: { title: "Client Registration", padding: "0", border: false }
          },
          [
            _c("div", { staticClass: "subtitle" }, [
              _vm._v("Client Information")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "First Name",
                      validator: _vm.$v.form.firstName,
                      full: ""
                    },
                    model: {
                      value: _vm.form.firstName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "firstName", $$v)
                      },
                      expression: "form.firstName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "Last Name",
                      validator: _vm.$v.form.lastName,
                      full: ""
                    },
                    model: {
                      value: _vm.form.lastName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "lastName", $$v)
                      },
                      expression: "form.lastName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "Job Title",
                      validator: _vm.$v.form.jobTitle,
                      full: ""
                    },
                    model: {
                      value: _vm.form.jobTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "jobTitle", $$v)
                      },
                      expression: "form.jobTitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      type: "email",
                      label: "Email Address",
                      validator: _vm.$v.form.email,
                      full: ""
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "Phone Number",
                      validator: _vm.$v.form.phone,
                      full: ""
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subtitle" }, [
              _vm._v("Client Company Information")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form" }, [
              _c(
                "div",
                { staticClass: "col w100" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "Client Company Name",
                      validator: _vm.$v.form.company,
                      full: ""
                    },
                    model: {
                      value: _vm.form.company,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "company", $$v)
                      },
                      expression: "form.company"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-select", {
                    attrs: {
                      label: "Client Company Type",
                      validator: _vm.$v.form.companyType,
                      options: _vm.companyTypeOptions,
                      full: ""
                    },
                    model: {
                      value: _vm.form.companyType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "companyType", $$v)
                      },
                      expression: "form.companyType"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-select", {
                    attrs: {
                      label: "Country",
                      validator: _vm.$v.form.country,
                      options: _vm.countryOptions,
                      full: ""
                    },
                    model: {
                      value: _vm.selectedCountry,
                      callback: function($$v) {
                        _vm.selectedCountry = $$v
                      },
                      expression: "selectedCountry"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subtitle" }, [
              _vm._v("Client Company Address Information")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "Address",
                      validator: _vm.$v.form.address,
                      full: ""
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "City",
                      validator: _vm.$v.form.city,
                      full: ""
                    },
                    model: {
                      value: _vm.form.city,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "city", $$v)
                      },
                      expression: "form.city"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-select", {
                    attrs: {
                      label: "State/Province",
                      disabled: !_vm.stateOptions,
                      validator: _vm.$v.form.state,
                      options: _vm.stateOptions,
                      full: ""
                    },
                    model: {
                      value: _vm.selectedState,
                      callback: function($$v) {
                        _vm.selectedState = $$v
                      },
                      expression: "selectedState"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "ZIP/Postal code",
                      validator: _vm.$v.form.postal,
                      full: ""
                    },
                    model: {
                      value: _vm.form.postal,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "postal", $$v)
                      },
                      expression: "form.postal"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ex-input", {
                    attrs: {
                      label: "Phone Number",
                      validator: _vm.$v.form.companyPhone,
                      full: ""
                    },
                    model: {
                      value: _vm.form.companyPhone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "companyPhone", $$v)
                      },
                      expression: "form.companyPhone"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "terms" }, [
              _c("p", [_vm._v("Do you agree to our:")]),
              _vm._v(" "),
              _c("div", { staticClass: "check" }, [
                _c("div", { staticClass: "ch-div" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agree.terms,
                        expression: "agree.terms"
                      }
                    ],
                    staticClass: "check-b",
                    attrs: { type: "checkbox", id: "terms" },
                    domProps: {
                      checked: Array.isArray(_vm.agree.terms)
                        ? _vm._i(_vm.agree.terms, null) > -1
                        : _vm.agree.terms
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.agree.terms,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.agree, "terms", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.agree,
                                "terms",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.agree, "terms", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "terms" } }, [
                    _c("a", [_vm._v("Terms & Conditions")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ch-div" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agree.privacy,
                        expression: "agree.privacy"
                      }
                    ],
                    staticClass: "check-b",
                    attrs: { type: "checkbox", id: "cbx1" },
                    domProps: {
                      checked: Array.isArray(_vm.agree.privacy)
                        ? _vm._i(_vm.agree.privacy, null) > -1
                        : _vm.agree.privacy
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.agree.privacy,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.agree, "privacy", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.agree,
                                "privacy",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.agree, "privacy", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "cbx1" } }, [
                    _c("a", [_vm._v("Privacy")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ch-div" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agree.user_policy,
                        expression: "agree.user_policy"
                      }
                    ],
                    staticClass: "check-b",
                    attrs: { type: "checkbox", id: "cbx2" },
                    domProps: {
                      checked: Array.isArray(_vm.agree.user_policy)
                        ? _vm._i(_vm.agree.user_policy, null) > -1
                        : _vm.agree.user_policy
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.agree.user_policy,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.agree,
                                "user_policy",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.agree,
                                "user_policy",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.agree, "user_policy", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "cbx2" } }, [
                    _c("a", [_vm._v("User Policy")])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ch-btn",
                    on: {
                      click: function($event) {
                        return _vm.beforeSave()
                      }
                    }
                  },
                  [_vm._v("Submit Registration")]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("get-plugged-footer", { attrs: { "is-login": false } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }