var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: "/tools/scheduler" } },
    [
      _c("schedulerMenu", { attrs: { page: _vm.currentPage } }),
      _vm._v(" "),
      _c("eventsByDate", { attrs: { date: _vm.date, type: _vm.type } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }