var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: "/scheduler/add-event" } }, [
    _c(
      "div",
      [
        _c("schedulerMenu", { attrs: { page: _vm.currentPage } }),
        _vm._v(" "),
        _c("panel", { attrs: { title: _vm.currentPage.text } }, [
          _c(
            "div",
            { staticClass: "scheduler-panel" },
            [
              _c("div", { staticClass: "panel-title" }, [
                _c(
                  "div",
                  { staticClass: "panel-date" },
                  [
                    _c(
                      "chButton",
                      {
                        attrs: { color: "gray" },
                        on: {
                          click: function($event) {
                            return _vm.clear()
                          }
                        }
                      },
                      [_vm._v("Clear Event")]
                    ),
                    _vm._v(" "),
                    _c(
                      "chButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.save()
                          }
                        }
                      },
                      [_vm._v("Save Event")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("createEventForm", {
                ref: "addEvent",
                attrs: { type: "SCHEDULER" }
              })
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }