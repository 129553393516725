<template>
  <layout active="/support/my-account">
    <panel title="My Account">
      <el-button v-for="(tab, idx) in subTabs" :key="tab" size="mini" :type="activeSubTab === idx ? 'primary' : null" @click="activeSubTab = idx">{{ tab }}</el-button>
    </panel>
      <panel v-if="data">
      <keep-alive>
        <template>
          <billing-info v-if="activeSubTab === 0" />
          <billing-invoices v-if="activeSubTab === 1" />
          <billing-statements v-if="activeSubTab === 2" />
          <billing-subscriptions v-if="activeSubTab === 3" />
        </template>
      </keep-alive>
    </panel>
  </layout>
</template>

<script>
  import { mapActions } from 'vuex'
  import helperMixin from '../common/helpers-mixin'

  import Panel from '../common/Panel'
  import Layout from '../common/Layout'
  import BillingInfo from './BillingInfo'
  import BillingSubscriptions from './billing/BillingSubscriptions'
  import BillingStatements from "./billing/BillingStatements";
  import BillingInvoices from "./billing/BillingInvoices";

  export default {
    name: 'support-my-account',

    mixins: [helperMixin],

    components: {
      Panel,
      Layout,
      BillingSubscriptions,
      BillingStatements,
      BillingInvoices,
    },

    data () {
      return {
        activeTab: 'Billing',
        activeSubTab: 0,
      }
    },

    computed: {

      isClient () {
        return this.data ? !!this.data.userId : false
      },

      subTabs () {
        return ['Billing Info', 'Invoices', 'Statements', 'Subscriptions']
      },

      data () {
        return this.$store.state.client.single.registration
      },

      user () {
        return window.appData.currentUser
      },

      worksheet () {
        return this.data ? this.data.Worksheet : null
      }
    },

    watch: {
      activeTab () {
        this.activeSubTab = 0
      }
    },

    async created () {
      const regsigration = [...(await this.loadRegistration({ params: { userId: this.user.id } }))]
      let regsigrationId = 0
      regsigration.map(i => {
        regsigrationId = i.id
      })
      await this.get({ id: regsigrationId })
      const checklists = ['Worksheet']
      checklists.forEach(async type => {
        await this.loadChecklist({ type, id: regsigrationId })
      })

      const tab = this.getUrl('tab')
      const subitem = this.getUrl('subitem')
      if (tab) {
        this.activeTab = tab
        if (subitem) this.$nextTick(() => this.activeSubTab = parseInt(subitem))
      }
    },

    methods: {
      ...mapActions({
        loadRegistration: 'support/client/load',
        loadUsers: 'users/get',
        get: 'client/single/get',
        update: 'client/single/update',
        loadDocuments: 'client/single/loadDocuments',
        loadChecklist: 'client/single/loadChecklist'
      }),
    }
  }
</script>
