import { render, staticRenderFns } from "./ClientInfoCompany.vue?vue&type=template&id=49ccb9ec&scoped=true&"
import script from "./ClientInfoCompany.vue?vue&type=script&lang=js&"
export * from "./ClientInfoCompany.vue?vue&type=script&lang=js&"
import style0 from "./ClientInfoCompany.vue?vue&type=style&index=0&id=49ccb9ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ccb9ec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6exchange/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49ccb9ec')) {
      api.createRecord('49ccb9ec', component.options)
    } else {
      api.reload('49ccb9ec', component.options)
    }
    module.hot.accept("./ClientInfoCompany.vue?vue&type=template&id=49ccb9ec&scoped=true&", function () {
      api.rerender('49ccb9ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/components/clients/ClientInfoCompany.vue"
export default component.exports