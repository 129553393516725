var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.worksheetData
        ? _c(
            "div",
            { staticClass: "buttons" },
            [
              !_vm.edit
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-edit",
                        type: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.startEdit()
                        }
                      }
                    },
                    [_vm._v("Edit")]
                  )
                : [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          icon: "el-icon-edit",
                          type: "success"
                        },
                        on: {
                          click: function($event) {
                            return _vm.save()
                          }
                        }
                      },
                      [_vm._v("Save")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-edit" },
                        on: {
                          click: function($event) {
                            _vm.edit = false
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.edit
        ? [
            !_vm.data.userId
              ? [_vm._v("N/A")]
              : _vm._l(_vm.general, function(info, idx) {
                  return _c(
                    "div",
                    { key: idx, staticClass: "info-box" },
                    [
                      _c("h3", [_vm._v(_vm._s(info.name))]),
                      _vm._v(" "),
                      _vm._l(info.items, function(item, index) {
                        return _c("div", { key: index, staticClass: "col" }, [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                          _vm._v(" "),
                          _c("b", [_vm._v(_vm._s(item.value))])
                        ])
                      })
                    ],
                    2
                  )
                })
          ]
        : _c("worksheet-contacts", {
            attrs: { v: _vm.$v },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }