var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "submenu", class: _vm.classes }, [
    _vm.back ? _c("a", { attrs: { href: "/" } }, [_vm._m(0)]) : _vm._e(),
    _vm._v(" "),
    _vm.dashboard
      ? _c("a", { attrs: { href: _vm.dashboard.to } }, [
          _c("button", { staticClass: "back" }, [
            _c("i"),
            _vm._v(" " + _vm._s(_vm.dashboard.text))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "menu" },
      _vm._l(_vm.menu, function(item) {
        return _c(
          "li",
          { key: item.text },
          [
            item.list
              ? [
                  _c("span", [_vm._v(" " + _vm._s(item.text) + " "), _c("i")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "arrow-up" }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(item.list, function(subitem, idx) {
                      return _c("li", { key: idx }, [
                        _c("a", { attrs: { href: subitem.to } }, [
                          _vm._v(_vm._s(subitem.text))
                        ])
                      ])
                    }),
                    0
                  )
                ]
              : _c("a", { attrs: { href: item.to } }, [
                  _vm._v(_vm._s(item.text))
                ])
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "back" }, [
      _c("i"),
      _vm._v(" Back to Main")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }