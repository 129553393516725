<template>
  <div>
    <div
      v-if="worksheetData"
      class="buttons">
      <el-button v-if="!edit" size="mini" icon="el-icon-edit" type="primary" @click="startEdit()">Edit</el-button>
      <template v-else>
        <el-button size="mini" icon="el-icon-edit" type="success" @click="save()">Save</el-button>
        <el-button size="mini" icon="el-icon-edit" @click="edit = false">Cancel</el-button>
      </template>
    </div>

    <template v-if="!edit">
      <template v-if="!data.userId">N/A</template>
      <template v-else>
        <div v-for="(info, idx) in general" :key="idx" class="info-box">
          <h3>{{ info.name }}</h3>
          <div v-for="(item, index) in info.items" :key="index" class="col">
            <span>{{ item.name }}</span>
            <b>{{ item.value }}</b>
          </div>
        </div>
      </template>
    </template>
    <worksheet-contacts v-else v-model="form" :v="$v" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { phone } from '../common/validators'
import WorksheetContacts from './setup-checklist/WorksheetContacts'
import { required, numeric, email } from 'vuelidate/lib/validators'

export default {
  components: {
    WorksheetContacts
  },

  data () {
    return {
      form: {},
      edit: false,
      contacts: ['MainContact', 'SecondaryContact', 'TechnicalContact', 'BillingContact'],
      contactKeys: ['firstName', 'lastName', 'jobTitle', 'email', 'phone', 'ext'],
      contactTypes: {
        MainContact: 'Main Contact',
        SecondaryContact: 'Secondary Contact',
        TechnicalContact: 'Technical Contact',
        BillingContact: 'Billing Contact'
      }
    }
  },

  computed: {
    data () {
      return this.$store.state.client.single.registration
    },

    worksheet () {
      return this.data.Worksheet
    },

    worksheetData () {
      return this.worksheet ? JSON.parse(this.worksheet.data) : null
    },

    general () {
      if (!this.worksheetData) return []
      const data = []
      this.contacts.forEach(type => {
        data.push({
          name: this.contactTypes[type],
          items: [
            {
              name: 'First Name',
              value: this.worksheetData[type].firstName || 'N/A'
            },
            {
              name: 'Last Name',
              value: this.worksheetData[type].lastName || 'N/A'
            },
            {
              name: 'Title',
              value: this.worksheetData[type].jobTitle || 'N/A'
            },
            {
              name: 'Email',
              value: this.worksheetData[type].email || 'N/A'
            },
            {
              name: 'Phone',
              value: this.worksheetData[type].phone || 'N/A'
            },
            {
              name: 'Ext',
              value: this.worksheetData[type].ext || 'N/A'
            }
          ]
        })
      })
      return data
    }
  },

  methods: {
    ...mapActions({
      updateChecklist: 'client/single/updateChecklist'
    }),

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const loading = this.$loading()
        const payload = {
          data: JSON.stringify(Object.assign(this.worksheetData, this.form))
        }
        await this.updateChecklist({ type: 'Worksheet', id: this.worksheet.id, payload })
        this.edit = false
        loading.close()
      }
    },

    startEdit () {
      this.edit = true
      this.$nextTick(() => {
        if (this.worksheetData) {
          this.contacts.forEach(type => {
            this.contactKeys.forEach(key => {
              this.form[type][key] = this.worksheetData[type][key]
            })
          })
        }
      })
    }
  },

  validations () {
    const rules = {
      form: {}
    }

    this.contacts.filter(type => type !== 'SecondaryContact').forEach(type => {
      rules.form[type] = {
        firstName: { required },
        lastName: { required },
        jobTitle: { required },
        email: { required, email },
        phone: { required, phone },
      }
    })
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
  .m-0 {
    margin: 0;
  }
</style>