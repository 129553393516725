var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function($event) {
                  _vm.openForm = true
                }
              }
            },
            [_vm._v("New")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "cof-multi flex" },
          [
            _c("date-time-picker", {
              attrs: {
                "value-type": "String",
                "value-format": "yyyy-LL-dd",
                format: "yyyy-LL-dd",
                "time-picker": false,
                "auto-close": true,
                "min-date": "2020-01-01",
                "max-date": _vm.today
              },
              model: {
                value: _vm.filters.from,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "from", $$v)
                },
                expression: "filters.from"
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "calendar-text" }, [_vm._v("To")]),
            _vm._v(" "),
            _c("date-time-picker", {
              attrs: {
                "value-type": "String",
                "value-format": "yyyy-LL-dd",
                format: "yyyy-LL-dd",
                "time-picker": false,
                "auto-close": true,
                "min-date": "2020-01-01",
                "max-date": _vm.today
              },
              model: {
                value: _vm.filters.to,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "to", $$v)
                },
                expression: "filters.to"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.search()
                  }
                }
              },
              [_vm._v("Search")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              staticStyle: { "min-width": "100%" },
              attrs: { data: _vm.invoices, stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "Invoice #" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.id) + "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Filename" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return scope.row.file
                          ? [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/download/ClientDocument/" +
                                      scope.row.file.id,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.file.file) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          : undefined
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Date Start" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(scope.row.startDate, "LL")) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Date End" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(scope.row.endDate, "LL")) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Actions", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-edit",
                              type: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openPdf(scope.row.file.id)
                              }
                            }
                          },
                          [_vm._v("View\n          ")]
                        ),
                        _vm._v(" "),
                        _vm.isUserAdmin
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-delete",
                                  type: "danger"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.remove(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("\n            Remove\n          ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.openForm
        ? _c("billing-invoice-form", {
            on: {
              close: function($event) {
                _vm.openForm = false
              },
              saved: function($event) {
                _vm.openForm = false
                _vm.loadInvoices()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }