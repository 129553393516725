var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel", class: { border: _vm.border } }, [
    _vm.title
      ? _c("div", { staticClass: "panel-header" }, [
          _c("h3", [_vm._v(_vm._s(_vm.title))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "panel-body", style: { padding: _vm.padding } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }