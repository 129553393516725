import builds from './builds'
import messages from './messages'
import changelog from './changelog'
import apix from './apix'
import provider from './provider'
import pin from './pins'

const state = {
  system: null
}

const actions = {
  async get ({ commit }) {
    const data = (await api.get('SystemInfo', 1)).item
    commit('set', data)
    return data
  },

  async update ({ commit }, payload) {
    const data = (await api.update('SystemInfo', 1, payload, true)).item
    commit('set', data)
    return data
  },

  async upload (context, { file, params = null }) {
    return await api.upload('SystemInfo', 1, file, params)
  }
}

const mutations = {
  set (state, data) {
    state.system = data
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
  modules: {
    builds,
    messages,
    changelog,
    apix,
    provider,
    pin
  }
}
