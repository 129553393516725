<template>
  <div>
    <table class="form-table mt-20">
      <tr class="heading">
        <td>Note</td>
        <td width="100">Hours</td>
        <td width="100">Cost</td>
      </tr>
      <tr
        v-for="tracker in trackers"
        :key="tracker.id">
        <td>
          <small>{{ tracker.createdAt | date }}</small>
          <el-tag effect="dark" size="mini">{{ tracker.createdBy.firstName }} {{ tracker.createdBy.lastName }}</el-tag>:
          {{ tracker.name }}
        </td>
        <td>{{ tracker.hours }}</td>
        <td>${{ tracker.cost }}</td>
      </tr>
      <tr>
        <td><ex-input v-model="form.name" :validator="$v.form.name" label="Note" no-style full /></td>
        <td><ex-input v-model.number="form.hours" :validator="$v.form.hours" label="Hours" no-style full /></td>
        <td><ex-input v-model.number="form.cost" :validator="$v.form.cost" label="Cost" no-style full /></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { required, integer } from 'vuelidate/lib/validators'

import ExInput from '../../common/Input'

export default {
  components: {
    ExInput
  },

  props: {
    trackers: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    form: {
      name: null,
      hours: null,
      cost: null
    }
  }),

  validations: {
    form: {
      name: { required },
      hours: { integer },
      cost: { integer }
    }
  }
}
</script>
