import Vue from 'vue'
import users from './users'

const state = {
  registration: null,
  documents: null
}

const actions = {
  async get ({ state, commit }, { id, force = false }) {
    if (state.registration && !force) return state.registration
    const data = (await api.get('ClientRegistration', id)).item
    commit(state.registration ? 'updateRegistration' : 'setRegistration', data)
    return data
  },

  async update ({ commit }, { id, payload }) {
    const data = (await api.update('ClientRegistration', id, payload, true)).item
    commit('updateRegistration', data)
    return data
  },

  async loadChecklist ({ state, commit }, { type, id, force = false }) {
    if (state.registration[type] && !force) return state.registration[type]
    const data = (await api.list(type, { clientRegId: id })).items
    commit('setData', { key: type, data: data.length ? data[0] : null })
    return data
  },

  async createChecklist ({ commit }, { type, payload }) {
    const data = (await api.create(type, payload, true)).item
    commit('setData', { key: type, data })
    return data
  },

  async updateChecklist ({ commit }, { id, type, payload }) {
    const data = (await api.update(type, id, payload, true)).item
    commit('setData', { key: type, data })
    return data
  },

  async deleteChecklist ({ commit }, { id, type }) {
    await api.delete(type, id)
    commit('setData', { key: type, data: null })
    return id
  },

  async generateNdaPdf ({ commit }, id) {
    const data = (await api.request('POST', `/nda/${id}/pdf`, {}, true)).item
    commit('setData', { key: 'NDA', data })
    return data
  },

  async generateClientAgreementPdf ({ commit }, id) {
    const data = (await api.request('POST', `/client/${id}/agreement/pdf`, {}, true)).item
    commit('setData', { key: 'ClientAssignment', data })
    return data
  },

  async loadDocuments ({ state, commit }, { id, force = false }) {
    if (state.documents && !force) return state.documents;
    const type = ['\'Client Invoice\', \'Client Statement\'', 'NOT IN'];
    const data = (await api.list('ClientDocument', { clientRegId: id, type }, ['createdAt:DESC'])).items;
    commit('setDocuments', data);
    return data
  },

  async createDocument ({ commit }, payload) {
    const data = (await api.create('ClientDocument', payload, true)).item
    commit('addDocument', data)
    return data
  },

  async deleteDocument({ state, commit }, documentId) {
    await api.delete('ClientDocument', documentId);
    commit('setDocuments', state.documents.filter(document => document.id !== documentId));
  },

  async uploadDocument (context, { id, file }) {
    return api.upload('ClientDocument', id, file)
  },
}

const mutations = {
  setRegistration (state, data) {
    state.registration = data
  },

  updateRegistration (state, data) {
    console.log('update', data.accountBalance)
    state.registration = Object.assign(state.registration, data);
  },

  setData (state, { key, data }) {
    Vue.set(state.registration, key, data)
  },

  setDocuments (state, data) {
    state.documents = data
  },

  addDocument (state, data) {
    state.documents.push(data)
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
  modules: {
    users
  }
}