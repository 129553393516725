<template>
  <div>
    <div class="buttons">
      <el-button v-if="!edit && canEdit" size="mini" icon="el-icon-edit" type="primary" @click="startEdit()">Edit</el-button>
      <template v-if="edit">
        <el-button size="mini" icon="el-icon-edit" type="success" @click="save()">Save</el-button>
        <el-button size="mini" icon="el-icon-edit" @click="edit = false">Cancel</el-button>
      </template>
    </div>

    <div class="info-box">
      <div class="col">
        <span>Registration Date</span>
        <b>{{ data.created | date('PD') }}</b>
      </div>
      <div class="col" />
      <div class="col">
        <span>Registration ID</span>
        <b>{{ data.id }}</b>
      </div>
    </div>

    <div v-for="(info, idx) in general" :key="idx" class="info-box">
      <h3>{{ info.name }}</h3>
      <div v-for="(item, index) in info.items" :key="index" class="col">
        <span>{{ item.name }}</span>

        <template v-if="edit">
          <template v-if="['email', 'text'].includes(item.input)">
            <ex-input v-model="form[item.key]" :type="item.input" :validator="item.validation" class="m-0" full />
          </template>
          <ex-select v-if="item.input === 'select'" v-model="form[item.key]" :disabled="item.disabled || false" :validator="item.validation" :options="item.options" class="m-0" full />
        </template>

        <b v-else>{{ item.value }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import ExInput from '../common/Input'
import ExSelect from '../common/Select'

import { phone } from '../common/validators'
import { required, numeric, email, alpha } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  components: {
    ExInput,
    ExSelect
  },

  data () {
    return {
      edit: false,

      form: {
        firstName: null,
        lastName: null,
        jobTitle: null,
        email: null,
        phone: null,
        company: null,
        companyType: null,
        country: null,
        address: null,
        city: null,
        state: null,
        postal: null,
        companyPhone: null,

        selectedState: null,
        selectedCountry: null
      },
    }
  },

  computed: {
    data () {
      return this.$store.state.client.single.registration
    },

    nda () {
      return this.data.NDA
    },

    clientAssign () {
      return this.data.ClientAssign
    },

    worksheet () {
      return this.data.Worksheet
    },

    canEdit () {
      return  !this.nda && !this.clientAssign && !this.worksheet
    },

    general () {
      if (!this.data) return []
      return [
        {
          name: 'Client Information',
          items: [
            {
              name: 'First Name',
              value: this.data.firstName,
              key: 'firstName',
              validation: this.$v.form.firstName,
              input: 'text'
            },
            {
              name: 'Last Name',
              value: this.data.lastName,
              key: 'lastName',
              validation: this.$v.form.lastName,
              input: 'text'
            },
            {
              name: 'Job Title',
              value: this.data.jobTitle,
              key: 'jobTitle',
              validation: this.$v.form.jobTitle,
              input: 'text'
            },
            {
              name: 'Email Address',
              value: this.data.email,
              key: 'email',
              validation: this.$v.form.email,
              input: 'email'
            },
            {
              name: 'Phone Number',
              value: this.data.phone,
              key: 'phone',
              validation: this.$v.form.phone,
              input: 'text'
            }
          ]
        },
        {
          name: 'Client Company Information',
          items: [
            {
              name: 'Client Company Name',
              value: this.data.company,
              key: 'company',
              validation: this.$v.form.company,
              input: 'text'
            },
            {
              name: 'Client Company Type',
              value: window.companyTypes[this.data.companyType],
              key: 'companyType',
              validation: this.$v.form.companyType,
              input: 'select',
              options: this.companyTypeOptions
            },
            {
              name: 'Country',
              value: window.countries[this.data.country],
              key: 'selectedCountry',
              validation: this.$v.form.country,
              input: 'select',
              options: this.countryOptions
            }
          ]
        },
        {
          name: 'Client Company Address Information',
          items: [
            {
              name: 'Address',
              value: this.data.address,
              key: 'address',
              validation: this.$v.form.address,
              input: 'text'
            },
            {
              name: 'City',
              value: this.data.city,
              key: 'city',
              validation: this.$v.form.city,
              input: 'text'
            },
            {
              name: 'State/Province',
              value: ['CA', 'US'].includes(this.data.country) ? window.states[this.data.country].find(state => state.id === this.data.state).text : 'N/A',
              key: 'selectedState',
              validation: this.$v.form.state,
              input: 'select',
              disabled: !this.stateOptions,
              options: this.stateOptions
            },
            {
              name: 'ZIP/Postal Code',
              value: this.data.postal,
              key: 'postal',
              validation: this.$v.form.postal,
              input: 'text'
            },
            {
              name: 'Phone Number',
              value: this.data.companyPhone,
              key: 'companyPhone',
              validation: this.$v.form.companyPhone,
              input: 'text'
            }
          ]
        }
      ]
    },

    companyTypeOptions () {
      return Object.keys(window.companyTypes).map(key => {
        return {
          id: key,
          text: window.companyTypes[key]
        }
      })
    },

    countryOptions () {
      const options = window.countriesOptions.map(country => {
        return {
          id: country.id,
          text: `${country.id} - ${country.text}`
        }
      }) 
      options.splice(2, 0, {
        id: null,
        disabled: true,
        text: '---------------------------------'
      });
      return options
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    'form.selectedState': {
      handler (value) {
        if (value) {
          this.form.state = this.getInitials(value)
        }
      }
    },

    'form.selectedCountry': {
      handler (value) {
        if (value) {
          this.form.country = this.getInitials(value)
          this.selectedState = null
          this.form.state = null
        }
      }
    }
  },

  methods: {
    ...mapActions({
      update: 'client/single/update'
    }),

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.update({ id: this.data.id, payload: this.form })
          this.$message.success('Successfully edited Client.')
          this.edit = false
        } catch {
          this.$message.error('There was an error... Please try again.')
        }
      }
    },

    startEdit () {
      this.form = {
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        jobTitle: this.data.jobTitle,
        email: this.data.email,
        phone: this.data.phone,
        company: this.data.company,
        companyType: this.data.companyType,
        country: this.data.country,
        address: this.data.address,
        city: this.data.city,
        state: this.data.state,
        postal: this.data.postal,
        companyPhone: this.data.companyPhone,
        selectedCountry: this.data.country,
        selectedState: null
      }
      this.$nextTick(() => {
        this.form.selectedState = this.data.state
        this.form.state = this.data.state
      })
      this.edit = true
    },

    getInitials (value) {
      return value.split('').splice(0, 2).join('')
    }
  },
  
  validations () {
    const rules = {
      form: {
        firstName: { required },
        lastName: { required },
        jobTitle: { required },
        email: { required, email },
        phone: { required, phone },
        company: { required },
        companyType: { required },
        country: { required },
        address: { required },
        city: { required },
        postal: { required },
        companyPhone: { required }
      }
    }

    if (this.stateOptions) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
  .m-0 {
    margin: 0;
  }
</style>