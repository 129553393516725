var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c("header-system-messages"),
      _vm._v(" "),
      _vm.user
        ? _c("div", { staticClass: "account" }, [
            _c("div", { staticClass: "r-text" }, [
              _c("h2", [
                _vm._v("Welcome back, "),
                _c("a", { attrs: { href: "/profile" } }, [
                  _vm._v(_vm._s(_vm.user.firstName))
                ])
              ]),
              _vm._v(" "),
              _c("h4", [
                _vm._v("Account ID: "),
                _c("a", { attrs: { href: "/profile" } }, [
                  _vm._v(_vm._s(_vm.user.uuid))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "dropdown-icon",
              class: { opened: _vm.opened },
              on: {
                click: function($event) {
                  _vm.opened = !_vm.opened
                }
              }
            }),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.opened,
                    expression: "opened"
                  }
                ],
                staticClass: "dropdown"
              },
              [
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.user.firstName) + " " + _vm._s(_vm.user.lastName)
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.isEmployee ? _vm.system.dbaName : _vm.company)
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v(_vm._s(_vm.user.uuid))]),
                _vm._v(" "),
                _c("li", [_vm._v("Account PIN")]),
                _vm._v(" "),
                _c("li", { staticClass: "line" }),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("li", [
                  _c("a", { attrs: { href: "/profile" } }, [
                    _c("b", [_vm._v("My Profile")])
                  ])
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("li", { staticClass: "line" }),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [_c("b", [_vm._v("Logout")])]
                  )
                ])
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("b", [_vm._v("Account Options")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("b", [_vm._v("Change Password")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }