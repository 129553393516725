<template>
  <layout active="/system/create-staff-users">
    <div class="wrapper">
      <panel title="Create New Staff">
        <form class="form">
          <ex-select v-model="form.role" label="Role"  :validator="$v.form.role" :options="roleOptions" />
          <ex-input v-model="form.firstName" label="First Name"  :validator="$v.form.firstName" />
          <ex-input v-model="form.lastName" label="Last Name"  :validator="$v.form.lastName" />
          <ex-input v-model="form.email" label="Email" type="email" :validator="$v.form.email" />
          <ex-input v-model="form.phone" label="Phone" type="number" :validator="$v.form.phone" />
          <ex-input v-model="form.phoneExt" label="Phone Ext" type="number" />
        </form>

        <div class="button">
          <el-button type="primary" @click="confirmSave()">Create</el-button>
        </div>
      </panel>
    </div>
  </layout>
</template>

<script>
import Layout from '../common/Layout'
import Panel from '../common/Panel'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'

import { required, numeric, email, alpha } from 'vuelidate/lib/validators'

export default {
  name: 'CreateNewStaff',

  components: {
    Panel,
    Layout,
    ExInput,
    ExSelect
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        phoneExt: null,
        role: null
      },
      roles: null
    }
  },

  computed: {
    roleOptions () {
      if (!this.roles) return []

      return this.roles.filter(role => role.user_group === 'staff').map(role => {
        return {
          id: role.name,
          text: window.roles[role.name]
        }
      })
    }
  },

  async created () {
    this.roles = (await api.list('Role')).items
  },

  methods: {
    async beforeSave () {
      try {
        await api.request("POST", "/validate/employee", { email: this.form.email });
        this.save()
      } catch (e) {
        this.$message.error('Email already exists. Please try again or contact Support')
      }
    },

    async save () {
      try {
        const payload = {
          ...this.form,
          created: 'CURRENT_TIMESTAMP',
          uuid: 'GET_USER_UUID',
          activationCode: 'USER_ACTIVATION_CODE',
          activationCodeExpires: 'USER_ACTIVATION_CODE_EXPIRES',
          createdBy: window.appData.currentUser.id
        }
        const user = (await api.create('User', payload, true)).item
        await api.action('User', user.id, 'WelcomeEmail')
        this.clear()
        this.$alert(`New user successfully created.<br><br>Generated Activation Code is: <b>${user.activationCode}</b>`, 'New User Created', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true
        })
      } catch {
        this.$message.error('There was an error... Please try again.')     
      }
    },

    async confirmSave () {
      if (!this.$v.form.$invalid) {
        try {
          await this.$confirm('Are you sure you want to create new user?', 'Confirm')
          this.beforeSave()
        } catch {}
      } else {
        this.$v.form.$touch()
      }
    },

    clear () {
      Object.keys(this.form).forEach(key => this.form[key] = null)
      this.$v.$reset()
    }
  },

  validations: {
    form: {
      firstName: { required, alpha },
      lastName: { required, alpha },
      email: { required, email },
      phone: { required },
      role: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 685px;
  margin: 0 auto;
  .form {
    padding: 0;
    justify-content: center;
  }
  .button {
    text-align: center;
  }
}
</style>