<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        Project Build Date: {{ buildDate | date('LL') }}
      </el-col>
      <el-col :span="8" class="text-center">
        Project Build Number: 99{{ buildId }}
      </el-col>
      <el-col :span="8" class="text-right">
        Hosted Solutions
        <el-switch
          v-model="form.hosted"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-col>
    </el-row>

    <div class="mt-20">
      <el-row :gutter="40">
        <template v-for="(domain, key) in domains">
          <el-col
            :key="domain"
            :span="6"
            :offset="key === 'dev' ? 3 : 0">
            <div class="text-center">{{ domain }}</div>
            <div v-for="(di, name) in domainInputs" :key="name" class="mt-10">
              <ex-input v-model="form[`${key}_${name}`]" :validator="name === 'domain' ? validator.form[`${key}_${name}`] : null" :label="di" no-style full />
            </div>
          </el-col>
        </template>
      </el-row>
    </div>

    <table class="form-table mt-20">
      <tr class="heading">
        <td colspan="6">PROJECT INFORMATION</td>
      </tr>
      <tr>
        <td>Client Name</td>
        <td>{{ registration.company }}</td>
        <td>Client Reg Number</td>
        <td>{{ registration.id }}</td>
        <td>Project Scope Number</td>
        <td>
          <ex-select v-model="scope" :options="scopeOptions" class="m-0" no-style full />
        </td>
      </tr>
      <tr>
        <td>Project Name</td>
        <td>
          <ex-input v-model="form.name" :validator="validator.form.name" full no-style />
        </td>
        <td>Est. Project Start</td>
        <td>
          <datepicker
            v-model="form.start_date"
            :class="{ error: validator.form.start_date.$dirty && validator.form.start_date.$invalid }"
            :disabled-dates="disabledStartDates"
            calendar-button
            calendar-button-icon="el-icon-date"
            placeholder="Est. Project Start" />
        </td>
        <td>Est. Project Deadline</td>
        <td>
          <datepicker
            v-model="form.end_date"
            :class="{ error: validator.form.end_date.$dirty && validator.form.end_date.$invalid }"
            :disabled="!form.start_date"
            :disabled-dates="disabledEndDates"
            calendar-button-icon="el-icon-date"
            calendar-button
            placeholder="Est. Project Deadline" />
        </td>
      </tr>
      <tr>
        <td>Account Manager</td>
        <td>{{ accountManager.firstName }} {{ accountManager.lastName }}</td>
        <td>Project Manager</td>
        <td>{{ projectManager.firstName }} {{ projectManager.lastName }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr class="heading">
        <td colspan="6">SCOPE OF WORK</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.scope_of_work" maxlength="5000" full no-margin />
        </td>
      </tr>
      <tr class="heading">
        <td colspan="6">CUSTOMIZATIONS</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.customizations" maxlength="5000" full no-margin />
        </td>
      </tr>
      <tr class="heading">
        <td colspan="6">CLIENT TO PROVIDE</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.client_to_provide" maxlength="5000" full no-margin />
        </td>
      </tr>
      <tr class="heading">
        <td colspan="6">ADDITIONAL PROJECT NOTES</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.additional_notes" maxlength="5000" full no-margin />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'
import ExInput from '../../common/Input'
import Datepicker from 'vuejs-datepicker'
import ExSelect from '../../common/Select'
import ExTextarea from '../../common/Textarea'

export default {
  components: {
    ExInput,
    ExSelect,
    ExTextarea,
    Datepicker
  },

  mixins: [helperMixin],

  props: {
    value: {
      type: Object,
      default: null
    },

    validator: {
      type: Object,
      default: null
    },
    
    edit: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    scope: null,

    domains: {
      dev: 'Development Domain',
      staging: 'Staging Domain',
      live: 'Live Domain'
    },

    domainInputs: {
      domain: 'Domain',
      username: 'Username',
      password: 'Password',
    }      
  }),

  computed: {
    disabledStartDates () {
      if (this.edit)
      return {
        to: this.edit ? moment(this.edit.start_date).toDate() : moment().subtract(1, "days").toDate()
      }
    },

    disabledEndDates () {
      return {
        to: typeof this.form.start_date === 'string' ? moment(this.form.start_date).toDate() : this.form.start_date
      }
    },

    buildDate () {
      return this.edit ? this.edit.createdAt : moment()
    },

    projects () {
      return this.$store.state.projects.data
    },

    buildId () {
      if (this.edit) return this.edit.id
      if (!this.projects || !this.projects.length) return 100
      return this.getMax(this.projects.map(project => parseInt(project.id))) + 1
    },

    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    scopes () {
      return this.$store.state.projects.scopes.data || []
    },

    scopeOptions () {
      return this.scopes.map(scope => {
        return {
          id: scope.id,
          text: scope.scopeId
        }
      })
    },

    accountManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.accountManager : {}
    },

    projectManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.clientServicesRep : {}
    },

    registration () {
      return this.$store.state.client.single.registration
    }
  },

  watch: {
    scope (value) {
      if (value) {
        const scope = this.scopes.find(scope => scope.id === value)
        this.form.accountManager = scope.accountManager.id
        this.form.projectManager = scope.projectManager.id
        const keys = ['additional_notes', 'client_to_provide', 'customizations', 'end_date', 'name', 'scope_of_work', 'start_date']
        let schedulePricing = JSON.parse(scope.schedule_pricing)
        keys.forEach(key => this.form[key] = scope[key])
        Object.keys(schedulePricing).forEach(key => this.form[key] = schedulePricing[key])
      }
    }
  }
}
</script>