var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("logo"),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.login()
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.username,
                      expression: "form.username"
                    }
                  ],
                  staticClass: "form-inp",
                  attrs: { type: "text", placeholder: "Username" },
                  domProps: { value: _vm.form.username },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "username", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password"
                    }
                  ],
                  staticClass: "form-inp",
                  attrs: { type: "password", placeholder: "Password" },
                  domProps: { value: _vm.form.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "password", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("button", { staticClass: "login-button" }, [_vm._v("LOGIN")])
              ]
            ),
            _vm._v(" "),
            _c("get-plugged-footer")
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.showActivateAcc
        ? _c("activate-account", {
            attrs: { user: _vm.showActivateAcc },
            on: {
              activated: function($event) {
                return _vm.onActivate()
              },
              close: function($event) {
                _vm.showActivateAcc = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showResetPw
        ? _c("reset-password", {
            attrs: { user: _vm.showResetPw },
            on: {
              reset: function($event) {
                return _vm.onReset()
              },
              close: function($event) {
                _vm.showResetPw = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }