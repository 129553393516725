var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: "/clients/projects" } },
    [
      _c(
        "div",
        [
          _c("ex-select", {
            attrs: { options: _vm.statusOptions, "no-margin": "" },
            model: {
              value: _vm.status,
              callback: function($$v) {
                _vm.status = $$v
              },
              expression: "status"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.load()
                }
              }
            },
            [_vm._v("Submit")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { data: _vm.projects, stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "Status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          { attrs: { effect: "dark", size: "mini" } },
                          [_vm._v(_vm._s(scope.row.status))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Project Build" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openEdit(scope.row)
                              }
                            }
                          },
                          [_c("b", [_vm._v("99" + _vm._s(scope.row.id))])]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Setup Date" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(scope.row.start_date)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Project Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.name) + "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Client ID" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/clients/registrations/" + scope.row.client.id
                            }
                          },
                          [
                            _c("b", [
                              _vm._v(_vm._s(scope.row.client.userId.uuid))
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Company Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.client.company) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Tracker" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.getTracker(scope.row)
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getTracker(scope.row)) +
                                  "\n          "
                              )
                            ]
                          : [_vm._v("N/A")]
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Options", width: "100", align: "right" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.edit
        ? _c("manage-project", {
            attrs: { edit: _vm.edit },
            on: {
              close: function($event) {
                _vm.edit = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }