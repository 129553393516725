var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      on: {
        close: function($event) {
          _vm.open = false
        }
      },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "div",
        { staticClass: "pad20" },
        [
          _c(
            "div",
            { staticClass: "floatr" },
            [
              _vm.event && _vm.readonly && !_vm.viewOnly
                ? [
                    _c(
                      "chButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$emit("setEdit", true)
                          }
                        }
                      },
                      [_vm._v("Edit Event")]
                    ),
                    _vm._v(" "),
                    _c(
                      "chButton",
                      {
                        attrs: { color: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.deleteEvent(_vm.event.id)
                          }
                        }
                      },
                      [_vm._v("Delete Event")]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.event
                ? [
                    _c(
                      "chButton",
                      {
                        attrs: { color: "gray" },
                        on: {
                          click: function($event) {
                            return _vm.clear()
                          }
                        }
                      },
                      [_vm._v("Clear Event")]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.event || !_vm.readonly
                ? _c(
                    "chButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Save Event")]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "clear-after" }),
          _vm._v(" "),
          _vm.open
            ? _c("createEventForm", {
                ref: "event",
                attrs: {
                  type: _vm.type,
                  event: _vm.event,
                  preselectedDate: _vm.preselectedDate,
                  readonly: _vm.readonly
                },
                on: {
                  save: function($event) {
                    return _vm.onEventUpdate($event)
                  },
                  remove: function($event) {
                    return _vm.removeTag($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }