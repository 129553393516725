const state = {
  users: null
}

const getters = {
  getUsersByType: state => role => state.users.filter(user => user.active === 'Yes' && user.role === role)
}

const actions = {
  async get ({ state, commit }, force = false) {
    if (state.users && !force) return state.users
    const users = (await api.list('User')).items
    commit('setUsers', users)
    return users
  }
}

const mutations = {
  setUsers (state, users) {
    state.users = users
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}