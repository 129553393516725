var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "buttons" },
        [
          !_vm.edit && _vm.canEdit
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-edit",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.startEdit()
                    }
                  }
                },
                [_vm._v("Edit")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.edit
            ? [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      icon: "el-icon-edit",
                      type: "success"
                    },
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [_vm._v("Save")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-edit" },
                    on: {
                      click: function($event) {
                        _vm.edit = false
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "info-box" }, [
        _c("div", { staticClass: "col" }, [
          _c("span", [_vm._v("Registration Date")]),
          _vm._v(" "),
          _c("b", [_vm._v(_vm._s(_vm._f("date")(_vm.data.created, "PD")))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("span", [_vm._v("Registration ID")]),
          _vm._v(" "),
          _c("b", [_vm._v(_vm._s(_vm.data.id))])
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.general, function(info, idx) {
        return _c(
          "div",
          { key: idx, staticClass: "info-box" },
          [
            _c("h3", [_vm._v(_vm._s(info.name))]),
            _vm._v(" "),
            _vm._l(info.items, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "col" },
                [
                  _c("span", [_vm._v(_vm._s(item.name))]),
                  _vm._v(" "),
                  _vm.edit
                    ? [
                        ["email", "text"].includes(item.input)
                          ? [
                              _c("ex-input", {
                                staticClass: "m-0",
                                attrs: {
                                  type: item.input,
                                  validator: item.validation,
                                  full: ""
                                },
                                model: {
                                  value: _vm.form[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, item.key, $$v)
                                  },
                                  expression: "form[item.key]"
                                }
                              })
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        item.input === "select"
                          ? _c("ex-select", {
                              staticClass: "m-0",
                              attrs: {
                                disabled: item.disabled || false,
                                validator: item.validation,
                                options: item.options,
                                full: ""
                              },
                              model: {
                                value: _vm.form[item.key],
                                callback: function($$v) {
                                  _vm.$set(_vm.form, item.key, $$v)
                                },
                                expression: "form[item.key]"
                              }
                            })
                          : _vm._e()
                      ]
                    : _c("b", [_vm._v(_vm._s(item.value))])
                ],
                2
              )
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }