var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "Activate Your Account", width: 400 },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "data" }, [
          _vm._v("\n      Account ID: "),
          _c("b", [_vm._v(_vm._s(_vm.user.uuid))]),
          _c("br"),
          _vm._v("\n      Username: "),
          _c("b", [_vm._v(_vm._s(_vm.user.email))])
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "label" }, [_vm._v("Set Password:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.password,
              expression: "form.password"
            }
          ],
          ref: "input",
          staticClass: "form-inp",
          attrs: { type: "password" },
          domProps: { value: _vm.form.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "password", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.showError(_vm.$v.form.password)
          ? _c("span", { staticClass: "input-error" }, [
              _vm._v(
                "Password needs to contain small letter, capital letter, number, symbol and at least 6 characters."
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("label", { staticClass: "label" }, [_vm._v("Confirm Password:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.confirmPassword,
              expression: "form.confirmPassword"
            }
          ],
          staticClass: "form-inp",
          attrs: { type: "password" },
          domProps: { value: _vm.form.confirmPassword },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "confirmPassword", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.showError(_vm.$v.form.confirmPassword)
          ? _c("span", { staticClass: "input-error" }, [
              _vm._v("Passwords do not match.")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "agree" }, [
          _vm._v("\n      By activating my account, I agree to the:\n      "),
          _c("div", { staticClass: "checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.agree.terms,
                  expression: "agree.terms"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.agree.terms)
                  ? _vm._i(_vm.agree.terms, null) > -1
                  : _vm.agree.terms
              },
              on: {
                change: function($event) {
                  var $$a = _vm.agree.terms,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.agree, "terms", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.agree,
                          "terms",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.agree, "terms", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", [_vm._v("Terms and Conditions")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.agree.something,
                  expression: "agree.something"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.agree.something)
                  ? _vm._i(_vm.agree.something, null) > -1
                  : _vm.agree.something
              },
              on: {
                change: function($event) {
                  var $$a = _vm.agree.something,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.agree, "something", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.agree,
                          "something",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.agree, "something", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", [_vm._v("Something 2")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.agree.something2,
                  expression: "agree.something2"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.agree.something2)
                  ? _vm._i(_vm.agree.something2, null) > -1
                  : _vm.agree.something2
              },
              on: {
                change: function($event) {
                  var $$a = _vm.agree.something2,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.agree, "something2", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.agree,
                          "something2",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.agree, "something2", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", [_vm._v("Something 3")])
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "login-button",
            on: {
              click: function($event) {
                return _vm.activate()
              }
            }
          },
          [_vm._v("Activate")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "sublink" }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Cancel")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }