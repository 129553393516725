<template>
  <div class="content">
    <div class="row">
      <div class="col">
        <label>Date: {{ now | date('LL') }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ex-select v-model="form.build" label="Plugg Build" :validator="validator.form.build" :options="buildOptions" full no-style />
      </div>
      <div class="col">
        <ex-select v-model="form.build2" label="Plugg Build 2" :options="buildOptions" full no-style />
      </div>
      <div class="col">
        <ex-select v-model="form.build3" label="Plugg Build 3" :options="buildOptions" full no-style />
      </div>
    </div>

    <table class="form-table">
      <tr class="heading">
        <td colspan="6">PROJECT INFORMATION</td>
      </tr>
      <tr>
        <td>Client Name</td>
        <td>{{ registration.company }}</td>
        <td>Client Reg Number</td>
        <td>{{ registration.id }}</td>
        <td>Project Scope Number</td>
        <td>{{ nextId }}</td>
      </tr>
      <tr>
        <td>Project Name</td>
        <td>
          <ex-input v-model="form.name" :validator="validator.form.name" full no-style />
        </td>
        <td>Est. Project Start</td>
        <td>
          <datepicker
            v-model="form.start_date"
            :class="{ error: validator.form.start_date.$dirty && validator.form.start_date.$invalid }"
            calendar-button
            calendar-button-icon="el-icon-date"
            placeholder="Est. Project Start" />
        </td>
        <td>Est. Project Deadline</td>
        <td>
          <datepicker
            v-model="form.end_date"
            :class="{ error: validator.form.end_date.$dirty && validator.form.end_date.$invalid }"
            calendar-button-icon="el-icon-date"
            calendar-button
            placeholder="Est. Project Deadline" />
        </td>
      </tr>
      <tr>
        <td>Account Manager</td>
        <td>{{ accountManager.firstName }} {{ accountManager.lastName }}</td>
        <td>Project Manager</td>
        <td>{{ projectManager.firstName }} {{ projectManager.lastName }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr class="heading">
        <td colspan="6">SCOPE OF WORK</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.scope_of_work" maxlength="5000" full no-margin />
        </td>
      </tr>
      <tr class="heading">
        <td colspan="6">CUSTOMIZATIONS</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.customizations" maxlength="5000" full no-margin />
        </td>
      </tr>
      <tr class="heading">
        <td colspan="6">CLIENT TO PROVIDE</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.client_to_provide" maxlength="5000" full no-margin />
        </td>
      </tr>
      <tr class="heading">
        <td colspan="6">ADDITIONAL PROJECT NOTES</td>
      </tr>
      <tr>
        <td colspan="6" class="p-0">
          <ex-textarea v-model="form.additional_notes" maxlength="5000" full no-margin />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import ExInput from '../../common/Input'
import Datepicker from 'vuejs-datepicker'
import ExSelect from '../../common/Select'
import ExTextarea from '../../common/Textarea'
import WorksheetContacts from './WorksheetContacts'

export default {
  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea,
    WorksheetContacts
  },

  props: {
    value: {
      type: Object,
      default: null
    },

    validator: {
      type: Object,
      default: null
    },

    nextId: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      now: moment()
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    accountManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.accountManager : {}
    },

    projectManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.clientServicesRep : {}
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    buildOptions () {
      const data = this.$store.state.system.builds.data || []
      return [{ 
        id: 'CUSTOM',
        text: 'Custom'
      }].concat( data.map(item => {
        return {
          id: item.id,
          text: `${item.name}/${item.version}`
        }
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  .col {
    width: 33%;
  }
}
</style>