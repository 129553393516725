var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _vm.data.userId && !_vm.edit
          ? _c(
              "el-button",
              {
                attrs: { size: "mini", icon: "el-icon-edit", type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.startEdit()
                  }
                }
              },
              [_vm._v("Edit")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.edit
          ? [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-edit",
                    type: "success"
                  },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-edit" },
                  on: {
                    click: function($event) {
                      _vm.edit = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "info-box" }, [
      _c("div", { staticClass: "col" }, [
        _c("span", [_vm._v("Client ID")]),
        _vm._v(" "),
        _c("b", [
          _vm._v(_vm._s(_vm.data.userId ? _vm.data.userId.uuid : "N/A"))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("span", [_vm._v("Account ID")]),
        _vm._v(" "),
        _c("b", [
          _vm._v(_vm._s(_vm.data.userId ? _vm.data.userId.accountId : "N/A"))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("span", [_vm._v("Setup Date")]),
        _vm._v(" "),
        _vm.data.userId && _vm.data.userId.created
          ? _c("b", [_vm._v(_vm._s(_vm._f("date")(_vm.data.userId.created)))])
          : _c("b", [_vm._v("N/A")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("span", [_vm._v("Activation Date")]),
        _vm._v(" "),
        _vm.data.userId && _vm.data.userId.activationDate
          ? _c("b", [
              _vm._v(_vm._s(_vm._f("date")(_vm.data.userId.activationDate)))
            ])
          : _c("b", [_vm._v("N/A")])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info-box" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("span", [_vm._v("Account Manager")]),
          _vm._v(" "),
          _vm.edit
            ? [
                _c("ex-select", {
                  staticClass: "m-0",
                  attrs: {
                    validator: _vm.$v.form.accountManager,
                    options: _vm.salesUsers,
                    full: ""
                  },
                  model: {
                    value: _vm.form.accountManager,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "accountManager", $$v)
                    },
                    expression: "form.accountManager"
                  }
                })
              ]
            : _c(
                "b",
                [
                  _vm.data.userId && _vm.clientAssign
                    ? [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.clientAssign.accountManager.firstName) +
                            " " +
                            _vm._s(_vm.clientAssign.accountManager.lastName) +
                            "\n        "
                        )
                      ]
                    : [_vm._v("N/A")]
                ],
                2
              )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("span", [_vm._v("Client Services Rep")]),
          _vm._v(" "),
          _vm.edit
            ? [
                _c("ex-select", {
                  staticClass: "m-0",
                  attrs: {
                    validator: _vm.$v.form.clientServicesRep,
                    options: _vm.clientUsers,
                    full: ""
                  },
                  model: {
                    value: _vm.form.clientServicesRep,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "clientServicesRep", $$v)
                    },
                    expression: "form.clientServicesRep"
                  }
                })
              ]
            : _c(
                "b",
                [
                  _vm.data.userId && _vm.clientAssign
                    ? [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.clientAssign.clientServicesRep.firstName
                            ) +
                            " " +
                            _vm._s(
                              _vm.clientAssign.clientServicesRep.lastName
                            ) +
                            "\n        "
                        )
                      ]
                    : [_vm._v("N/A")]
                ],
                2
              )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }