<template>
  <layout active="/system/providers">
    <div class="wrapper">
      <panel title="System Providers">
        <div class="table-wrapper">
          <el-table :data="data" style="min-width: 100%" stripe>
            <el-table-column label="Date">
              <template slot-scope="scope">
                <span>{{ scope.row.createdAt | date('MMMM D YYYY') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Provider Name">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">{{ scope.row.name }}</span>
                <ex-input v-else v-model="scope.row.name" :validator="$v.data.$each.$iter[scope.$index].name" no-style />
              </template>
            </el-table-column>

            <el-table-column label="Version">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">{{ scope.row.version }}</span>
                <ex-input v-else v-model="scope.row.version" :validator="$v.data.$each.$iter[scope.$index].version" no-style />
              </template>
            </el-table-column>

            <el-table-column label="Category">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">{{ categoryName(scope.row.category) }}</span>
                <ex-select v-else v-model="scope.row.category"  :options="categories" :validator="$v.data.$each.$iter[scope.$index].category" no-style />
              </template>
            </el-table-column>

            <el-table-column label="Actions" width="100" align="right">
               <template slot="header">
                <el-button size="small" type="primary" icon="el-icon-plus" circle @click="addNew()" />
              </template>

              <template slot-scope="scope">
                <template v-if="!scope.row.id || scope.row.edit">
                  <el-button v-if="!scope.row.id" size="mini" type="danger" icon="el-icon-close" circle @click="remove(scope)" />
                  <el-button size="mini" type="success" icon="el-icon-check" circle @click="onSave(scope.$index)" />
                </template>
                <template v-else>
                  <el-button size="mini" icon="el-icon-edit" circle @click="openEdit(scope.$index)" />
                  <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="deleteProvider(scope)" />
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </panel>
    </div>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import { required, numeric, email, alpha } from 'vuelidate/lib/validators'

import Panel from '../common/Panel'
import Layout from '../common/Layout'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'

export default {
  name: 'system-providers',

  components: {
    Panel,
    Layout,
    ExInput,
    ExSelect
  },

  data () {
    return {
      data: [],

      categories: [
        {
          id: 'payments',
          text: 'Payments'
        },
        {
          id: 'lending',
          text: 'Lending'
        },
        {
          id: 'risk_compliance',
          text: 'Risk & Compliance'
        },
        {
          id: 'portals',
          text: 'Portals'
        },
        {
          id: 'billing_invoicing',
          text: 'Billing & Invoicing'
        },
        {
          id: 'back_office',
          text: 'Back-Office'
        },
        {
          id: 'software',
          text: 'Software'
        },
        {
          id: 'flyboard',
          text: 'FlyBoard'
        }
      ]
    }
  },

  async created () {
    this.data = [...(await this.load())]
  },
  
  methods: {
    ...mapActions({
      load: 'system/provider/load',
      create: 'system/provider/create',
      delete: 'system/provider/delete',
      update: 'system/provider/update'
    }),

    async onSave (index) {
      this.$v.data.$each.$iter[index].$touch()
      if(!this.$v.data.$each.$iter[index].$invalid) {
        const loading = this.$loading()
        let data = null
        if (!this.data[index].id) {
          const payload = {
            ...this.data[index],
            createdAt: 'CURRENT_TIMESTAMP'
          }
          data = await this.create(payload)
        } else {
          const payload = {
            id: this.data[index].id,
            payload: this.data[index]
          }
          data = await this.update(payload)
        }
        this.$set(this.data, index, data)
        loading.close()
      } else {
        this.$message.error('In order to save Provider, all fields need to be filled!')
      }
    },

    openEdit (index) {
      this.$set(this.data[index], 'edit', true)
    },

    async deleteProvider (scope) {
      try {
        await this.$confirm(`Are you sure to delete Provider "${scope.row.name}"?`, 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'error'
        })
        const loading = this.$loading()
        await this.delete(scope.row.id)
        this.$message.success('Provider deleted successfully.')
        this.data.splice(scope.$index, 1)
        loading.close()
      } catch {}
    },

    remove (scope) {
      if (scope.row.id) {
        this.$set(this.data[scope.$index], 'edit', false)
      } else {
        this.data.splice(scope.$index, 1)
      }
    },

    addNew () {
      this.data.push({
        name: null,
        version: null,
        category: null,
        edit: true
      })
    },

    categoryName (name) {
      return this.categories.find(item => item.id === name).text
    }
  },

  validations: {
    data: {
      required,
      $each: {
        name: { required },
        version: { required },
        category: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
</style>