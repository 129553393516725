<template>
  <div>
    <div v-if="data.userId" class="form-wrapper">
      <div class="type">
        <ex-input v-model="form.type" label="Type" :validator="$v.form.type" no-style full />
      </div>
      <div class="buttons">
        <el-button size="mini" icon="el-icon-edit" :type="chooseFileColor" @click="$refs.upload.click()">Choose file</el-button>
        <el-button size="mini" icon="el-icon-edit" type="primary" @click="save()">Upload</el-button>
      </div>
    </div>
    <input v-show="false" ref="upload" type="file" @change="uploadFile" />

    <div class="table-wrapper">
      <el-table :data="documents" style="min-width: 100%" stripe>
        <el-table-column label="Type">
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>

        <el-table-column label="Filename">
          <template slot-scope="scope">
            {{ scope.row.file }}
          </template>
        </el-table-column>

        <el-table-column label="Uploaded">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Actions" align="right">
          <template slot-scope="scope">
            <a :href="`/download/ClientDocument/${scope.row.id}`" target="_blank">
              <el-button size="mini" icon="el-icon-download" type="primary">View / Download</el-button>
            </a>
            <el-button v-if="isUserAdmin" size="mini" icon="el-icon-delete" type="danger" @click="remove(scope.row.id)">Remove</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import ExInput from '../common/Input'

import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import helperMixin from '../common/helpers-mixin';

export default {
  components: {
    ExInput
  },

  mixins: [ helperMixin ],

  data () {
    return {
      form: {
        type: ''
      },

      file: null
    }
  },

  computed: {
    chooseFileColor () {
      if (this.$v.file.$dirty && this.$v.file.$invalid) return 'danger'
      return this.file ? 'success' : 'default'
    },

    data () {
      return this.$store.state.client.single.registration
    },

    documents () {
      return this.data.userId ? this.$store.state.client.single.documents : []
    }
  },

  created () {
    this.load()
  },

  methods: {
    ...mapActions({
      loadDocuments: 'client/single/loadDocuments',
      deleteDocument: 'client/single/deleteDocument',
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument'
    }),

    load () {
      return this.loadDocuments({ id: this.data.id, force: true })
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const loading = this.$loading()
        const payload = {
          ...this.form,
          clientRegId: this.data.id,
          createdAt: 'CURRENT_TIMESTAMP'
        }
        const document = await this.createDocument(payload)
        await this.uploadDocument({ id: document.id, file: this.file })
        await this.load()
        this.clear()
        this.$message.success('Successfully uploaded document.')
        this.$v.$reset()
        loading.close()
      }
    },

    async remove(documentId) {
      const loading = this.$loading();
      try {
        await this.$confirm('Are you sure you want to delete this document?');
        try {
          await this.deleteDocument(documentId);
          this.$message.success('Document deleted.');
        } catch (e) {
          console.log(e.message);
          this.$message.error('Something went wrong');
        }
      } catch {}

      loading.close();
    },

    clear () {
      this.$refs.upload.value = null
      this.file = null
      this.form.type = null
    },

    async uploadFile ($e) {
      const file = $e.target.files[0]
      if (file) this.file = file
    }
  },

  
  validations: {
    form: {
      type: { required }
    },
    file: { required }
  }
}
</script>

<style lang="scss" scoped>
.form-wrapper {
  display: flex;
  justify-content: center;
  .type {
    width: 300px;
  }
  .buttons {
    margin-top: 17px;
    margin-left: 15px;
  }
}
</style>