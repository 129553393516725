var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "Reset Password", width: 400 },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "data" }, [
          _vm._v("\n      Account ID: "),
          _c("b", [_vm._v(_vm._s(_vm.user.uuid))]),
          _c("br"),
          _vm._v("\n      Username: "),
          _c("b", [_vm._v(_vm._s(_vm.user.email))])
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "label" }, [_vm._v("Set Password:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.password,
              expression: "form.password"
            }
          ],
          ref: "input",
          staticClass: "form-inp",
          attrs: { type: "password" },
          domProps: { value: _vm.form.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "password", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.showError(_vm.$v.form.password)
          ? _c("span", { staticClass: "input-error" }, [
              _vm._v(
                "Password needs to contain small letter, capital letter, number, symbol and at least 6 characters."
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("label", { staticClass: "label" }, [_vm._v("Confirm Password:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.confirmPassword,
              expression: "form.confirmPassword"
            }
          ],
          staticClass: "form-inp",
          attrs: { type: "password" },
          domProps: { value: _vm.form.confirmPassword },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "confirmPassword", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.showError(_vm.$v.form.confirmPassword)
          ? _c("span", { staticClass: "input-error" }, [
              _vm._v("Passwords do not match.")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "login-button",
            on: {
              click: function($event) {
                return _vm.reset()
              }
            }
          },
          [_vm._v("Reset")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "sublink" }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Cancel")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }