var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "table-wrapper" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticStyle: { "min-width": "100%" },
            attrs: { data: [null], stripe: "" }
          },
          [
            _c("el-table-column", { attrs: { label: "Status" } }, [
              _c("div", { staticClass: "status" }, [
                _c("i", {
                  class: _vm.signed
                    ? "el-icon-s-claim success"
                    : "el-icon-s-release"
                })
              ])
            ]),
            _vm._v(" "),
            _vm.signed
              ? _c("el-table-column", { attrs: { label: "Filename" } }, [
                  _c(
                    "a",
                    { attrs: { href: _vm.signedFileUrl, target: "_blank" } },
                    [_vm._v(_vm._s(_vm.nda.signedFile.file))]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", { attrs: { label: "Name" } }, [
              _vm._v("\n        Client NDA\n      ")
            ]),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { label: "Created by" } },
              [
                !_vm.nda
                  ? [_vm._v("N/A")]
                  : [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.nda.createdBy.firstName) +
                          " " +
                          _vm._s(_vm.nda.createdBy.lastName) +
                          "\n        "
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { label: "Created at" } },
              [
                !_vm.nda
                  ? [_vm._v("N/A")]
                  : [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("date")(_vm.nda.createdAt)) +
                          "\n        "
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { label: "Signed at" } },
              [
                !_vm.signed
                  ? [_vm._v("N/A")]
                  : [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("date")(_vm.nda.signedAt)) +
                          "\n        "
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            !_vm.registration.userId
              ? _c(
                  "el-table-column",
                  { attrs: { label: "Actions", align: "right", width: "350" } },
                  [
                    !_vm.nda
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.create()
                              }
                            }
                          },
                          [_vm._v("New")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.remove()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(!_vm.signed ? "Remove" : "Remove Signed")
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: _vm.viewUrl, target: "_blank" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: !_vm.nda,
                              icon: "el-icon-document"
                            }
                          },
                          [_vm._v("View")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.signed
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: !_vm.nda,
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.upload.click()
                              }
                            }
                          },
                          [_vm._v("Upload signed")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      ref: "upload",
                      attrs: { type: "file" },
                      on: { change: _vm.uploadFile }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-box" }, [
      _c("h3", [_vm._v("Client NDA")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }