var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "div",
            [
              _vm.selected.length
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-refresh" },
                        on: {
                          click: function($event) {
                            return _vm.newCode()
                          }
                        }
                      },
                      [_vm._v("Reset Activation Code")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-key" },
                        on: {
                          click: function($event) {
                            return _vm.resetPassword()
                          }
                        }
                      },
                      [_vm._v("Reset Password")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          icon: "el-icon-remove",
                          disabled: _vm.clientIsSelected
                        },
                        on: {
                          click: function($event) {
                            return _vm.block()
                          }
                        }
                      },
                      [_vm._v("Block")]
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.userId && _vm.users && _vm.users.length < 4
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus"
                  },
                  on: {
                    click: function($event) {
                      _vm.showAddUser = true
                    }
                  }
                },
                [_vm._v("Create Sub-User")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { "min-width": "100%" },
              attrs: { data: _vm.users, stripe: "" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "First Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.firstName))]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Last Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.lastName))]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Email" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.email))]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Activation Code" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.isActive(scope.row)
                          ? [_vm._v("N/A")]
                          : [
                              !_vm.isACExpired(scope.row)
                                ? [_vm._v(_vm._s(scope.row.activationCode))]
                                : _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "danger",
                                        effect: "dark",
                                        size: "mini"
                                      }
                                    },
                                    [_vm._v("Expired")]
                                  )
                            ]
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Activation Code Expires" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.isActive(scope.row) &&
                        !_vm.isACExpired(scope.row) &&
                        _vm.activationExpires(scope.row)
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.activationExpires(scope.row)
                                  )
                                )
                              )
                            ])
                          : _c("span", [_vm._v("N/A")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Activation Date" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.isActive(scope.row)
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(scope.row.activationDate, "PD")
                                )
                              )
                            ]
                          : [_vm._v("N/A")]
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Reset pass Request" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.pwResetRequested
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(
                                    scope.row.pwResetRequested,
                                    "PD"
                                  )
                                )
                              )
                            ]
                          : [_vm._v("N/A")]
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Reset Pass Completed" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.resetPwDate
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(scope.row.resetPwDate, "PD")
                                )
                              )
                            ]
                          : [_vm._v("N/A")]
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Blocked" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.active === "Blocked" ? "Yes" : "No")
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showAddUser
        ? _c("client-add-user", {
            attrs: { id: _vm.userId },
            on: {
              save: function($event) {
                return _vm.onUserCreated()
              },
              close: function($event) {
                _vm.showAddUser = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }