var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: "/system/changelog" } }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("panel", { attrs: { title: "Change Log" } }, [
          _c(
            "div",
            { staticClass: "add-btn" },
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "success",
                  icon: "el-icon-circle-plus",
                  circle: ""
                },
                on: {
                  click: function($event) {
                    return _vm.add()
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ex-table" }, [
            _c("header", [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col", staticStyle: { width: "230px" } },
                  [_vm._v("Date")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col", staticStyle: { width: "230px" } },
                  [_vm._v("Version")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [_vm._v("Description")])
              ])
            ]),
            _vm._v(" "),
            _c(
              "main",
              _vm._l(_vm.form, function(item, idx) {
                return _c("div", { key: idx, staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col", staticStyle: { width: "250px" } },
                    [
                      _c("datepicker", {
                        class: {
                          error:
                            _vm.$v.form.$each.$iter[idx].start_date.$dirty &&
                            _vm.$v.form.$each.$iter[idx].start_date.$invalid
                        },
                        attrs: {
                          "calendar-button": "",
                          "calendar-button-icon": "el-icon-date",
                          format: "d MMM yyyy",
                          placeholder: "Date",
                          disabled: !_vm.form[idx].disabled,
                          "input-class": {
                            disabled: !_vm.form[idx].disabled,
                            datepicker: true
                          }
                        },
                        model: {
                          value: _vm.form[idx].start_date,
                          callback: function($$v) {
                            _vm.$set(_vm.form[idx], "start_date", $$v)
                          },
                          expression: "form[idx].start_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col", staticStyle: { width: "230px" } },
                    [
                      _c("ex-input", {
                        attrs: {
                          classes: {
                            disabled: !_vm.form[idx].disabled,
                            "changelog-input": true
                          },
                          validator: _vm.$v.form.$each.$iter[idx].version,
                          "no-style": "",
                          full: "",
                          disabled: !_vm.form[idx].disabled
                        },
                        model: {
                          value: _vm.form[idx].version,
                          callback: function($$v) {
                            _vm.$set(_vm.form[idx], "version", $$v)
                          },
                          expression: "form[idx].version"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("ex-textarea", {
                        attrs: {
                          classes: {
                            disabled: !_vm.form[idx].disabled,
                            textarea: true
                          },
                          validator: _vm.$v.form.$each.$iter[idx].description,
                          "no-style": "",
                          full: "",
                          disabled: !_vm.form[idx].disabled
                        },
                        model: {
                          value: _vm.form[idx].description,
                          callback: function($$v) {
                            _vm.$set(_vm.form[idx], "description", $$v)
                          },
                          expression: "form[idx].description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "edit-buttons" },
                    [
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-edit",
                          circle: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.edit(item, idx)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          icon: "el-icon-delete",
                          circle: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.remove(item, idx)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }