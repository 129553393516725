<template>
  <layout :active="active">
    <div class="add-btn">
      <a :href="`/system/create-staff-users`">
        <el-button size="mini" type="success" icon="el-icon-circle-plus" circle />
      </a>
    </div>
    <div v-if="!loading" class="table-wrapper">
      <el-table :data="list" style="min-width: 100%" stripe>
        <el-table-column label="Emp ID">
          <template slot-scope="scope">
            <a :href="`/system/employee/${scope.row.id}`">
              <b>{{ scope.row.uuid }}</b>
            </a>
          </template>
        </el-table-column>

        <el-table-column label="First Name">
          <template slot-scope="scope">{{ scope.row.firstName }}</template>
        </el-table-column>

        <el-table-column label="Last Name">
          <template slot-scope="scope">{{ scope.row.lastName }}</template>
        </el-table-column>

        <el-table-column label="Email">
          <template slot-scope="scope">{{ scope.row.email }}</template>
        </el-table-column>

        <el-table-column label="Role">
          <template slot-scope="scope">
            <el-tag type="success" effect="dark" size="mini">{{ scope.row.role | role }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </layout>
</template>

<script>
import Panel from '../common/Panel'
import Layout from '../common/Layout'

export default {
  name: 'Employees',

  props: {
    users: {
      type: String,
      default: null
    }
  },

  components: {
    Panel,
    Layout
  },

  data () {
    return {
      data: [],
      roles: null,
      loading: true
    }
  },

  computed: {
    active () {
      return this.users ? '/system/users' : '/system/employees'
    },

    staff () {
      return this.roles ? this.roles.filter(role => role.user_group === 'staff').map(role => role.name) : []
    },

    list () {
      return this.users ? this.data : this.data.filter(user => this.staff.includes(user.role))
    }
  },

  async created () {
    this.loading = true
    this.roles = (await api.list('Role')).items
    await this.load()
    this.loading = false
  },

  methods: {
    async load () {
      this.data = (await api.list('User')).items
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-btn {
    margin: 10px 0;
  }
</style>