<template>
  <div>
    <sub-menu :menu="menu" classes="left"/>
  </div>
</template>

<script>
import SubMenu from '../common/SubMenu'

export default {
  name: 'DocumentDepotMenu',

  components: {
    SubMenu
  },

  data () {
    return {
      menu: [
        {
          to: '/',
          text: 'Documents'
        },
        {
          to: '/system/document-depot/nda',
          text: 'System NDA'
        }
      ]
    }
  }
}
</script>