<template>
  <div>
    <div class="table-wrapper">
      <el-table :data="subscriptions" style="min-width: 100%" stripe>
        <el-table-column label="Day">
          <template slot-scope="scope">
            {{ scope.row.day }}
          </template>
        </el-table-column>

        <el-table-column label="Frequency">
          <template slot-scope="scope">
            {{ scope.row.frequency }}
          </template>
        </el-table-column>

        <el-table-column label="Note">
          <template slot-scope="scope">
            {{ scope.row.note }}
          </template>
        </el-table-column>

        <el-table-column label="Amount">
          <template slot-scope="scope">
            <el-tag effect="dark" size="small">
              {{ scope.row.amount }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'

export default {
  name: 'BillingSubscriptions',

  mixins: [ helperMixin ],

  data () {
    return {
      openForm: false,
      edit: null
    }
  },

  computed: {
    client () {
      return this.$store.state.client.single.registration
    },

    subscriptions () {
      return this.$store.state.client.subscriptions.data
    }
  },

  created () {
    this.$store.dispatch('client/subscriptions/load', { params: { client: this.client.id } })
  },

  methods: {
    openEdit (item) {
      this.edit = item
      this.openForm = true
    },

    openCreate () {
      this.edit = null
      this.openForm = true
    },

    async remove (id) {
      try {
        await this.$confirm('Are you sure to delete this subscription?')
        const loading = this.$loading()
        try {
          await this.$store.dispatch('client/subscriptions/delete', id)
          loading.close()
          this.$message.success('Subscription deleted.')
        } catch (e) {
          loading.close()
        }
      } catch {}
    },
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-bottom: 20px;
}
</style>