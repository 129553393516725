var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "modal-container",
          style: { width: _vm.width + "px" },
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _vm.title
            ? _c("div", { staticClass: "modal-header" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [_vm._t("default")], 2)
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "close",
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [_c("img", { attrs: { src: "/assets/images/close.png" } })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }