var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: "/management/invoicing" } },
    [
      _c("panel", { attrs: { title: "Invoicing Dashboard" } }, [
        _c(
          "div",
          { staticClass: "buttons mb-20" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
                on: {
                  click: function($event) {
                    _vm.addNewInvoice = true
                  }
                }
              },
              [_vm._v("New Invoice")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c(
              "el-table",
              {
                staticStyle: { "min-width": "100%" },
                attrs: { data: _vm.invoices, stripe: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Invoice #" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.id) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Filename" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return scope.row.file
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: {
                                      href:
                                        "/download/ClientDocument/" +
                                        scope.row.file.id,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.file.file) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            : undefined
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Invoice Date" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("date")(scope.row.createdAt, "LL")
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Actions", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.file && scope.row.file.id
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-edit",
                                    type: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openPdf(scope.row.file.id)
                                    }
                                  }
                                },
                                [_vm._v("\n              View\n            ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isUserAdmin
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-delete",
                                    type: "danger"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(scope.row.id)
                                    }
                                  }
                                },
                                [_vm._v("\n              Remove\n            ")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.addNewInvoice
        ? _c("new-invoice-modal", {
            on: {
              close: function($event) {
                _vm.addNewInvoice = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }