import Vue from 'vue'

const state = {
  users: {},
  logs: {}
}

const getters = {
  getUsersById: (state) => (id) => state.users[id],
  getUsersLogsById: (state) => (id) => state.logs[id]
}

const actions = {
  async get ({ state, commit }, { id, force = false }) {
    if (state.users[id] && !force) return state.users[id]
    const data = (await api.list('User', { parent: id })).items
    commit('setUsers', { id, data })
    return data
  },

  async getLogs ({ state, commit }, { id, force = false }) {
    if (state.logs[id] && !force) return state.logs[id]
    const data = (await api.list('UserLogin', { createdBy: id })).items
    commit('setLogs', { id, data })
    return data
  }
}

const mutations = {
  setUsers (state, { id, data }) {
    Vue.set(state.users, id, data)
  },

  setLogs (state, { id, data }) {
    Vue.set(state.logs, id, data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}