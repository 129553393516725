<template>
  <div>
    <div class="buttons">
      <ex-select v-model="status" :options="statusOptions" no-margin />
      <el-button size="mini" type="primary" @click="filter()">Filter by status</el-button>
      <el-button size="mini" icon="el-icon-edit" type="primary" @click="manage = true">New</el-button>
    </div>

    <div>
    </div>

    <div class="table-wrapper">
      <el-table :data="projects" stripe>
        
        <el-table-column label="Build #">
          <template slot-scope="scope">
            <a @click="openEdit(scope.row)">
              <b>99{{ scope.row.id }}</b>
            </a>
          </template>
        </el-table-column>

        <el-table-column label="Status">
          <template slot-scope="scope">
            <el-tag effect="dark" size="mini">{{ scope.row.status | projectStatus }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="Date Created">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Project Name">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>

        <el-table-column label="Client Name">
          <template slot-scope="scope">
            {{ scope.row.client.firstName }} {{ scope.row.client.lastName }}
          </template>
        </el-table-column>

        <el-table-column label="Signed Build"></el-table-column>

        <el-table-column label="Options" width="100" align="right">
          <template slot-scope="scope">
            <a :href="`/client/project-build/${scope.row.id}/pdf`" target="_blank">
              <el-button size="mini">View</el-button>
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <manage-project
      v-if="manage"
      :edit="edit"
      @close="close()" />
  </div>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'

import ExSelect from '../../common/Select'
import ManageProject from './ManageProject'

export default {
  components: {
    ExSelect,
    ManageProject
  },

  mixins: [helperMixin],

  data () {
    return {
      status: null,
      appliedStatus: null,
      edit: null,
      manage: false
    }
  },

  computed: {
    statusOptions () {
      return Object.keys(window.projectStatuses).map(key => {
        return {
          id: key,
          text: `${key} - ${window.projectStatuses[key]}`
        }
      })
    },

    client () {
      return this.$store.state.client.single.registration
    },

    projects () {
      let projects = this.$store.state.projects.data || []
      if (this.appliedStatus) {
        projects = projects.filter(item => item.status === this.appliedStatus)
      }
      return projects
    }
  },

  async created () {
    await this.$store.dispatch('projects/load', { order: ['id:DESC'], params: { client: this.client.id } })
    const projectId = this.getUrl('projectId')
    if (projectId) {
      const project = this.projects.find(pro => pro.id == projectId)
      if (project) {
        this.edit = project
        this.manage = true
      }
    }
  },

  methods: {
    filter () {
      this.appliedStatus = this.status
    },

    openEdit (project) {
      this.edit = project
      this.manage = true
    },

    close () {
      this.edit = null
      this.manage = false
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-bottom: 20px;
}
</style>