import $ from 'jquery';
import Vue from 'vue';
import store from '../store'

$(function($) {
  window.vueMixins=window.vueMixins || {}
  $("[vue-dyn]").each(function() {
    var $this=$(this);
    var mixins=$this.attr("vue-dyn").split(",").filter(function(a) {
      return a;
    }).map(function(a) {
      return window.vueMixins[a];
    });
    $this.removeAttr("vue-dyn");

    (new Vue({
      el: this,
      store,
      mixins: mixins
    }));
  });
});
