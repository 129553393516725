<template>
  <layout active="/management/invoicing">
    <panel title="Invoicing Dashboard">
      <div class="buttons mb-20">
        <el-button size="mini" icon="el-icon-plus" type="primary" @click="addNewInvoice = true">New Invoice</el-button>
      </div>

      <div class="table-wrapper">
        <el-table :data="invoices" style="min-width: 100%" stripe>
          <el-table-column label="Invoice #">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>

          <el-table-column label="Filename">
            <template v-if="scope.row.file" slot-scope="scope">
              <a :href="`/download/ClientDocument/${scope.row.file.id}`" target="_blank" class="link">
                {{ scope.row.file.file }}
              </a>
            </template>
          </el-table-column>

          <el-table-column label="Invoice Date">
            <template slot-scope="scope">
              {{ scope.row.createdAt | date('LL') }}
            </template>
          </el-table-column>

          <el-table-column label="Actions" align="right">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.file && scope.row.file.id"
                size="mini" icon="el-icon-edit" type="primary" @click="openPdf(scope.row.file.id)">
                View
              </el-button>
              <el-button v-if="isUserAdmin" size="mini" icon="el-icon-delete" type="danger" @click="remove(scope.row.id)">
                Remove
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </panel>

    <new-invoice-modal
      v-if="addNewInvoice"
      @close="addNewInvoice = false" />
  </layout>
</template>

<script>
import { mapState } from 'vuex'
import helperMixin from '../common/helpers-mixin'

import Layout from '../common/Layout'
import NewInvoiceModal  from './NewInvoiceModal'

export default {
  name: 'Invoicing',

  mixins: [ helperMixin ],

  components: {
    Layout,
    NewInvoiceModal
  },

  data () {
    return {
      addNewInvoice: false
    }
  },

  computed: {
    ...mapState({
      invoices: state => state.invoices.data
    })
  },

  created() {
    this.load()
  },

  methods: {
    async remove (id) {
      try {
        await this.$confirm('Are you sure to delete this invoice?')
        const loading = this.$loading()
        try {
          await this.$store.dispatch('invoices/delete', id)
          loading.close()
          this.$message.success('Invoice deleted.')
        } catch (e) {
          loading.close()
        }
      } catch {}
    },

    openPdf (documentId) {
      window.open(`/download/ClientDocument/${documentId}`, '_blank');
    },

    load () {
      this.$store.dispatch('invoices/load');
    }
  }
}
</script>
