<template>
  <form-item :label="label" :validator="validator" :class="{ full }" :no-style="noStyle" :no-margin="noMargin">
    <textarea
      ref="textarea"
      v-model="val" 
      :placeholder="`Enter ${label || 'text'}`" 
      :disabled="disabled"
      :class="classes"
      :rows="rows"
      @blur="$emit('blur')" />
  </form-item>
</template>

<script>
import autosize from 'autosize'
import inputMixin from './input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    rows: {
      type: String,
      default: '1'
    },

    full: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    },

    noMargin: {
      type: Boolean,
      default: false
    },

    classes: {
      type: Object,
      default: null
    }
  },

  mounted () {
    autosize(this.$refs.textarea);
  }
}
</script>

<style lang="scss" scoped>
  .full {
    width: 100%;
  }
</style>