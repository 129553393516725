<template>
  <layout :active="active">
    <tabs v-model="activeTab" :header="headerData" :tabs="tabs">
      <el-button v-for="(tab, idx) in subTabs[activeTab]" :key="tab" size="mini" :type="activeSubTab === idx ? 'primary' : null" @click="activeSubTab = idx">{{ tab }}</el-button>
      <el-button v-if="setupDone && !data.userId" type="success" icon="el-icon-check" size="mini" @click="setup()">Client Setup</el-button>
    </tabs>

    <panel v-if="data">
      <keep-alive>
        <template v-if="activeTab === 'Registration'">
          <client-info v-if="activeSubTab === 0" />
          <setup-checklist  v-if="activeSubTab === 1" :id="id" :registration="data" />
        </template>
        <template v-else-if="activeTab === 'General'">
          <client-general-info />
        </template>
        <template v-else-if="activeTab === 'Client Info'">
          <client-info-company v-if="activeSubTab === 0" />
          <client-info-contacts v-if="activeSubTab === 1" />
        </template>
        <template v-else-if="activeTab === 'Billing'">
          <billing-info v-if="activeSubTab === 0" />
          <billing-my-account v-if="activeSubTab === 1" />
          <billing-invoices v-if="activeSubTab === 2" />
          <billing-statements v-if="activeSubTab === 3" />
          <billing-subscriptions v-if="activeSubTab === 4" />
        </template>
        <client-documents v-else-if="activeTab === 'Documents'" />
        <template v-else-if="activeTab === 'Access'">
          <client-users v-if="activeSubTab === 0" />
          <client-user-log v-if="activeSubTab === 1" />
        </template>
        <template v-else-if="activeTab === 'Projects'">
          <project-scopes v-if="activeSubTab === 0" />
          <project-builds v-if="activeSubTab === 1" />
        </template>
        <template v-else-if="activeTab === 'Notes'">
          <notes v-if="activeSubTab === 0" />
        </template>
      </keep-alive>
    </panel>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import helperMixin from '../common/helpers-mixin'

import Tabs from '../common/Tabs'
import Panel from '../common/Panel'
import Layout from '../common/Layout'
import ClientInfo from './ClientInfo'
import ClientUsers from './ClientUsers'
import ClientUserLog from './ClientUserLog'
import ClientDocuments from './ClientDocuments'
import BillingInfo from './BillingInfo'
import BillingMyAccount from './BillingMyAccount'
import ClientInfoCompany from './ClientInfoCompany'
import ProjectBuilds from './projects/ProjectBuilds'
import ProjectScopes from './projects/ProjectScopes'
import ClientInfoContacts from './ClientInfoContacts'
import BillingSubscriptions from './billing/BillingSubscriptions'
import BillingStatements from "./billing/BillingStatements";
import BillingInvoices from "./billing/BillingInvoices";
import SetupChecklist from './setup-checklist/SetupChecklist'
import Notes from './Notes'

export default {
  name: 'ViewRegistration',

  mixins: [helperMixin],

  components: {
    Tabs,
    Notes,
    Panel,
    Layout,
    ClientInfo,
    ClientUsers,
    ClientUserLog,
    ClientDocuments,
    BillingInfo,
    BillingMyAccount,
    ProjectBuilds,
    ProjectScopes,
    SetupChecklist,
    ClientInfoCompany,
    ClientInfoContacts,
    BillingSubscriptions,
    BillingStatements,
    BillingInvoices,
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      activeTab: 'Registration',
      activeSubTab: 0,
    }
  },

  computed: {
    tabs () {
      if (!this.isClient) return [['Registration']]
      return [
        ['Registration', 'General', 'Client Info', 'Billing', 'Documents', 'Notes', 'Emails'],
        ['Access', 'Projects', 'APIX', 'Account Settings']
      ]
    },
    
    active () {
      return this.isClient ? '/clients/profiles' : '/clients/registrations'
    },

    isClient () {
      return this.data ? !!this.data.userId : false
    },
  
    headerData () {
      if (!this.data) return null
      return [
        {
          text: this.data.userId ? 'Client ID' : 'Account ID',
          value: this.data.userId ? this.data.userId.uuid : this.data.id
        },
        {
          text: 'Client Company Name',
          value: this.data.company
        },
        {
          text: 'Client Company Status',
          value: this.data.status
        }
      ]
    },

    nda () {
      return this.data ? this.data.NDA : null
    },

    clientAgreement () {
      return this.data ? this.data.ClientAgreement : null
    },

    clientAssign () {
      return this.data ? this.data.ClientAssign : null
    },

    worksheet () {
      return this.data ? this.data.Worksheet : null
    },

    accountManager () {
      return this.clientAssign ? this.clientAssign.accountManager : {}
    },

    projectManager () {
      return this.clientAssign ? this.clientAssign.clientServicesRep : {}
    },

    PSSP () {
      return this.data ? this.data.ProjectScopeSchedulePricing : null
    },

    setupDone () {
      const worksheet = this.worksheet && this.worksheet.completedBy
      const nda = this.nda && this.nda.signedAt && this.nda.signedFile
      const PSSP = this.PSSP && this.PSSP.completedBy && this.PSSP.signedFile
      const clientAgreement = this.clientAgreement && this.clientAgreement.signedAt && this.clientAgreement.signedFile
      return nda && clientAgreement && worksheet && PSSP && this.clientAssign
    },

    subTabs () {
      return {
        Registration: ['Client Info', 'Setup Checklist'],
        'Client Info': ['Company', 'Contacts'],
        Billing: ['Billing Info', 'My Account', 'Invoices', 'Statements', 'Subscriptions'],
        Access: ['Users', 'User Log'],
        Projects: ['Scopes', 'Builds'],
        Notes: ['Notes']
      }
    },

    data () {
      return this.$store.state.client.single.registration
    },

    user () {
      return window.appData.currentUser
    }
  },

  watch: {
    activeTab () {
      this.activeSubTab = 0
    }
  },

  async created () {
    await this.get({ id: this.id })
    const checklists = ['ClientAssign', 'NDA', 'Worksheet', 'ProjectScopeSchedulePricing', 'ClientAgreement']
    checklists.forEach(async type => {
      await this.loadChecklist({ type, id: this.id })
    })
    await this.loadDocuments({ id: this.id })
    await this.loadUsers()

    const clientSetup = this.getUrl('clientSetup')
    if (clientSetup && !this.data.userId) this.setup()

    const tab = this.getUrl('tab')
    const subitem = this.getUrl('subitem')
    if (tab) {
      this.activeTab = tab
      if (subitem) this.$nextTick(() => this.activeSubTab = parseInt(subitem))
    }
  },

  methods: {
    ...mapActions({
      loadUsers: 'users/get',
      get: 'client/single/get',
      update: 'client/single/update',
      loadDocuments: 'client/single/loadDocuments',
      loadChecklist: 'client/single/loadChecklist'
    }),

    async setup () {
      try {
        await this.$confirm(`Are you sure you want to do Client Setup?`, 'Warning', {
          confirmButtonText: 'Setup Client',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        try {
          await this.$confirm(null, `Send Welcome Email?`, {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'success'
          })
          this.createUser(true)
        } catch {
          this.createUser(false)
        }
      } catch {}
        
    },

    async createUser (sendEmail) {
      try {
        const loading = this.$loading()
        const payload = {
          firstName: this.data.firstName,
          lastName: this.data.lastName,
          email: this.data.email,
          phone: this.data.phone,
          phoneExt: this.data.phoneExt,
          role: 'client',
          uuid: 'GET_USER_UUID',
          activationCode: 'USER_ACTIVATION_CODE',
          activationCodeExpires: 'USER_ACTIVATION_CODE_EXPIRES'
        }
        let user = (await api.create('User', payload, true)).item
        await this.update({ id: this.data.id, payload: { status: 'NEW', userId: user.id } })
        user = (await api.update('User', user.id, { uuid: user.uuid, accountId: 'GET_USER_ACC_ID', created: 'CURRENT_TIMESTAMP' }, true)).item
        await this.createScope(user.id)
        if (sendEmail) await api.action('User', user.id, 'ClientWelcomeEmail')
        await this.get({ id: this.id, force: true })
        loading.close()
        this.$alert(`Successfully finished Client Setup.<br><br>Generated Account ID: <b>${user.accountId}</b><br>Generated Activation Code is: <b>${user.activationCode}</b>`, 'Client Setup', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true
        })
      } catch {}
    },

    async createScope (id) {
      const form = JSON.parse(this.PSSP.data)
      const keys = ['build', 'build2', 'build3', 'name', 'start_date', 'end_date', 'scope_of_work', 'customizations', 'client_to_provide', 'additional_notes']
      const data = { ...form }
      keys.forEach(key => delete data[key])

      const payload = {
        scopeId: this.PSSP.id,
        client: this.data.id,
        createdAt: this.PSSP.createdAt,
        createdBy: this.PSSP.createdBy.id,
        signedAt: this.PSSP.signedAt,
        signedFile: this.PSSP.signedFile.id,
        accountManager: this.accountManager.id,
        projectManager: this.projectManager.id,

        start_date: moment(form.start_date).format('YYYY-MM-DD'),
        end_date: moment(form.end_date).format('YYYY-MM-DD'),
        name: form.name,
        build: form.build,
        build2: form.build2,
        build3: form.build3,
        scope_of_work: form.scope_of_work,
        customizations: form.customizations,
        client_to_provide: form.client_to_provide,
        additional_notes: form.additional_notes,
        schedule_pricing: JSON.stringify(data),
      }

      await this.$store.dispatch('projects/scopes/create', payload)
    }
  }
}
</script>
