var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "Project Build and Schedule A Pricing", width: 1200 },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "tabs" },
        [
          _c(
            "el-button",
            {
              staticClass: "tab",
              attrs: { type: _vm.activeTab ? "default" : "primary" },
              on: {
                click: function($event) {
                  _vm.activeTab = 0
                }
              }
            },
            [_vm._v("\n      Project Scope\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "tab",
              attrs: { type: !_vm.activeTab ? "default" : "primary" },
              on: {
                click: function($event) {
                  _vm.activeTab = 1
                }
              }
            },
            [_vm._v("\n      Schedule A Pricing\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("project-build-form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeTab === 0,
            expression: "activeTab === 0"
          }
        ],
        attrs: { validator: _vm.$v, edit: _vm.edit },
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      }),
      _vm._v(" "),
      _c("schedule-pricing-form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeTab === 1,
            expression: "activeTab === 1"
          }
        ],
        attrs: { validator: _vm.$v },
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      }),
      _vm._v(" "),
      _c("project-tracker", {
        ref: "tracker",
        attrs: { trackers: _vm.edit ? _vm.edit.trackers : [] }
      }),
      _vm._v(" "),
      _vm.edit
        ? _c(
            "div",
            { staticClass: "text-center mt-20" },
            [
              _vm._v("\n    Status: "),
              _c("ex-select", {
                staticClass: "m-0",
                attrs: {
                  validator: _vm.$v.form.status,
                  options: _vm.projectStatuses,
                  "no-style": ""
                },
                model: {
                  value: _vm.form.status,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-check", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v("Submit")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Cancel")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }