var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: _vm.active } },
    [
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            !_vm.profiles
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Registration Date")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cof-multi flex" },
                    [
                      _c("date-time-picker", {
                        attrs: {
                          "value-type": "String",
                          "value-format": "yyyy-LL-dd",
                          format: "yyyy-LL-dd",
                          "time-picker": false,
                          "auto-close": true,
                          "min-date": "2020-01-01",
                          "max-date": _vm.today
                        },
                        model: {
                          value: _vm.form.fromDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fromDate", $$v)
                          },
                          expression: "form.fromDate"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "calendar-text" }, [
                        _vm._v("To")
                      ]),
                      _vm._v(" "),
                      _c("date-time-picker", {
                        attrs: {
                          "value-type": "String",
                          "value-format": "yyyy-LL-dd",
                          format: "yyyy-LL-dd",
                          "time-picker": false,
                          "auto-close": true,
                          "min-date": "2020-01-01",
                          "max-date": _vm.today
                        },
                        model: {
                          value: _vm.form.toDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "toDate", $$v)
                          },
                          expression: "form.toDate"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Setup Date")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cof-multi flex" },
                    [
                      _c("date-time-picker", {
                        attrs: {
                          "value-type": "String",
                          "value-format": "yyyy-LL-dd",
                          format: "yyyy-LL-dd",
                          "time-picker": false,
                          "auto-close": true,
                          "min-date": "2020-01-01",
                          "max-date": _vm.today
                        },
                        model: {
                          value: _vm.form.fromDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fromDate", $$v)
                          },
                          expression: "form.fromDate"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "calendar-text" }, [
                        _vm._v("To")
                      ]),
                      _vm._v(" "),
                      _c("date-time-picker", {
                        attrs: {
                          "value-type": "String",
                          "value-format": "yyyy-LL-dd",
                          format: "yyyy-LL-dd",
                          "time-picker": false,
                          "auto-close": true,
                          "min-date": "2020-01-01",
                          "max-date": _vm.today
                        },
                        model: {
                          value: _vm.form.toDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "toDate", $$v)
                          },
                          expression: "form.toDate"
                        }
                      })
                    ],
                    1
                  )
                ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              !_vm.profiles
                ? _c("ex-input", {
                    attrs: { label: "Registration ID" },
                    model: {
                      value: _vm.form.registrationId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "registrationId", $$v)
                      },
                      expression: "form.registrationId"
                    }
                  })
                : _c("ex-input", {
                    attrs: { label: "Account ID" },
                    model: {
                      value: _vm.form.registrationId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "registrationId", $$v)
                      },
                      expression: "form.registrationId"
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: { label: "Company Name" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                },
                [_vm._v("Search")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.resetItem()
                    }
                  }
                },
                [_vm._v("Reset Filters")]
              )
            ],
            1
          )
        ])
      ],
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              staticStyle: { "min-width": "100%" },
              attrs: { data: _vm.data, stripe: "" }
            },
            [
              !_vm.profiles
                ? _c("el-table-column", {
                    attrs: { label: "Actions", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-button", {
                                attrs: {
                                  disabled: !_vm.setupDone(scope.row),
                                  size: "mini",
                                  type: _vm.setupDone(scope.row)
                                    ? "success"
                                    : "info",
                                  icon: "el-icon-check",
                                  circle: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.redirect(scope.row)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  icon: "el-icon-close",
                                  circle: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.askDelete(scope.row.id)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2406584557
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          { attrs: { effect: "dark", size: "mini" } },
                          [_vm._v(_vm._s(scope.row.status))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              !_vm.profiles
                ? [
                    _c("el-table-column", {
                      attrs: { label: "Registration Date" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(scope.row.created, "LL")
                                  )
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        258085372
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "Registration ID" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/clients/registrations/" + scope.row.id
                                    }
                                  },
                                  [_c("b", [_vm._v(_vm._s(scope.row.id))])]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3319122766
                      )
                    })
                  ]
                : [
                    _c("el-table-column", {
                      attrs: { label: "Setup Date" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("date")(
                                      scope.row.userId.created,
                                      "LL"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "Client ID" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/clients/registrations/" + scope.row.id
                                  }
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.userId.uuid) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "Account ID" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/clients/registrations/" + scope.row.id
                                  }
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.userId.accountId) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Company Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.company) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Company Type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getCompanyType(scope.row.companyType)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Contact First Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.firstName) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Contact Last Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.lastName) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }