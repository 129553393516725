<template>
  <div class="content">
    <table class="form-table" v-if="false">
      <tr class="heading">
        <td colspan="3">{{ form.name || 'N/A' }}</td>
      </tr>
      <tr class="heading">
        <td>DESCRIPTION</td>
        <td>COST</td>
        <td>NOTE</td>
      </tr>
      <tr v-for="(text, key) in projectPricings" :key="key">
        <td>{{ text }}</td>
        <td class="p-0">
          <ex-input v-model="form[key]" label="Price" :validator="validator.form[key]" full no-style @blur="formatCurrency(key)" />
        </td>
        <td class="p-0">
          <ex-input v-model="form[key + '_note']" :validator="validator.form[key + '_note']" full no-style />
        </td>
      </tr>
    </table>

    <table class="form-table mt-50">
      <tr class="heading">
<!--         <td colspan="3">PLUGG CONNEX - EXCHANGE</td> -->
        <td colspan="3">{{ form.name || 'N/A' }}</td>
      </tr>
      <tr class="heading">
        <td>DESCRIPTION</td>
        <td>COST</td>
        <td>NOTE</td>
      </tr>
      <template v-for="(item, index) in pluggPricings">
        <tbody :key="index">
          <tr v-if="typeof item === 'string'" class="heading">
            <td colspan="3">{{ item }}</td>
          </tr>
          <template v-else>
            <tr v-for="(text, key) in item" :key="key">
              <td>{{ text }}</td>
              <td class="p-0" v-if="text!=='-'">
                <ex-input v-model="form[key]" label="Price" :validator="validator.form[key]" full no-style @blur="formatCurrency(key)" />
              </td>
              <td class="p-0" v-else>
                -
              </td>
              <td class="p-0" v-if="text!=='-'">
                <ex-input v-model="form[key + '_note']" :validator="validator.form[key + '_note']" full no-style />
              </td>
              <td class="p-0" v-else>
                -
              </td>
            </tr>
          </template>
        </tbody>
      </template>
      <tr class="heading">
        <td colspan="3">Notes</td>
      </tr>
      <tr>
        <td colspan="3"><textarea v-model="form.notes"></textarea></td>
      </tr>
      <tr>
        <td colspan="3"><b>Contact us for additional services and pricing</b></td>
      </tr>
    </table>
  </div>
</template>

<script>
import numeral from 'numeral'
import { phone } from '../../common/validators'

import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import WorksheetContacts from './WorksheetContacts'


export default {
  components: {
    ExInput,
    ExSelect,
    WorksheetContacts
  },

  props: {
    value: {
      type: Object,
      default: null
    },

    validator: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      pluggPricings: [
        {
          plugg_setup: 'Setup & Implementation',
          monthly_fee: 'License Fee',
          monthly_support: 'Monthly Support',
          monthly_updates: 'Monthly Updates',
        },
        'PLUGG CENTRAL',
        {
          central_setup: 'Setup & Implementation',
          central_monthly_fee: 'Monthly Fee',
        },
        'PLUGG 360',
        {
          t360_plugg_setup: 'Consulting',
          t360_monthly_fee: 'Monthly Fee',
        },
        'PLUGG APIX',
        {
          apix_plugg_setup: '-',
        },
        'OTHER',
        {
          development: 'Development',
          enhanced_support: 'Enhanced Support',
          integrations: 'Integrations',
          certifications: 'Certifications'
        },
      ],

      projectPricings: {
        setup_implementation: 'Setup & Implementation',
        monthly_license_fee: 'Monthly License Fee',
        monthly_support: 'Monthly Support',
        monthly_updates: 'Monthly Updates',
      }
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    formatCurrency (key) {
      this.form[key] = numeral(this.form[key]).format('$0.0000')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-table {
  width: 100%;
  .heading {
    background-color: #a6a6a6;
    color: #000;
    text-align: center;;
  }
  tr td {
    padding: 5px 10px;
    border: 1px solid #000;
    .inline {
      display: inline-block;
      vertical-align: middle;
    }
    &.p-0 {
      padding: 0;
    }
    textarea {
      width: 100%;
      padding: 5px;
      resize: vertical;
    }
  }
}

.mt-50 {
  margin-top: 50px;
}
</style>
