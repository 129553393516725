var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "table-wrapper" },
      [
        _c(
          "el-table",
          {
            staticStyle: { "min-width": "100%" },
            attrs: { data: _vm.statements, stripe: "" }
          },
          [
            _c("el-table-column", {
              attrs: { label: "Statement #" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(scope.row.id) + "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Filename" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return scope.row.file
                        ? [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/download/ClientDocument/" +
                                    scope.row.file.id,
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.file.file) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        : undefined
                    }
                  }
                ],
                null,
                true
              )
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Date Start" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("date")(scope.row.startDate, "LL")) +
                          "\n        "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Date End" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("date")(scope.row.endDate, "LL")) +
                          "\n        "
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }