<template>
  <div>
    <div class="buttons">
      <el-button size="mini" icon="el-icon-plus" type="primary" @click="openForm = true">New</el-button>
    </div>

    <div>
      <div class="cof-multi flex">
        <date-time-picker
          v-model="filters.from"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" >
        </date-time-picker>
        <span class="calendar-text">To</span>
        <date-time-picker
          v-model="filters.to"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" >
        </date-time-picker>
        <el-button size="mini" type="primary" @click="search()">Search</el-button>
      </div>
    </div>

    <div class="table-wrapper">
      <el-table :data="invoices" style="min-width: 100%" stripe>
        <el-table-column label="Invoice #">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>

        <el-table-column label="Filename">
          <template v-if="scope.row.file" slot-scope="scope">
            <a :href="`/download/ClientDocument/${scope.row.file.id}`" target="_blank">
              {{ scope.row.file.file }}
            </a>
          </template>
        </el-table-column>

        <el-table-column label="Date Start">
          <template slot-scope="scope">
            {{ scope.row.startDate | date('LL') }}
          </template>
        </el-table-column>

        <el-table-column label="Date End">
          <template slot-scope="scope">
            {{ scope.row.endDate | date('LL') }}
          </template>
        </el-table-column>

        <el-table-column label="Actions" align="right">
          <template slot-scope="scope">
            <el-button size="mini" icon="el-icon-edit" type="primary" @click="openPdf(scope.row.file.id)">View
            </el-button>
            <el-button v-if="isUserAdmin" size="mini" icon="el-icon-delete" type="danger" @click="remove(scope.row.id)">
              Remove
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <billing-invoice-form
      v-if="openForm"
      @close="openForm = false"
      @saved="openForm = false; loadInvoices()"/>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from "vuex"

import helperMixin from "../../common/helpers-mixin"
import BillingInvoiceForm from "./BillingInvoiceForm"
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  name: "BillingInvoices",

  mixins: [helperMixin],

  components: {
    DateTimePicker,
    BillingInvoiceForm,
  },

  data() {
    return {
      filters: {
        from: null,
        to: null
      },
      appliedFilters: {
        from: null,
        to: null
      },
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      openForm: false
    }
  },

  computed: {
    ...mapState({
      client: state => state.client.single.registration
    }),

    invoices () {
      let invoices = this.$store.state.client.invoices.data || []

      if (this.appliedFilters.from) {
        invoices = invoices.filter(invoice => moment(moment(invoice.createdAt).format('YYYY-MM-DD')) >= moment(this.appliedFilters.from))
      }

      if (this.appliedFilters.to) {
        invoices = invoices.filter(invoice => moment(moment(invoice.createdAt).format('YYYY-MM-DD')) <= moment(this.appliedFilters.to))
      }

      return invoices
    }
  },

  created() {
    this.filters.from = moment().add(-7, 'd').format("YYYY-MM-DD")
    this.filters.to = moment().format("YYYY-MM-DD")
    this.search()
    this.loadInvoices()
  },

  methods: {
    search () {
      Object.keys(this.filters).forEach(key => this.appliedFilters[key] = this.filters[key])
    },

    openPdf(documentId) {
      window.open(`/download/ClientDocument/${documentId}`, '_blank')
    },

    loadInvoices() {
      this.$store.dispatch('client/invoices/load', {params: {client: this.client.id}})
    },

    async remove(id) {
      const loading = this.$loading()
      try {
        await this.$confirm('Are you sure you want to delete this invoice?')
        try {
          await this.$store.dispatch('client/invoices/delete', id)
          this.$message.success('Invoice deleted.')
        } catch (e) {
          console.log(e.message)
          this.$message.error('Something went wrong')
        }
      } catch {}

      loading.close()
    },
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px
  }
</style>