<template>
  <footer>
    <p class="copy">Copyright &copy; {{ year }} Plugg & Co. All rights reserved.</p>
    <p class="privacy-policy">
      <a href="#">Privacy Policy</a>
    </p>
    <div class="black-logo"><img src="/assets/images/black-logo.png"></div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      year: (new Date()).getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  min-height: 70px;
  width: 100%;
  background-color: #e3e3e3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .copy {
    font-size: 11px;
    font-weight: 300;
    color: #686868;
    margin: 0;
    margin-right: 50px;
  }
  .privacy-policy {
    margin: 0;
    margin-right: 50px;
    font-size: 11px;
  }
  .black-logo {
    margin-right: 20px;
  }
}
</style>