var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "New Invoice", width: 800 },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "layout" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ex-input", {
              attrs: {
                label: "Company Name",
                validator: _vm.$v.form.name,
                full: "",
                noMargin: ""
              },
              model: {
                value: _vm.form.name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ex-input", {
              attrs: {
                label: "Address",
                validator: _vm.$v.form.address,
                full: "",
                noMargin: ""
              },
              model: {
                value: _vm.form.address,
                callback: function($$v) {
                  _vm.$set(_vm.form, "address", $$v)
                },
                expression: "form.address"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ex-select", {
              attrs: {
                label: "Country",
                validator: _vm.$v.form.country,
                options: _vm.countryOptions,
                full: "",
                noMargin: ""
              },
              model: {
                value: _vm.form.country,
                callback: function($$v) {
                  _vm.$set(_vm.form, "country", $$v)
                },
                expression: "form.country"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ex-select", {
              attrs: {
                label: "State/Province",
                disabled: !_vm.stateOptions,
                validator: _vm.$v.form.state,
                options: _vm.stateOptions,
                full: "",
                noMargin: ""
              },
              model: {
                value: _vm.state,
                callback: function($$v) {
                  _vm.state = $$v
                },
                expression: "state"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ex-input", {
              attrs: {
                label: "City",
                validator: _vm.$v.form.city,
                full: "",
                noMargin: ""
              },
              model: {
                value: _vm.form.city,
                callback: function($$v) {
                  _vm.$set(_vm.form, "city", $$v)
                },
                expression: "form.city"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ex-input", {
              attrs: {
                label: "Postal code",
                validator: _vm.$v.form.postal,
                full: "",
                noMargin: ""
              },
              model: {
                value: _vm.form.postal,
                callback: function($$v) {
                  _vm.$set(_vm.form, "postal", $$v)
                },
                expression: "form.postal"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("ex-input", {
              attrs: { label: "Invoice ID", full: "", noMargin: "" },
              model: {
                value: _vm.form.invoiceId,
                callback: function($$v) {
                  _vm.$set(_vm.form, "invoiceId", $$v)
                },
                expression: "form.invoiceId"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("ex-input", {
              attrs: { label: "Client ID", full: "", noMargin: "" },
              model: {
                value: _vm.form.clientId,
                callback: function($$v) {
                  _vm.$set(_vm.form, "clientId", $$v)
                },
                expression: "form.clientId"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("ex-input", {
              attrs: { label: "Account Number", full: "", noMargin: "" },
              model: {
                value: _vm.form.accountNumber,
                callback: function($$v) {
                  _vm.$set(_vm.form, "accountNumber", $$v)
                },
                expression: "form.accountNumber"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "px-20" }, [
        _c(
          "table",
          { staticClass: "form-table mt-20" },
          [
            _c("tr", [
              _c("td", { attrs: { width: "150" } }, [_vm._v("Date")]),
              _vm._v(" "),
              _c("td", [_vm._v("Description")]),
              _vm._v(" "),
              _c("td", { attrs: { width: "150" } }, [_vm._v("Amount")]),
              _vm._v(" "),
              _c("td", { attrs: { width: "30" } })
            ]),
            _vm._v(" "),
            _vm._l(_vm.form.items, function(item, index) {
              return _c("tr", { key: index }, [
                _c(
                  "td",
                  [
                    _c("datepicker", {
                      attrs: {
                        "calendar-button": "",
                        "calendar-button-icon": "el-icon-date",
                        format: "d MMM yyyy",
                        placeholder: "Estimated Date of Completion",
                        "no-style": ""
                      },
                      model: {
                        value: _vm.form.items[index].date,
                        callback: function($$v) {
                          _vm.$set(_vm.form.items[index], "date", $$v)
                        },
                        expression: "form.items[index].date"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("ex-input", {
                      attrs: {
                        validator: _vm.$v.form.items.$each[index].description,
                        label: "Description",
                        "no-style": "",
                        full: "",
                        noMargin: ""
                      },
                      model: {
                        value: _vm.form.items[index].description,
                        callback: function($$v) {
                          _vm.$set(_vm.form.items[index], "description", $$v)
                        },
                        expression: "form.items[index].description"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("ex-input", {
                      attrs: {
                        validator: _vm.$v.form.items.$each[index].amount,
                        label: "Amount",
                        "no-style": "",
                        full: "",
                        noMargin: "",
                        "no-margin": ""
                      },
                      on: {
                        blur: function($event) {
                          return _vm.formatCurrency(index)
                        }
                      },
                      model: {
                        value: _vm.form.items[index].amount,
                        callback: function($$v) {
                          _vm.$set(_vm.form.items[index], "amount", _vm._n($$v))
                        },
                        expression: "form.items[index].amount"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("el-button", {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-delete",
                        type: "danger"
                      },
                      on: {
                        click: function($event) {
                          return _vm.remove(index)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center mt-10" },
        [
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-plus" },
            on: {
              click: function($event) {
                return _vm.addNewItem()
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons mt-20" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v("\n      Submit\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }