export default {
  computed: {
    isUserAdmin () {
      return ['super-admin', 'admin'].includes(this.user.role)
    },

    isUserClient () {
      return ['client'].includes(this.user.role)
    },

    user () {
      return window.appData.currentUser
    }
  },

  methods: {
    changeFileName (file, name) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      const blob = file.slice(0, file.size, file.type);
      const fileName = name + '.' + ext
      return new File([blob], fileName, { type: file.type })
    },

    getUrl (name) {
      let url = location.href
      name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]")
      var regexS = "[\\?&]"+name+"=([^&#]*)"
      var regex = new RegExp( regexS )
      var results = regex.exec( url )
      return results == null ? null : results[1]
    },

    isImage (file) {
      var parts = file.name.split('.');
      const ext = parts[parts.length - 1];
      const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(ext)
      if (!isImage)
        this.$message.error('Selected file type is not accepted. Accpted file types are: JPG, JPEG, PNG, GIF')
      return isImage
    },

    getMax (arr) {
      return arr.reduce((a, b) => Math.max(parseInt(a), b))
    }
  }
}
