<template>
  <layout :active="active">
    <template class="">
      <div class="row">
        <div class="col">
          <div v-if="!profiles" class="form-group">
            <label>Registration Date</label>
            <div class="cof-multi flex">
              <date-time-picker
                v-model="form.fromDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                min-date="2020-01-01"
                :max-date="today" >
              </date-time-picker>
              <span class="calendar-text">To</span>
              <date-time-picker
                v-model="form.toDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                min-date="2020-01-01"
                :max-date="today" >
              </date-time-picker>
            </div>
          </div>
          <div v-else class="form-group">
            <label>Setup Date</label>
            <div class="cof-multi flex">
              <date-time-picker
                v-model="form.fromDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                min-date="2020-01-01"
                :max-date="today" >
              </date-time-picker>
              <span class="calendar-text">To</span>
              <date-time-picker
                v-model="form.toDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                min-date="2020-01-01"
                :max-date="today" >
              </date-time-picker>
            </div>
          </div>
        </div>
        <div class="col">
          <ex-input v-if="!profiles" v-model="form.registrationId" label="Registration ID"/>
          <ex-input v-else v-model="form.registrationId" label="Account ID"/>
        </div>
        <div class="col">
          <ex-input v-model="form.name" label="Company Name"/>
        </div>
      </div>
      <div class="row"></div>
      <div class="row">
        <div class="col">
          <el-button type="primary" @click="search()">Search</el-button>
        </div>
        <div class="col">
          <el-button type="primary" @click="resetItem()">Reset Filters</el-button>
        </div>
      </div>
    </template>
    <div class="table-wrapper">
      <el-table :data="data" style="min-width: 100%" stripe>
        <el-table-column v-if="!profiles" label="Actions" width="100">
          <template slot-scope="scope">
            <el-button :disabled="!setupDone(scope.row)" size="mini" :type="setupDone(scope.row) ? 'success' : 'info'" icon="el-icon-check" circle @click="redirect(scope.row)" />
            <el-button size="mini" type="danger" icon="el-icon-close" circle @click="askDelete(scope.row.id)" />
          </template>
        </el-table-column>

        <el-table-column label="Status">
          <template slot-scope="scope">
            <el-tag effect="dark" size="mini">{{ scope.row.status }}</el-tag>
          </template>
        </el-table-column>

        <template v-if="!profiles">
          <el-table-column label="Registration Date">
            <template slot-scope="scope">{{ scope.row.created | date('LL') }}</template>
          </el-table-column>

          <el-table-column label="Registration ID">
            <template slot-scope="scope">
              <a :href="`/clients/registrations/${scope.row.id}`">
                <b>{{ scope.row.id }}</b>
              </a>
            </template>
          </el-table-column>
        </template>

        <template v-else>
          <el-table-column label="Setup Date">
            <template slot-scope="scope">
              {{ scope.row.userId.created | date('LL') }}
            </template>
          </el-table-column>

          <el-table-column label="Client ID">
            <template slot-scope="scope">
              <a :href="`/clients/registrations/${scope.row.id}`">
                <b>
                {{ scope.row.userId.uuid }}
                </b>
              </a>
            </template>
          </el-table-column>

          <el-table-column label="Account ID">
            <template slot-scope="scope">
              <a :href="`/clients/registrations/${scope.row.id}`">
                <b>
                {{ scope.row.userId.accountId }}
                </b>
              </a>
            </template>
          </el-table-column>
        </template>

        <el-table-column label="Company Name">
          <template slot-scope="scope">
            {{ scope.row.company }}
          </template>
        </el-table-column>

        <el-table-column label="Company Type">
          <template slot-scope="scope">
            {{ getCompanyType(scope.row.companyType) }}
          </template>
        </el-table-column>

        <el-table-column label="Contact First Name">
          <template slot-scope="scope">
            {{ scope.row.firstName }}
          </template>
        </el-table-column>

        <el-table-column label="Contact Last Name">
          <template slot-scope="scope">
            {{ scope.row.lastName }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import Panel from '../common/Panel'
import Layout from '../common/Layout'
import ExInput from '../common/Input'
import DateTimePicker from 'vue-vanilla-datetime-picker';

export default {
  name: 'client-registrations',

  props: {
    profiles: {
      type: String,
      default: null
    }
  },

  components: {
    Panel,
    Layout,
    DateTimePicker,
    ExInput
  },

  data: () => ({
    form: {
      fromDate: null,
      toDate: null,
      registrationId: null,
      name: null,
    },

    appliedFilters: null,

    today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
  }),

  computed: {
    active () {
      return this.profiles ? '/clients/profiles' : '/clients/registrations'
    },

    data () {
      let registrations = this.$store.state.client.registration.data || []
      registrations = !this.profiles ? registrations.filter(user => user.status === 'REG') : registrations.filter(user => user.userId && user.status !== 'REG')
      if (this.appliedFilters) {
        if (this.appliedFilters.name) {
          registrations = registrations.filter(reg => {
            const company = reg?.company
            if (!company) return false;
            return company.toLowerCase().indexOf(this.appliedFilters.name.toLowerCase()) == 0
          })
        }

        if(!this.profiles){
          if (this.appliedFilters.fromDate) {
            const from = moment(this.appliedFilters.fromDate, 'YYYY-MM-DD')
            registrations = registrations.filter(reg => {
              if(reg.created) {
                const date = moment(reg.created.split(' ')[0], 'YYYY-MM-DD')
                return date >= from
              }
            })
          }

          if (this.appliedFilters.toDate) {
            const to = moment(this.appliedFilters.toDate, 'YYYY-MM-DD')
            registrations = registrations.filter(reg => {
              if(reg.created){
                const date = moment(reg.created.split(' ')[0], 'YYYY-MM-DD')
                return date <= to
              }
            })
          }

          if (this.appliedFilters.registrationId) {
            registrations = registrations.filter(reg => parseInt(reg.id) === parseInt(this.appliedFilters.registrationId))
          }
        }else{
          if (this.appliedFilters.fromDate) {
            const from = moment(this.appliedFilters.fromDate, 'YYYY-MM-DD')
            registrations = registrations.filter(reg => {
              if(reg.userId.created) {
                const date = moment(reg.userId.created.split(' ')[0], 'YYYY-MM-DD')
                return date >= from
              }
            })
          }

          if (this.appliedFilters.toDate) {
            const to = moment(this.appliedFilters.toDate, 'YYYY-MM-DD')
            registrations = registrations.filter(reg => {
              if(reg.userId.created){
                const date = moment(reg.userId.created.split(' ')[0], 'YYYY-MM-DD')
                return date <= to
              }
            })
          }

          if (this.appliedFilters.registrationId) {
            registrations = registrations.filter(reg => parseInt(reg.userId.accountId) === parseInt(this.appliedFilters.registrationId))
          }
        }
      }

      return registrations
    }
  },  

  async created () {
    await this.refreshRegistrations();
  },

  methods: {
    ...mapActions({
      getRegistrations: 'client/get',
      removeRegistration: 'client/remove'
    }),

    setupDone (data) {
      const worksheet = data.Worksheet.length ? data.Worksheet[0] : null 
      const nda = data.NDA.length ? data.NDA[0] : null 
      const assign = data.ClientAssign.length ? data.ClientAssign[0] : null

      let pssp = data.ProjectScopeSchedulePricing.length ? data.ProjectScopeSchedulePricing[0] : null
      pssp = !!(pssp && pssp.completedBy && pssp.signedFile)

      let clientAgreement = data.ClientAgreement.length ? data.ClientAgreement[0] : null
      clientAgreement = !!(clientAgreement && clientAgreement.signedAt && clientAgreement.signedFile)

      return worksheet && clientAgreement && pssp && worksheet.completedBy && nda && nda.signedAt && nda.signedFile && assign
    },

    getCompanyType (type) {
      return window.companyTypes[type]
    },

    async askDelete (id) {
      try {
        await this.$confirm(`Are you sure to delete Client Registration #${id}?`, 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'error'
        })

        await this.removeRegistration(id)
        this.$message.success('Registration deleted successfully.')
      } catch {}
    },

    redirect (data) {
      if (this.setupDone(data)) {
        location.href = `/clients/registrations/${data.id}?clientSetup=true`
      }
    },

    async refreshRegistrations () {
      let params = {}
      if (!this.appliedFilters) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 7)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")
        params.created=[">=", yesterdayDate+" 00:00:00"];
      } else {
        if (this.appliedFilters.fromDate)
          params.createdAtAfter=[">=", this.appliedFilters.fromDate+" 00:00:00", "created"];
        if (this.appliedFilters.toDate)
          params.createdAtBefore=["<=", this.appliedFilters.toDate+" 23:59:59", "created"];
      }

      await this.$store.dispatch('client/registration/load', { order: ['ID:DESC'], params })
    },

    search () {
      this.appliedFilters = { ...this.form }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },
  },

  watch: {
    appliedFilters () {
      this.refreshRegistrations();
    },
  },
}
</script>
<style lang="scss" scoped>
  .calendar-text {
    line-height: 30px;
  }
</style>