var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: "/system/information" } }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("panel", { attrs: { title: "System Information" } }, [
          _c(
            "form",
            { staticClass: "form" },
            [
              _vm._l(Object.keys(_vm.form), function(key) {
                return [
                  _c("ex-input", {
                    key: key,
                    staticClass: "item",
                    attrs: { label: "System " + _vm.labels[key] },
                    model: {
                      value: _vm.form[key],
                      callback: function($$v) {
                        _vm.$set(_vm.form, key, $$v)
                      },
                      expression: "form[key]"
                    }
                  })
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "images" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("label", [_vm._v("System Logo")]),
                _vm._v(" "),
                _c("image-upload", {
                  attrs: { base: _vm.base },
                  model: {
                    value: _vm.logo,
                    callback: function($$v) {
                      _vm.logo = $$v
                    },
                    expression: "logo"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("label", [_vm._v("System Mail Logo")]),
                _vm._v(" "),
                _c("image-upload", {
                  attrs: { base: _vm.base },
                  model: {
                    value: _vm.mailLogo,
                    callback: function($$v) {
                      _vm.mailLogo = $$v
                    },
                    expression: "mailLogo"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }