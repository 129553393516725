var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: "/system/builds" } }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("panel", { attrs: { title: "System Builds" } }, [
          _c(
            "div",
            { staticClass: "table-wrapper" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { "min-width": "100%" },
                  attrs: { data: _vm.data, stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "System Name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !scope.row.edit
                              ? _c("span", [_vm._v(_vm._s(scope.row.name))])
                              : _c("ex-input", {
                                  attrs: {
                                    validator:
                                      _vm.$v.data.$each.$iter[scope.$index]
                                        .name,
                                    "no-style": ""
                                  },
                                  model: {
                                    value: scope.row.name,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "name", $$v)
                                    },
                                    expression: "scope.row.name"
                                  }
                                })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Version" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !scope.row.edit
                              ? _c("span", [_vm._v(_vm._s(scope.row.version))])
                              : _c("ex-input", {
                                  attrs: {
                                    validator:
                                      _vm.$v.data.$each.$iter[scope.$index]
                                        .version,
                                    "no-style": ""
                                  },
                                  model: {
                                    value: scope.row.version,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "version", $$v)
                                    },
                                    expression: "scope.row.version"
                                  }
                                })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Framework" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !scope.row.edit
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.framework))
                                ])
                              : _c("ex-input", {
                                  attrs: {
                                    validator:
                                      _vm.$v.data.$each.$iter[scope.$index]
                                        .framework,
                                    "no-style": ""
                                  },
                                  model: {
                                    value: scope.row.framework,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "framework", $$v)
                                    },
                                    expression: "scope.row.framework"
                                  }
                                })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Category" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !scope.row.edit
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.categoryName(scope.row.category))
                                  )
                                ])
                              : _c("ex-select", {
                                  attrs: {
                                    options: _vm.categories,
                                    validator:
                                      _vm.$v.data.$each.$iter[scope.$index]
                                        .category,
                                    "no-style": ""
                                  },
                                  model: {
                                    value: scope.row.category,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "category", $$v)
                                    },
                                    expression: "scope.row.category"
                                  }
                                })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: { label: "Actions", width: "100", align: "right" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              !scope.row.id || scope.row.edit
                                ? [
                                    !scope.row.id
                                      ? _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                            icon: "el-icon-close",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.remove(scope)
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "success",
                                        icon: "el-icon-check",
                                        circle: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onSave(scope.$index)
                                        }
                                      }
                                    })
                                  ]
                                : [
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        icon: "el-icon-edit",
                                        circle: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openEdit(scope.$index)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        circle: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteBuild(scope)
                                        }
                                      }
                                    })
                                  ]
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("el-button", {
                            attrs: {
                              size: "small",
                              type: "primary",
                              icon: "el-icon-plus",
                              circle: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.addNew()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }