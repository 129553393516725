var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form add-task-form" },
    [
      _c("div", { staticClass: "inline-form-group" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Date:")]),
            _vm._v(" "),
            _c("datepicker", {
              class: {
                error: _vm.$v.form.date.$dirty && _vm.$v.form.date.$invalid
              },
              attrs: {
                "calendar-button": "",
                "calendar-button-icon": "el-icon-date",
                maxlength: "100",
                format: "yyyy MMM d",
                "open-date": new Date(),
                disabled: _vm.readonly,
                disabledDates: _vm.disabledDates
              },
              model: {
                value: _vm.form.date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "date", $$v)
                },
                expression: "form.date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Time:")]),
            _vm._v(" "),
            _c("ex-select", {
              attrs: {
                options: _vm.hourOptions,
                validator: _vm.$v.form.hours,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.hours,
                callback: function($$v) {
                  _vm.$set(_vm.form, "hours", $$v)
                },
                expression: "form.hours"
              }
            }),
            _vm._v(" "),
            _c("ex-select", {
              attrs: {
                options: _vm.minutesOptions,
                validator: _vm.$v.form.minutes,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.minutes,
                callback: function($$v) {
                  _vm.$set(_vm.form, "minutes", $$v)
                },
                expression: "form.minutes"
              }
            }),
            _vm._v(" "),
            _c("ex-select", {
              attrs: {
                options: _vm.amPmOptions,
                validator: _vm.$v.form.am_pm,
                disabled: _vm.readonly,
                "no-style": "",
                full: ""
              },
              model: {
                value: _vm.form.am_pm,
                callback: function($$v) {
                  _vm.$set(_vm.form, "am_pm", $$v)
                },
                expression: "form.am_pm"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.isScheduler
        ? _c("tag-employees", {
            ref: "tagEmployees",
            model: {
              value: _vm.selectedEmployees,
              callback: function($$v) {
                _vm.selectedEmployees = $$v
              },
              expression: "selectedEmployees"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group task-description" },
        [
          _c("label", [_vm._v("Task:")]),
          _vm._v(" "),
          _c("ex-textarea", {
            class: {
              error: _vm.$v.form.text.$dirty && _vm.$v.form.text.$invalid
            },
            attrs: {
              maxlength: "300",
              placeholder: "Enter task description",
              full: "",
              "no-margin": ""
            },
            model: {
              value: _vm.form.text,
              callback: function($$v) {
                _vm.$set(_vm.form, "text", $$v)
              },
              expression: "form.text"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }