<template>
  <form-item :label="label" :validator="validator" :class="{ full }" :no-style="noStyle" :no-margin="noMargin">
    <select v-model="val" :disabled="disabled" :class="classes">
      <option :value="null">Select {{ label || '' }}</option>
      <option v-for="option in options" :key="option.id" :value="option.id" :disabled="option.disabled">{{ option.text }}</option>
    </select>
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },

    full: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noMargin: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    },

    classes: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .full {
    width: 100%;
  }
  .apix {
    font-size: 14px;
    &.disabled {
      background: none;
      border: 0px;
      padding: 0;
    }
  }
</style>