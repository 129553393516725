<template> 
  <div class="page">
    <div class="wrapper">
      <logo />

      <panel title="Client Registration" padding="0" class="panel" :border="false">
        <div class="subtitle">Client Information</div>
        <div class="form">
          <div class="col">
            <ex-input v-model="form.firstName" label="First Name" :validator="$v.form.firstName" full />
          </div>
          <div class="col">
            <ex-input v-model="form.lastName" label="Last Name" :validator="$v.form.lastName" full />
          </div>
          <div class="col">
            <ex-input v-model="form.jobTitle" label="Job Title" :validator="$v.form.jobTitle" full />
          </div>
          <div class="col">
            <ex-input v-model="form.email" type="email" label="Email Address" :validator="$v.form.email" full />
          </div>
          <div class="col">
            <ex-input v-model="form.phone" label="Phone Number" :validator="$v.form.phone" full />
          </div>
        </div>
        
        <div class="subtitle">Client Company Information</div>
        <div class="form">
          <div class="col w100">
            <ex-input v-model="form.company" label="Client Company Name" :validator="$v.form.company" full />
          </div>
          <div class="col">
            <ex-select v-model="form.companyType" label="Client Company Type" :validator="$v.form.companyType" :options="companyTypeOptions" full />
          </div>
          <div class="col">
            <ex-select v-model="selectedCountry" label="Country" :validator="$v.form.country" :options="countryOptions" full />
          </div>
        </div>

        <div class="subtitle">Client Company Address Information</div>
        <div class="form">
          <div class="col">
            <ex-input v-model="form.address" label="Address" :validator="$v.form.address" full />
          </div>
          <div class="col">
            <ex-input v-model="form.city" label="City" :validator="$v.form.city" full />
          </div>
          <div class="col">
            <ex-select v-model="selectedState" label="State/Province" :disabled="!stateOptions" :validator="$v.form.state" :options="stateOptions" full />
          </div>
          <div class="col">
            <ex-input v-model="form.postal" label="ZIP/Postal code" :validator="$v.form.postal" full />
          </div>
          <div class="col">
            <ex-input v-model="form.companyPhone" label="Phone Number" :validator="$v.form.companyPhone" full />
          </div>
        </div>

        <div class="terms">
          <p>Do you agree to our:</p>
          <div class="check">
            <div class="ch-div">
              <input v-model="agree.terms" type="checkbox" id="terms" class="check-b">
              <label for="terms"> <a>Terms & Conditions</a></label>
            </div>
            <div class="ch-div">
              <input v-model="agree.privacy" type="checkbox" id="cbx1" class="check-b">
              <label for="cbx1"> <a>Privacy</a></label>
            </div>
            <div class="ch-div">
              <input v-model="agree.user_policy" type="checkbox" id="cbx2" class="check-b">
              <label for="cbx2"> <a>User Policy</a></label>
            </div>
            <button class="ch-btn" @click="beforeSave()">Submit Registration</button>
          </div>
        </div>
      </panel>

      <get-plugged-footer :is-login="false" />
    </div>
  </div>
</template>

<script>
import Logo from '../common/Logo'
import Panel from '../common/Panel'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import GetPluggedFooter from '../common/GetPluggedFooter'

import { phone, isTruthy } from '../common/validators'
import { required, numeric, email, alpha } from 'vuelidate/lib/validators'

export default {
  name: 'client-registration',

  components: {
    Logo,
    Panel,
    ExInput,
    ExSelect,
    GetPluggedFooter
  },

  data () {
    return  {
      form: {
        firstName: null,
        lastName: null,
        jobTitle: null,
        email: null,
        phone: null,
        company: null,
        companyType: null,
        country: null,
        address: null,
        city: null,
        state: null,
        postal: null,
        companyPhone: null
      },

      agree: {
        terms: false,
        privacy: false,
        user_policy: false
      },

      selectedState: null,
      selectedCountry: null,
    }
  },

  computed: {
    companyTypeOptions () {
      return Object.keys(window.companyTypes).map(key => {
        return {
          id: key,
          text: window.companyTypes[key]
        }
      })
    },

    countryOptions () {
      const options = window.countriesOptions.map(country => {
        return {
          id: country.id,
          text: `${country.id} - ${country.text}`
        }
      }) 
      options.splice(2, 0, {
        id: null,
        disabled: true,
        text: '---------------------------------'
      });
      return options
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    selectedState (value) {
      if (value) {
        this.form.state = this.getInitials(value)
      }
    },

    selectedCountry (value) {
      if (value) {
        this.form.country = this.getInitials(value)
        this.selectedState = null
        this.form.state = null
      } else {
        this.form.country = null
      }
    }
  },

  methods: {
    async beforeSave () {
      try {
        await api.request("POST", "/validate/registration", { email: this.form.email });
        this.save()
      } catch (e) {
        this.$message.error('Email already exists. Please try again or contact Support')
      }
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const payload = this.form;
          var response=await api.request('POST', '/client-registration', payload);
          if (response.Result=="Success") {
            this.$alert('Thank you for submitting your information!', 'Registration submitted!', {
              confirmButtonText: 'Back to Home',
              center: true,
              showClose	: false,
              callback: action => action === 'confirm' ? location.href = '/' : false
            })
          } else {
            var message=[];
            for(var i in response.Message)
              message.push(i+": "+response.Message[i].join(", "));
            message=message.join("\n");
            this.$alert('There was an error submitting your information!'+message, 'Registration failed!\n', {
              confirmButtonText: 'Close',
              center: true,
              showClose	: false,
              callback: action => {}
            });
          }
        } catch {
          this.$message.error('There was an error... Please try again.')
        }
      }
    },

    formatCountries (arr) {
      return arr.map(country => {
        const c = country.name || country
        return {
          id: this.getInitials(c),
          text: c
        }
      })
    },

    getInitials (value) {
      return value.split('').splice(0, 2).join('')
    }
  },

  validations () {
    const rules = {
      form: {
        firstName: { required },
        lastName: { required },
        jobTitle: { required },
        email: { required, email },
        phone: { required, phone },
        company: { required },
        companyType: { required },
        country: { required },
        address: { required },
        city: { required },
        postal: { required },
        companyPhone: { required }
      },

      agree: {
        terms: { required, isTruthy },
        privacy: { required, isTruthy },
        user_policy: { required, isTruthy }
      }
    }

    if (this.stateOptions) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: #1e004c;
  background-image: url("/assets/images/application-layer.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 75px 0;
  .wrapper {
    width: 100%;
    max-width: 685px;
    margin: 0 auto;
    .form {
      .col {
        padding-right: 40px;
        width: 50%;
        &.w100 {
          width: 100%;
        }
      }
    }
    .terms {
      min-height: 90px;
      background-color: #d6d6d6;
      display: flex;
      align-items: center;
      position: relative;
      p {
        font-size: 12px;
        font-weight: 500;
        color: #828282;
        position: absolute;
        top: 10px;
        left: 20px;
        font-family: 'Roboto', sans-serif;
      }
      .check {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .ch-div {
          display: flex;
          align-items: center;
          label {
            margin: 0;
            margin-left: 10px;
            a {
              font-size: 12px;
            }
          }
          .check-b {
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            padding: 0;
          }
        }
        .ch-btn {
          font-size: 19px;
          font-weight: 700;
          color: #fff;
          font-family: 'Roboto', sans-serif;
          background: rgb(69,162,11);
          background: linear-gradient(180deg, rgba(69,162,11,1) 85%, rgba(43,140,0,1) 100%);
          padding: 15px 30px;
          border-radius: 5px;
          border: none;
          border-bottom: 3px solid #fff;
          box-shadow: 0px 4px 6px #b6b6b6;
          cursor: pointer;
          &:disabled {
            cursor: not-allowed;
            opacity: .7;
          }
        }
      }
    }
    .panel {
      margin-bottom: 20px;
    }
    .subtitle {
      min-height: 40px;
      background-color: #000;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
