var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    {
      class: { full: _vm.full },
      attrs: {
        label: _vm.label,
        validator: _vm.validator,
        "no-style": _vm.noStyle,
        "no-margin": _vm.noMargin
      }
    },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.val,
              expression: "val"
            }
          ],
          class: _vm.classes,
          attrs: { disabled: _vm.disabled },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.val = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { domProps: { value: null } }, [
            _vm._v("Select " + _vm._s(_vm.label || ""))
          ]),
          _vm._v(" "),
          _vm._l(_vm.options, function(option) {
            return _c(
              "option",
              {
                key: option.id,
                attrs: { disabled: option.disabled },
                domProps: { value: option.id }
              },
              [_vm._v(_vm._s(option.text))]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }