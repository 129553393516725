export default {
  data: () => ({
    prices: ["monthly_support", "monthly_support_note", "monthly_updates", "monthly_updates_note", "enhanced_support", "enhanced_support_note", "central_setup", "central_setup_note", "central_monthly_fee", "t360_monthly_fee", "t360_plugg_setup", "central_monthly_fee_note", "t360_plugg_setup_note", "t360_monthly_fee_note", "development", "development_note", "monthly_fee", "monthly_fee_note"],
  }),

  created () {
    if (this.data) {
      this.form = { ...this.data }
    } else {      
      const values = {
        "monthly_support":"$250.0000",
        "monthly_support_note":"Regular Business Hours",
        "monthly_updates":"$0.0000",
        "monthly_updates_note":"Included",
        "plugg_setup":null,
        "plugg_setup_note":null,
        "monthly_fee":null,
        "monthly_fee_note":null,
        "enhanced_support":"$150.0000",
        "enhanced_support_note":"Per Hour - After Hours Support",
        "central_setup":"$0.0000",
        "central_setup_note":"Included",
        "central_monthly_fee":"$99.0000",
        "t360_monthly_fee":"$125.0000",
        "t360_plugg_setup":"$200.0000",
        "central_monthly_fee_note":"",
        "t360_plugg_setup_note":"Per Hour",
        "t360_monthly_fee_note":"Per Hour",
        "development":"$75.0000",
        "development_note":"Per Hour",
        "integrations":null,
        "integrations_note":"Per Quote",
        "certifications":null,
        "certifications_note":"Per Quote",
      }
      
      for(var key in values) {
        this.$set(this.form, key, values[key]);
//         key = key + '_note';
//         this.$set(this.form, key, values[key] || null);
      }
//       this.prices.forEach(key => {
//         this.$set(this.form, key, values[key] || null)
//         key = key + '_note'
//         this.$set(this.form, key, values[key] || null)
//       })
    }
  },
}
