<template>
  <modal
    title="New Client Profile Setup Worksheet"
    :width="1200"
    @close="$emit('close')">
    <div class="content">
      <h3>GENERAL INFORMATION</h3>
      <div class="row">
        <div class="col">
          <label>Registration ID</label>
          <span class="value">{{ data.id }}</span>
        </div>
        <div class="col">
          <label>Registration Date</label>
          <span class="value">{{ data.created | date }}</span>
        </div>
        <div class="col">
          <ex-select v-model="form.companyType" label="Company Type" :validator="$v.form.companyType" :options="companyTypeOptions" full no-style />
        </div>
      </div>
      <h3 class="mt">CLIENT COMPANY INFORMATION</h3>
      <div class="row">
        <div class="col">
          <ex-input v-model="form.company" label="Company Name" :validator="$v.form.company" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.email" type="email" label="Company Email" :validator="$v.form.email" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.companyPhone" label="Company Phone" :validator="$v.form.companyPhone" full no-style />
        </div>
        <div class="col">
          <ex-select v-model="selectedCountry" label="Country" :validator="$v.form.country" :options="countryOptions" full no-style />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ex-input v-model="form.address" label="Address" :validator="$v.form.address" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.city" label="City" :validator="$v.form.city" full no-style />
        </div>
        <div class="col">
          <ex-select v-model="selectedState" label="State/Province" :disabled="!stateOptions" :validator="$v.form.country" :options="stateOptions" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.postal" label="ZIP/Postal code" :validator="$v.form.postal" full no-style />
        </div>
      </div>

      <h3 class="mt">CLIENT CONTACT INFORMATION</h3>
      <worksheet-contacts v-model="form" :v="$v" />
      
      <h3 class="mt">ACCOUNT SETTINGS</h3>
      <table class="form-table">
        <tr>
          <td>Plugg Account</td>
          <td colspan="2">
            <el-checkbox v-model="pluggAccount.existing" @change="setCheckbox($event, 'pluggAccount', 'existing')">Link Existing - Account # </el-checkbox>
            <div class="inline">
              <ex-input v-model="form.existingAccount" :disabled="form.pluggAccount !== 'existing'" :validator="$v.form.existingAccount" no-style />
            </div>
          </td>
          <td><el-checkbox v-model="pluggAccount.new" @change="setCheckbox($event, 'pluggAccount', 'new')">New Account</el-checkbox></td>
        </tr>
        <tr>
          <td>Service Fees</td>
          <td><el-checkbox v-model="servicesFees.pluggAccount" @change="setCheckbox($event, 'servicesFees', 'pluggAccount')">Plugg Account</el-checkbox></td>
          <td><el-checkbox v-model="servicesFees.prepay" @change="setCheckbox($event, 'servicesFees', 'prepay')">Prepay</el-checkbox></td>
          <td><el-checkbox v-model="servicesFees.postPay" @change="setCheckbox($event, 'servicesFees', 'postPay')">Post-Pay</el-checkbox></td>
        </tr>
        <tr>
          <td>Billing Cycle</td>
          <td><el-checkbox v-model="billingCycle.weekly" @change="setCheckbox($event, 'billingCycle', 'weekly')">Weekly</el-checkbox></td>
          <td><el-checkbox v-model="billingCycle.monthly" @change="setCheckbox($event, 'billingCycle', 'monthly')">Monthly</el-checkbox></td>
        </tr>
      </table>

      <div class="buttons">
        <el-button icon="el-icon-check" type="primary" @click="save()">Submit</el-button>
        <el-button @click="cancel()">Cancel</el-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/Modal'
import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import WorksheetContacts from './WorksheetContacts'

import { phone } from '../../common/validators'
import { required, numeric, email } from 'vuelidate/lib/validators'

export default {
  components: { Modal, ExInput, ExSelect, WorksheetContacts },

  props: {
    data: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        country: null,
        company: null,
        email: null,
        companyPhone: null,
        companyType: null,
        address: null,
        city: null,
        state: null,
        postal: null,

        pluggAccount: null,
        servicesFees: null,
        billingCycle: null,

        existingAccount: null,
      },

      servicesFees: {
        pluggAccount: false,
        prepay: false,
        postPay: false
      },
      billingCycle: {
        weekly: false,
        monthly: false
      },
      pluggAccount: {
        existing: false,
        new: false
      },

      selectedState: null,
      selectedCountry: null,

      contacts: ['MainContact', 'SecondaryContact', 'TechnicalContact', 'BillingContact'],
    }
  },

  computed: {
    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    },

    countryOptions () {
      const options = window.countriesOptions.map(country => {
        return {
          id: country.id,
          text: `${country.id} - ${country.text}`
        }
      }) 
      options.splice(2, 0, {
        id: null,
        disabled: true,
        text: '---------------------------------'
      });
      return options
    },

    companyTypeOptions () {
      return Object.keys(window.companyTypes).map(key => {
        return {
          id: key,
          text: window.companyTypes[key]
        }
      })
    }
  },

  watch: {
    selectedState (value) {
      if (value) {
        this.form.state = this.getInitials(value)
      }
    },

    selectedCountry (value) {
      if (value) {
        this.form.country = this.getInitials(value)
        this.selectedState = null
        this.form.state = null
      } else {
        this.form.country = null
      }
    }
  },

  created () {
    this.form.company = this.data.company
    this.form.companyPhone = this.data.companyPhone
    this.form.country = this.selectedCountry = this.data.country
    this.form.state = this.selectedState = this.data.state
    this.form.address = this.data.address
    this.form.city = this.data.city
    this.form.postal = this.data.postal
    this.form.companyType = this.data.companyType
  },

  mounted () {
    this.form.MainContact.firstName = this.data.firstName
    this.form.MainContact.lastName = this.data.lastName
    this.form.MainContact.jobTitle = this.data.jobTitle
    this.form.MainContact.email = this.data.email
    this.form.MainContact.phone = this.data.phone
    this.form.MainContact.ext = this.data.ext
  },

  methods: {
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('submit', this.form)
      }
    },

    cancel () {
      this.$emit('close')
    },

    setCheckbox (checked, type, val) {
      if (checked) {
        this.form[type] = val

        const vals = Object.keys(this[type]).filter(t => t !== val)
        vals.forEach(v => {
          this[type][v] = false
        })
      } else {
        this.form[type] = null
      }
    },
    
    getInitials (value) {
      return value.split('').splice(0, 2).join('')
    }
  },

  validations () {
    const rules = {
      form: {
        company: { required },
        email: { required },
        companyType: { required },
        country: { required },
        address: { required },
        city: { required },
        postal: { required },
        companyPhone: { required, phone },
        
        pluggAccount: { required },
        servicesFees: { required },
        billingCycle: { required }
      }
    }

    this.contacts.filter(type => type !== 'SecondaryContact').forEach(type => {
      rules.form[type] = {
        firstName: { required },
        lastName: { required },
        jobTitle: { required },
        email: { required, email },
        phone: { required, phone },
      }
    })

    if (this.form.pluggAccount === 'existing') {
      rules.form.existingAccount = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  &.mt {
    margin-top: 20px;
  }
}
.title {
  h4 {
    display: inline-block;
    width: 200px;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
.form-table {
  tr td {
    padding: 5px 50px 5px 0;
    .inline {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.buttons {
  margin-top: 30px;
}
</style>