<template>
  <div id="app">
    <sidebar v-model="expanded" :active="active" />

    <section :class="{ expanded }">
      <main-header />
      <main>
        <slot />
      </main>
      <main-footer class="footer" />
    </section>
  </div>
</template>

<script>
import Sidebar from './Sidebar'
import MainHeader from './Header'
import MainFooter from './Footer'

export default {
  name: 'Layout',

  props: {
    active: {
      type: String,
      default: null
    }
  },

  components: {
    Sidebar,
    MainHeader,
    MainFooter
  },

  data () {
    return {
      expanded: false
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  min-height: 100%;
  section {
    padding-left: 65px;
    min-height: 100%;
    background-color: #fff;
    transition: all ease-in-out 0.2s;
    background-image: url(/assets/images/big-transparent-img.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    position: relative;
    padding-bottom: 70px;
    main {
      padding: 20px;
    }
    .footer {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &.expanded {
      padding-left: 195px;
    }
  }
}
</style>