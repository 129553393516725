var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.contacts, function(type) {
      return _c("div", { key: type }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c("h4", [_vm._v(_vm._s(_vm.contactTypes[type]))]),
            _vm._v(" "),
            ["TechnicalContact", "BillingContact"].includes(type)
              ? [
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.updateContact($event, type, "MainContact")
                        }
                      },
                      model: {
                        value: _vm.sameAs[type].mc,
                        callback: function($$v) {
                          _vm.$set(_vm.sameAs[type], "mc", $$v)
                        },
                        expression: "sameAs[type].mc"
                      }
                    },
                    [_vm._v("Same as Main Contact")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.updateContact(
                            $event,
                            type,
                            "SecondaryContact"
                          )
                        }
                      },
                      model: {
                        value: _vm.sameAs[type].sc,
                        callback: function($$v) {
                          _vm.$set(_vm.sameAs[type], "sc", $$v)
                        },
                        expression: "sameAs[type].sc"
                      }
                    },
                    [_vm._v("Same as Secondary Contact")]
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "First Name",
                  validator: _vm.v.form[type]
                    ? _vm.v.form[type].firstName
                    : null,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form[type].firstName,
                  callback: function($$v) {
                    _vm.$set(_vm.form[type], "firstName", $$v)
                  },
                  expression: "form[type].firstName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Last Name",
                  validator: _vm.v.form[type]
                    ? _vm.v.form[type].lastName
                    : null,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form[type].lastName,
                  callback: function($$v) {
                    _vm.$set(_vm.form[type], "lastName", $$v)
                  },
                  expression: "form[type].lastName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Title",
                  validator: _vm.v.form[type]
                    ? _vm.v.form[type].jobTitle
                    : null,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form[type].jobTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.form[type], "jobTitle", $$v)
                  },
                  expression: "form[type].jobTitle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  type: "email",
                  label: "Email",
                  validator: _vm.v.form[type] ? _vm.v.form[type].email : null,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form[type].email,
                  callback: function($$v) {
                    _vm.$set(_vm.form[type], "email", $$v)
                  },
                  expression: "form[type].email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: {
                  label: "Phone",
                  validator: _vm.v.form[type] ? _vm.v.form[type].phone : null,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form[type].phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form[type], "phone", $$v)
                  },
                  expression: "form[type].phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ex-input", {
                attrs: { label: "Ext", full: "", "no-style": "" },
                model: {
                  value: _vm.form[type].ext,
                  callback: function($$v) {
                    _vm.$set(_vm.form[type], "ext", $$v)
                  },
                  expression: "form[type].ext"
                }
              })
            ],
            1
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }