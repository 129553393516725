<template>
  <div>
    <div class="buttons">
      <el-button size="mini" icon="el-icon-edit" type="primary" @click="completeForm = true">New</el-button>
    </div>

    <div class="table-wrapper">
      <el-table :data="scopes" stripe>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <div class="status">
              <i class="el-icon-s-claim"
                :class="{
                  success: isSigned(scope.row),
                  warning: !isSigned(scope.row)
                }" />
            </div>
          </template>
        </el-table-column>
        
        <el-table-column label="Scope #">
          <template slot-scope="scope">
            {{ scope.row.scopeId }}
          </template>
        </el-table-column>

        <el-table-column label="Date Created">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Project Name">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>

        <el-table-column label="Client Name">
          <template slot-scope="scope">
            {{ scope.row.client.firstName }} {{ scope.row.client.lastName }}
          </template>
        </el-table-column>

        <el-table-column label="Signed Scope">
          <template slot-scope="scope">
            <template v-if="isSigned(scope.row) && scope.row.signedFile">
              <a :href="`/download/ClientDocument/${scope.row.signedFile.id}`" target="_blank">
                {{ scope.row.signedFile.file }}
              </a>
            </template>
            <template v-else>N/A</template>
          </template>
        </el-table-column>

        <el-table-column label="Options" width="300" align="right">
          <template slot-scope="scope">
            <template v-if="!isSigned(scope.row)">
              <el-button
                size="mini"
                type="primary"
                @click="openEdit(scope.row)">
                Edit
              </el-button>

              <a :href="`/client/scope/${scope.row.id}/pdf`" target="_blank">
                <el-button size="mini">View</el-button>
              </a>
              <el-button size="mini" :disabled="!scope.row" type="primary" icon="el-icon-edit" @click="$refs['upload'+scope.row.id].click()">Upload signed</el-button>
              <input v-show="false" :ref="'upload'+scope.row.id" type="file" @change="uploadFile($event, scope.row.id)" />
            </template>
            
            <template v-if="isSigned(scope.row)">
              <a :href="`/download/ClientDocument/${scope.row.signedFile.id}`" target="_blank">
                <el-button size="mini">View Signed</el-button>
              </a>
              <el-button size="mini" type="danger" @click="remove(scope.row.id)">Remove Signed</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <project-scope-schedule-pricing-modal v-if="completeForm" @close="close()" @submit="create($event)" :data="edit" :next-id="nextId" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import helperMixin from '../../common/helpers-mixin'
import ProjectScopeSchedulePricingModal from '../setup-checklist/ProjectScopeSchedulePricingModal'

export default {
  components: { ProjectScopeSchedulePricingModal },

  mixins: [helperMixin],

  data () {
    return {
      completeForm: false,
      nextId: null,
      edit: null,

      keys: ['build', 'build2', 'build3', 'name', 'start_date', 'end_date', 'scope_of_work', 'customizations', 'client_to_provide', 'additional_notes', 'id']
    }
  },

  computed: {
    accountManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.accountManager : {}
    },

    projectManager () {
      return this.registration.ClientAssign ? this.registration.ClientAssign.clientServicesRep : {}
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    scopes () {
      return this.$store.state.projects.scopes.data
    }
  },

  async created () {
    const items = await this.$store.dispatch('projects/scopes/load', { params: {
      client: this.registration.id
    }, order: ["id:DESC"] })
    const items2 = (await api.list('ProjectScopeSchedulePricing', {}, ["id:DESC"])).items
    const n1 = items.length ? parseInt(items[0].scopeId) + 1 : 1000
    const n2 = items2.length ? parseInt(items2[0].id) + 1 : 1000
    this.nextId = n1 > n2 ? n1 : n2
  },

  methods: {
    ...mapActions({
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument'
    }),

    close () {
      this.edit = null
      this.completeForm = false
    },

    openEdit (data) {
      const payload = { ...JSON.parse(data.schedule_pricing) }
      for (let key of this.keys) {
        payload[key] = data[key]
      }
      this.edit = payload
      this.completeForm = true
    },

    async create (form) {
      const loading = this.$loading()
      this.completeForm = false

      const data = { ...form }
      this.keys.forEach(key => delete data[key])

      const payload = {
        scopeId: this.nextId,
        client: this.registration.id,
        start_date: moment(form.start_date).format('YYYY-MM-DD'),
        end_date: moment(form.end_date).format('YYYY-MM-DD'),
        accountManager: this.accountManager.id,
        projectManager: this.projectManager.id,
        name: form.name,
        build: form.build,
        build2: form.build2,
        build3: form.build3,
        scope_of_work: form.scope_of_work,
        customizations: form.customizations,
        client_to_provide: form.client_to_provide,
        additional_notes: form.additional_notes,
        schedule_pricing: JSON.stringify(data),
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id,
      }
      if (this.edit) {
        await this.$store.dispatch('projects/scopes/update', { id: this.edit.id, payload })
      } else {
        await this.$store.dispatch('projects/scopes/create', payload)
        this.nextId++
      }
      this.close()
      loading.close()
    },

    isSigned (data) {
      return data && data.signedAt && data.signedFile
    },

    async remove (id) {
      const loading = this.$loading()
      await this.$store.dispatch('projects/scopes/update', {
        id,
        payload: {
          signedAt: null,
          signedFile: null,
          completedBy: null
        }
      })
      loading.close()
    },

    async uploadFile ($e, id) {
      let file = $e.target.files[0]
      if (file) {
        const loading = this.$loading()
        file = this.changeFileName(file, `${this.registration.company}-project-scope-and-schedule-a-pricing`)
        const document = await this.createDocument({
          type: 'Project Scope and Schedule A Pricing',
          clientRegId: this.registration.id,
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.uploadDocument({ id: document.id, file })
        await this.$store.dispatch('projects/scopes/update', { id, payload: {
          signedAt: 'CURRENT_TIMESTAMP',
          signedFile: document.id,
          completedBy: window.appData.currentUser.id
        }})
        loading.close()
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-bottom: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>
