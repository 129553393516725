import Vue from 'vue'

export default entity => {
  const state = {
    data: null,
    singles: {}
  }
  
  const getters = {
    get: state => id => state.singles[id]
  }

  const actions = {
    async load ({ commit }, { params = {}, order = [] } = { params: {}, order: [] }) {
      const data = (await api.list(entity, params, order)).items
      commit('set', data)
      return data
    },

    async get ({ commit }, id) {
      const data = (await api.get(entity, id)).item
      commit('setSingle', data)
      return data
    },

    async create ({ commit }, payload) {
      const data = (await api.create(entity, payload, true)).item
      commit('add', data)
      commit('setSingle', data)
      return data
    },
  
    async update ({ commit }, { id, payload }) {
      const data = (await api.update(entity, id, payload, true)).item
      commit('update', data)
      commit('setSingle', data)
      return data
    },
  
    async delete ({ commit }, id) {
      await api.delete(entity, id)
      return commit('delete', id)
    },
  
    async upload (context, { id, file, params = null }) {
      return await api.upload(entity, id, file, params)
    }
  }
  
  const mutations = {
    add (state, data) {
      if (state.data) {
        state.data.push(data)
      } else {
        state.data = [data]
      }
    },

    set (state, data) {
      state.data = data
    },

    update (state, data) {
      const idx = state.data.findIndex(item => item.id == data.id)
      Vue.set(state.data, idx, data)
    },

    setSingle (state, data) {
      state.singles[data.id] = data
    },

    delete (state, id) {
      delete state.singles[id]

      if (state.data) {
        const idx = state.data.findIndex(item => item.id === id)
        if (idx >= 0) state.data.splice(idx, 1)
      }
    }
  }

  return {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
  }
}