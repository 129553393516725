var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(
              "\n      Project Build Date: " +
                _vm._s(_vm._f("date")(_vm.buildDate, "LL")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "text-center", attrs: { span: 8 } }, [
            _vm._v(
              "\n      Project Build Number: 99" +
                _vm._s(_vm.buildId) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "text-right", attrs: { span: 8 } },
            [
              _vm._v("\n      Hosted Solutions\n      "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.form.hosted,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "hosted", $$v)
                  },
                  expression: "form.hosted"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-20" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 40 } },
            [
              _vm._l(_vm.domains, function(domain, key) {
                return [
                  _c(
                    "el-col",
                    {
                      key: domain,
                      attrs: { span: 6, offset: key === "dev" ? 3 : 0 }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(domain))
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.domainInputs, function(di, name) {
                        return _c(
                          "div",
                          { key: name, staticClass: "mt-10" },
                          [
                            _c("ex-input", {
                              attrs: {
                                validator:
                                  name === "domain"
                                    ? _vm.validator.form[key + "_" + name]
                                    : null,
                                label: di,
                                "no-style": "",
                                full: ""
                              },
                              model: {
                                value: _vm.form[key + "_" + name],
                                callback: function($$v) {
                                  _vm.$set(_vm.form, key + "_" + name, $$v)
                                },
                                expression: "form[`${key}_${name}`]"
                              }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("table", { staticClass: "form-table mt-20" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Client Name")]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.registration.company))]),
          _vm._v(" "),
          _c("td", [_vm._v("Client Reg Number")]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.registration.id))]),
          _vm._v(" "),
          _c("td", [_vm._v("Project Scope Number")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("ex-select", {
                staticClass: "m-0",
                attrs: { options: _vm.scopeOptions, "no-style": "", full: "" },
                model: {
                  value: _vm.scope,
                  callback: function($$v) {
                    _vm.scope = $$v
                  },
                  expression: "scope"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Project Name")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("ex-input", {
                attrs: {
                  validator: _vm.validator.form.name,
                  full: "",
                  "no-style": ""
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("td", [_vm._v("Est. Project Start")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("datepicker", {
                class: {
                  error:
                    _vm.validator.form.start_date.$dirty &&
                    _vm.validator.form.start_date.$invalid
                },
                attrs: {
                  "disabled-dates": _vm.disabledStartDates,
                  "calendar-button": "",
                  "calendar-button-icon": "el-icon-date",
                  placeholder: "Est. Project Start"
                },
                model: {
                  value: _vm.form.start_date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "start_date", $$v)
                  },
                  expression: "form.start_date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("td", [_vm._v("Est. Project Deadline")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("datepicker", {
                class: {
                  error:
                    _vm.validator.form.end_date.$dirty &&
                    _vm.validator.form.end_date.$invalid
                },
                attrs: {
                  disabled: !_vm.form.start_date,
                  "disabled-dates": _vm.disabledEndDates,
                  "calendar-button-icon": "el-icon-date",
                  "calendar-button": "",
                  placeholder: "Est. Project Deadline"
                },
                model: {
                  value: _vm.form.end_date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "end_date", $$v)
                  },
                  expression: "form.end_date"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Account Manager")]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(_vm.accountManager.firstName) +
                " " +
                _vm._s(_vm.accountManager.lastName)
            )
          ]),
          _vm._v(" "),
          _c("td", [_vm._v("Project Manager")]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(_vm.projectManager.firstName) +
                " " +
                _vm._s(_vm.projectManager.lastName)
            )
          ]),
          _vm._v(" "),
          _c("td"),
          _vm._v(" "),
          _c("td")
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            { staticClass: "p-0", attrs: { colspan: "6" } },
            [
              _c("ex-textarea", {
                attrs: { maxlength: "5000", full: "", "no-margin": "" },
                model: {
                  value: _vm.form.scope_of_work,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "scope_of_work", $$v)
                  },
                  expression: "form.scope_of_work"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            { staticClass: "p-0", attrs: { colspan: "6" } },
            [
              _c("ex-textarea", {
                attrs: { maxlength: "5000", full: "", "no-margin": "" },
                model: {
                  value: _vm.form.customizations,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "customizations", $$v)
                  },
                  expression: "form.customizations"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            { staticClass: "p-0", attrs: { colspan: "6" } },
            [
              _c("ex-textarea", {
                attrs: { maxlength: "5000", full: "", "no-margin": "" },
                model: {
                  value: _vm.form.client_to_provide,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "client_to_provide", $$v)
                  },
                  expression: "form.client_to_provide"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            { staticClass: "p-0", attrs: { colspan: "6" } },
            [
              _c("ex-textarea", {
                attrs: { maxlength: "5000", full: "", "no-margin": "" },
                model: {
                  value: _vm.form.additional_notes,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "additional_notes", $$v)
                  },
                  expression: "form.additional_notes"
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("PROJECT INFORMATION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("SCOPE OF WORK")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("CUSTOMIZATIONS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("CLIENT TO PROVIDE")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "6" } }, [
        _vm._v("ADDITIONAL PROJECT NOTES")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }