var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    false
      ? _c(
          "table",
          { staticClass: "form-table" },
          [
            _c("tr", { staticClass: "heading" }, [
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.form.name || "N/A"))
              ])
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.projectPricings, function(text, key) {
              return _c("tr", { key: key }, [
                _c("td", [_vm._v(_vm._s(text))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "p-0" },
                  [
                    _c("ex-input", {
                      attrs: {
                        label: "Price",
                        validator: _vm.validator.form[key],
                        full: "",
                        "no-style": ""
                      },
                      on: {
                        blur: function($event) {
                          return _vm.formatCurrency(key)
                        }
                      },
                      model: {
                        value: _vm.form[key],
                        callback: function($$v) {
                          _vm.$set(_vm.form, key, $$v)
                        },
                        expression: "form[key]"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "p-0" },
                  [
                    _c("ex-input", {
                      attrs: {
                        validator: _vm.validator.form[key + "_note"],
                        full: "",
                        "no-style": ""
                      },
                      model: {
                        value: _vm.form[key + "_note"],
                        callback: function($$v) {
                          _vm.$set(_vm.form, key + "_note", $$v)
                        },
                        expression: "form[key + '_note']"
                      }
                    })
                  ],
                  1
                )
              ])
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "form-table mt-50" },
      [
        _c("tr", { staticClass: "heading" }, [
          _c("td", { attrs: { colspan: "3" } }, [
            _vm._v(_vm._s(_vm.form.name || "N/A"))
          ])
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._l(_vm.pluggPricings, function(item, index) {
          return [
            _c(
              "tbody",
              { key: index },
              [
                typeof item === "string"
                  ? _c("tr", { staticClass: "heading" }, [
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v(_vm._s(item))
                      ])
                    ])
                  : _vm._l(item, function(text, key) {
                      return _c("tr", { key: key }, [
                        _c("td", [_vm._v(_vm._s(text))]),
                        _vm._v(" "),
                        text !== "-"
                          ? _c(
                              "td",
                              { staticClass: "p-0" },
                              [
                                _c("ex-input", {
                                  attrs: {
                                    label: "Price",
                                    validator: _vm.validator.form[key],
                                    full: "",
                                    "no-style": ""
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.formatCurrency(key)
                                    }
                                  },
                                  model: {
                                    value: _vm.form[key],
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, key, $$v)
                                    },
                                    expression: "form[key]"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("td", { staticClass: "p-0" }, [
                              _vm._v("\n                -\n              ")
                            ]),
                        _vm._v(" "),
                        text !== "-"
                          ? _c(
                              "td",
                              { staticClass: "p-0" },
                              [
                                _c("ex-input", {
                                  attrs: {
                                    validator:
                                      _vm.validator.form[key + "_note"],
                                    full: "",
                                    "no-style": ""
                                  },
                                  model: {
                                    value: _vm.form[key + "_note"],
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, key + "_note", $$v)
                                    },
                                    expression: "form[key + '_note']"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("td", { staticClass: "p-0" }, [
                              _vm._v("\n                -\n              ")
                            ])
                      ])
                    })
              ],
              2
            )
          ]
        }),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("tr", [
          _c("td", { attrs: { colspan: "3" } }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.notes,
                  expression: "form.notes"
                }
              ],
              domProps: { value: _vm.form.notes },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "notes", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _vm._m(3)
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", [_vm._v("DESCRIPTION")]),
      _vm._v(" "),
      _c("td", [_vm._v("COST")]),
      _vm._v(" "),
      _c("td", [_vm._v("NOTE")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", [_vm._v("DESCRIPTION")]),
      _vm._v(" "),
      _c("td", [_vm._v("COST")]),
      _vm._v(" "),
      _c("td", [_vm._v("NOTE")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { colspan: "3" } }, [_vm._v("Notes")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [
        _c("b", [_vm._v("Contact us for additional services and pricing")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }