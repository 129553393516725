<template>
  <div>
    <div class="table-wrapper">
      <el-table :data="statements" style="min-width: 100%" stripe>
        <el-table-column label="Statement #">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>

        <el-table-column label="Filename">
          <template v-if="scope.row.file" slot-scope="scope">
            <a :href="`/download/ClientDocument/${scope.row.file.id}`" target="_blank">
              {{ scope.row.file.file }}
            </a>
          </template>
        </el-table-column>

        <el-table-column label="Date Start">
          <template slot-scope="scope">
            {{ scope.row.startDate | date('LL') }}
          </template>
        </el-table-column>

        <el-table-column label="Date End">
          <template slot-scope="scope">
            {{ scope.row.endDate | date('LL') }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import helperMixin from '../../common/helpers-mixin'
  import { mapState } from 'vuex'

  export default {
    name: "BillingStatements",

    mixins: [ helperMixin ],

    data() {
      return {
        openForm: false
      }
    },

    computed: {
      ...mapState({
        client: state => state.client.single.registration,
        statements: state => state.client.statements.data
      })
    },

    created () {
      this.loadStatements();
    },

    methods: {

      openPdf(documentId) {
        window.open(`/download/ClientDocument/${documentId}`, '_blank');
      },

      loadStatements() {
        this.$store.dispatch('client/statements/load', { params: { client: this.client.id } });
      },

      async remove(id) {
        const loading = this.$loading();
        try {
          await this.$confirm('Are you sure you want to delete this statement?');
          try {
            await this.$store.dispatch('client/statements/delete', id);
            this.$message.success('Statement deleted.');
          } catch (e) {
            console.log(e.message);
            this.$message.error('Something went wrong');
          }
        } catch {}

        loading.close();
      },
    }
  }
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
</style>