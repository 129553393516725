var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "Create New User", width: 800 },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "form",
        { staticClass: "form" },
        [
          _c("ex-input", {
            attrs: { label: "First Name", validator: _vm.$v.form.firstName },
            model: {
              value: _vm.form.firstName,
              callback: function($$v) {
                _vm.$set(_vm.form, "firstName", $$v)
              },
              expression: "form.firstName"
            }
          }),
          _vm._v(" "),
          _c("ex-input", {
            attrs: { label: "Last Name", validator: _vm.$v.form.lastName },
            model: {
              value: _vm.form.lastName,
              callback: function($$v) {
                _vm.$set(_vm.form, "lastName", $$v)
              },
              expression: "form.lastName"
            }
          }),
          _vm._v(" "),
          _c("ex-input", {
            attrs: {
              label: "Email",
              type: "email",
              validator: _vm.$v.form.email
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c("ex-input", {
            attrs: {
              label: "Phone",
              type: "number",
              validator: _vm.$v.form.phone
            },
            model: {
              value: _vm.form.phone,
              callback: function($$v) {
                _vm.$set(_vm.form, "phone", $$v)
              },
              expression: "form.phone"
            }
          }),
          _vm._v(" "),
          _c("ex-input", {
            attrs: {
              label: "Phone Ext",
              type: "number",
              validator: _vm.$v.form.phoneExt
            },
            model: {
              value: _vm.form.phoneExt,
              callback: function($$v) {
                _vm.$set(_vm.form, "phoneExt", $$v)
              },
              expression: "form.phoneExt"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.beforeSave()
                }
              }
            },
            [_vm._v("Create")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }