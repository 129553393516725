<template>
  <layout active="/system/integrations">
    <panel title="APIX Connection Manager">
      <form class="form apix">
        <ex-select v-model="apixForm.apix_connect" :disabled="!updateApix" :validator="$v.apixForm.apix_connect" :options="yesNoOptions" label="APIX Connect" :classes="{'disabled': !updateApix, 'apix': true}" />
        <ex-input v-model="apixForm.version" :disabled="!updateApix" :validator="$v.apixForm.version" label="Version" :classes="{'disabled': !updateApix, 'apix': true}"/>
        <ex-input v-model="apixForm.username" :disabled="!updateApix" :validator="$v.apixForm.username" label="Username" :classes="{'disabled': !updateApix, 'apix': true}" />
        <ex-input v-model="apixForm.code" :disabled="!updateApix" :validator="$v.apixForm.code" label="Key" :classes="{'disabled': !updateApix, 'apix': true}" />
        <ex-select v-model="apixForm.status" :disabled="!updateApix" :options="statusOptions"  label="Status" :classes="{'disabled': !updateApix, 'apix': true}" />
        <ex-input v-model="apixForm.notes" :disabled="!updateApix" :validator="$v.apixForm.notes" label="Notes" :classes="{'disabled': !updateApix, 'apix': true}"  />
        <div class="button">
          <el-button v-if="!updateApix" icon="el-icon-edit" circle @click="updateApixConnection()"/>
          <el-button v-else type="success" icon="el-icon-check" circle @click="saveApix()"/>
        </div>
      </form>
    </panel>
  </layout>
</template>

<script>
  import { required, numeric, email, alpha, alphaNum } from 'vuelidate/lib/validators'

  import Panel from '../common/Panel'
  import Layout from '../common/Layout'
  import { mapActions } from 'vuex'
  import ExInput from '../common/Input'
  import ExSelect from '../common/Select'

  export default {
    name: 'system-integrations',
    components: {
      Panel,
      Layout,
      ExInput,
      ExSelect,
    },

    data () {
      return {
        apixForm: {
          apix_connect: null,
          version: null,
          username: null,
          code: null,
          status: null,
          notes: null
        },

        updateApix: false,

        statusOptions: [
          {
            id: 'Review',
            text: 'Review'
          },
          {
            id: 'Testing',
            text: 'Testing'
          },
          {
            id: 'Active',
            text: 'Active'
          },
          {
            id: 'Disabled',
            text: 'Disabled'
          }
        ],

        yesNoOptions: [
          {
            id: 1,
            text: 'Yes'
          },
          {
            id: 0,
            text: 'No'
          },
        ]
      }
    },

    async created () {
      [...(await this.load())]
      this.apixForm = this.$store.state.system.apix.data[0]
    },

    methods: {

      ...mapActions({
        load: 'system/apix/load',
        create: 'system/apix/create',
        delete: 'system/apix/delete',
        update: 'system/apix/update'
      }),

      async saveApix () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          try {
            const payload = {
              apix_connect: this.apixForm.apix_connect,
              version: this.apixForm.version,
              username: this.apixForm.username,
              code: this.apixForm.code,
              status: this.apixForm.status,
              notes: this.apixForm.notes
            }

            await this.update({id: 1, payload})
            this.$message.success('APIX connection successfully updated')
            this.updateApix = false
          } catch {
            this.$message.error(e.message)
          }
        }
      },

      updateApixConnection() {
        this.updateApix = true
      }
    },

    validations: {
      apixForm: {
        apix_connect: { required },
        version: {  },
        username: { alphaNum },
        notes: {},
      }
    }
  }
</script>
<style lang="scss" scoped>
  .apix {
    .disabled {
      font-size: 14px;
    }
  }
</style>