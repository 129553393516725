var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.employees
    ? _c("div", [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Tag Employees:")]),
          _vm._v(" "),
          _vm.availableEmployees.length && !_vm.readonly
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectEmployee,
                      expression: "selectEmployee"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectEmployee = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.availableEmployees, function(employee) {
                  return _c(
                    "option",
                    { key: employee.id, domProps: { value: employee.id } },
                    [
                      _vm._v(
                        _vm._s(employee.firstName) +
                          " " +
                          _vm._s(employee.lastName)
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.employees.length
            ? _c("span", [_vm._v("There are no Active employees")])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tags" },
          _vm._l(_vm.selectedEmployees, function(id) {
            return _c(
              "el-tag",
              {
                key: id,
                staticClass: "mr-5",
                attrs: {
                  effect: "dark",
                  size: "mini",
                  closable:
                    !_vm.readonly ||
                    (_vm.selfRemove &&
                      _vm.getEmployee(id).userId.id === _vm.userId)
                },
                on: {
                  close: function($event) {
                    return _vm.removeEmployee(id)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.getEmployee(id).firstName) +
                    " " +
                    _vm._s(_vm.getEmployee(id).lastName) +
                    "\n    "
                )
              ]
            )
          }),
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }