import client from './client'

const state = {
  support: null
}
const mutations = {
  set (state, data) {
    state.support = data
  }
}

export default {
  state,
  mutations,
  namespaced: true,
  modules: {
    client
  }
}
