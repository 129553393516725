<template>
  <div v-if="message" class="notifications">
    <div class="flex">
      <div class="img-t"><img src="/assets/images/chat.png"></div>
      <div class="t-text">
        <h2 ref="overflow" :class="{ 'none-overflow': readMore }">{{ message.message }}</h2>
        <h3>{{ message.createdAt | date }}</h3>
        <div class="arrows">
          <div :class="{active: this.messages.length > 1,  arrow :true}" @click="prev()">
            <img src="/assets/images/left-arrow.png">
          </div>
          <div :class="{active: this.messages.length > 1,  arrow :true}" @click="next()">
            <img src="/assets/images/right-arrow-new.png">
          </div>
          <span class="counter">({{ index + 1}} / {{ messages.length }})</span>
        </div>
      </div>

      <div class="read-more-container" v-if="isEllypsisActive">
        <button class="read-more" @click="readMore = !readMore">{{ readMore ? 'Read less' : 'Read more' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      index: 0,
      maxChar: 150,
      readMore: false,
      isEllypsisActive: false,
      arrowR: "../../../../assets/images/right-arrow.png",
      arrowRNew: "../../../../assets/images/right-arrow-new.png",
      arrowL: "../../../../assets/images/left-arrow.png",
      arrowLNew: "../../../../assets/images/left-arrow-new.png"
    }
  },

  computed: {
    showReadMore () {
      return this.overflow
    },

    message () {
      return this.messages && this.messages.length ? this.messages[this.index] : null
    },

    messages () {
      return this.$store.state.system.messages.data
    }
  },

  watch: {
    message: {
      handler () {
        this.setEllypsis()
      },
      immediate: true
    }
  },

  async created () {
    await this.load(['createdAt:DESC'])
    this.setEllypsis()
    window.addEventListener("resize", this.setEllypsis)
  },

  destroyed() {
    window.removeEventListener("resize", this.setEllypsis);
  },

  methods: {
    ...mapActions({
      load: 'system/messages/load'
    }),

    next () {
      if (this.index + 1 > this.messages.length - 1) this.index = 0
      else this.index++
      this.readMore ? this.readMore = false : null
    },

    prev () {
      if (this.index - 1 < 0) this.index = this.messages.length - 1
      else this.index--
      this.readMore ? this.readMore = false : null
    },

    setEllypsis () {
      if(this.$refs.overflow) {
        this.$nextTick(() => {
          this.isEllypsisActive = this.$refs.overflow.scrollWidth > this.$refs.overflow.clientWidth
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notifications {
  width: calc(100% - 235px);
  padding: 15px 20px;
  .img-t {
    margin-right: 10px;
  }
  .t-text {
    width: calc(100% - 140px);
    h2 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
      color: #4d00be;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.none-overflow {
        text-overflow: unset;
        white-space: unset;
      }
    }
    h3 {
      font-size: 11px;
      font-weight: 700;
      margin-bottom: 8px;
      color: #989898;
    }
    .arrows {
      display: flex;
      .arrow  {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(218,218,218,1) 51%, rgba(218,218,218,1) 79%, rgba(193,193,193,1) 100%);
        &.active:hover {
          background: #4d00be;
        }
      }
      .counter {
        display: inline-block;
        margin-left: 10px;
        font-size: 12px;
        font-weight: bold;
        color: #4d00be;
      }
    }
  }
  .read-more-container {
    width: 140px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .read-more {
      padding: 2px 7px;
      color: #f8f5f5;
      background: #bcbcbc;
      border: none;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover { background: #8f8f8f; }
      &:focus { outline: none; }
    }
  }
}
</style>