<template>
  <div>
    <client-assign />

    <nda />

    <client-profile-setup-worksheet />

    <project-scope-schedule-pricing />

    <client-agreement />
  </div>
</template>

<script>
import nda from './NDA'
import ClientAssign from './ClientAssign'
import ClientAgreement from './ClientAgreement'
import ProjectScopeSchedulePricing from './ProjectScopeSchedulePricing'
import ClientProfileSetupWorksheet from './ClientProfileSetupWorksheet'

export default {
  name: 'SetupChecklist',

  components: {
    nda,
    ClientAssign,
    ClientAgreement,
    ProjectScopeSchedulePricing,
    ClientProfileSetupWorksheet
  },

  props: {
    id: {
      type: String,
      required: true
    }
  }
}
</script>
