var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.openCreate()
                }
              }
            },
            [_vm._v("New")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              staticStyle: { "min-width": "100%" },
              attrs: { data: _vm.subscriptions, stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "Day" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.day) + "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Frequency" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.frequency) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Note" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.note) + "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          { attrs: { effect: "dark", size: "small" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.amount) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Actions", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-edit",
                              type: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("Edit")]
                        ),
                        _vm._v(" "),
                        _vm.isUserAdmin
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-delete",
                                  type: "danger"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.remove(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("Remove")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.openForm
        ? _c("billing-subscription-form", {
            attrs: { edit: _vm.edit },
            on: {
              close: function($event) {
                _vm.openForm = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }