var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c(
          "panel",
          { staticClass: "profile", attrs: { title: "My profile" } },
          [
            _vm.data
              ? _c("div", [
                  _c("h3", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.data.firstName) +
                        " " +
                        _vm._s(_vm.data.lastName) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "item" }, [
                    _vm._v("Account ID: "),
                    _c("b", [_vm._v(_vm._s(_vm.data.uuid))])
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "item" }, [
                    _vm._v("Role: "),
                    _c("b", [_vm._v(_vm._s(_vm._f("role")(_vm.data.role)))])
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "item" }, [
                    _vm._v("Email: "),
                    _c("b", [_vm._v(_vm._s(_vm.data.email))])
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "item" }, [
                    _vm._v("Job Title: "),
                    _c("b", [_vm._v(_vm._s(_vm.data.job_title || "N/A"))])
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "item" }, [
                    _vm._v("Phone: "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.data.phone) + "-" + _vm._s(_vm.data.phoneExt)
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "item" }, [
                    _vm._v("\n          Last login:\n          "),
                    _vm.data.lastLogin
                      ? _c("b", [
                          _vm._v(_vm._s(_vm._f("date")(_vm.data.lastLogin)))
                        ])
                      : _c("b", [_vm._v("N/A")])
                  ])
                ])
              : _c("div", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "placeholder"
                })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }