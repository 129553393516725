<template>
  <nav :class="{ expanded }">
    <div class="overflow">
      <a href="/">
        <div class="logo">
          <i />
        </div>
      </a>

      <div class="hamgurger">
        <a @click="expanded = !expanded">
          <img src="/assets/images/bars.png">
        </a>
      </div>

      <ul>
        <li
          v-for="(item, index) in menu"
          :key="item.name + index"
          v-if="item.view !== false"
          :class="{ active: isActive(item), open: item.open }">
          <a v-if="item.link" :href="item.link" class="item">
            <div class="main">
              <i class="icon" :class="item.icon" />
              <span>{{ item.name }}</span>
            </div>
          </a>
          <template v-else>
            <div class="item" @click="toggleParentMenu(item)">
              <div class="main">
                <i class="icon" :class="item.icon" />
                <span>{{ item.name }}</span>
              </div>
            </div>
            <div v-show="item.open && expanded" class="dropdown">
              <template v-for="(subitem, idx) in item.items">
                <a
                  v-if="subitem.view !== false"
                  :key="subitem.link + idx"
                  :href="subitem.link"
                  class="dropdown-item"
                  :class="{ active: active === subitem.link || active === subitem.additionalLink }">
                  {{ subitem.name }}
                </a>
              </template>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <modal v-if="openPin"
      title="Please Enter System PIN"
      :width="360"
      @close="openPin = false">
      <div class="content">
        <label class="label">System PIN:</label>
        <input type="text" v-model="pinCode" class="form-inp" ref="input" maxlength="4"/>
        <span class="input-error" v-if="pinFailed">Invalid PIN.</span>
        <button class="login-button" @click="checkPin()">Confirm</button>
        <button class="login-button" @click="openPin = false">Cancel</button>
      </div>
    </modal>
  </nav>
</template>

<script>
import helperMixin from './helpers-mixin'
import Modal from './Modal'

export default {
  name: 'Sidebar',

  components: {
    Modal
  },

  mixins: [helperMixin],

  props: {
    value: {
      type: Boolean,
      required: true
    },

    active: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      menu: [],
      openPin: false,
      pinItem: null,
      pinCode: '',
      pinFailed: false,
    }
  },

  computed: {
    expanded: {
      get () {
        return this.value
      },
      set (value) {
        if (!value) this.menu.forEach(item => item.open = false)
        this.$emit('input', value)
      }
    }
  },

  watch: {
    isUserAdmin (value) {
      this.setMenu()
    },

    isUserClient (value) {
      this.setMenu()
    }
  },

  created () {
    this.setMenu()
  },

  methods: {
    setMenu () {
      this.menu = [
        {
          icon: 'dashboard',
          name: 'Dashboard',
          link: '/dashboard',
        },
        {
          icon: 'cubes',
          name: 'APIX',
          open: false,
          items: [
            {
              name: 'FlyBoard',
              link: '/exchange/flyboarding',
            }
          ],
        },
        {
          icon: 'tools',
          name: 'Tools',
          open: false,
          items: [
            {
              name: 'Scheduler',
              link: '/tools/scheduler',
            }
          ],
        },
        {
          icon: 'profile',
          name: 'Support',
          open: false,
          items: [
            {
              name: 'My Account',
              link: '/support/my-account',
              view: this.isUserClient,
            },
            {
              name: 'Help-Desk',
              link: '/',
            },
            {
              name: 'Documentation',
              link: '/',
            }
          ],
        },
        {
          icon: 'client',
          name: 'Clients',
          open: false,
          view: !this.isUserClient,
          items: [
            {
              name: 'Registrations',
              link: '/clients/registrations',
            },
            {
              name: 'Client Profiles',
              link: '/clients/profiles',
            },
            {
              name: 'Projects',
              link: '/clients/projects',
            }
          ],
        },
        {
          icon: 'settings',
          name: 'Management',
          open: false,
          view: !this.isUserClient,
          items: [
            {
              name: 'Invoicing',
              link: '/management/invoicing'
            },
            {
              name: 'Employees',
              link: '/system/employees',
              additionalLink: '/system/create-staff-users'
            },
            {
              name: 'System Messages',
              link: '/system/messages',
              view: this.isUserAdmin
            },
            {
              name: 'Users',
              link: '/system/users',
            },
            {
              name: 'Document Depot',
              link: '/system/document-depot',
            },
          ],
        },
        {
          icon: 'settings',
          name: 'System',
          open: false,
          view: !this.isUserClient,
          pin: true,
          items: [
            {
              name: 'Integrations Management',
              link: '/system/integrations',
            },
            {
              name: 'System Providers',
              link: '/system/providers',
            },
            {
              name: 'System Builds',
              link: '/system/builds',
            },
            {
              name: 'System Status',
              link: '/',
            },
            {
              name: 'System Information',
              link: '/system/information',
            },
            {
              name: 'System PINS',
              link: '/system/pins',
            },
            {
              name: 'Change Log',
              link: '/system/changelog',
            }
          ],
        }        
      ]
      if (this.active) {
        this.menu.filter(item => item.items).forEach(item => {
          if (item.items.some(i => i.link === this.active || i.additionalLink === this.active )) this.toggleMenu(item)
        })
      }
    },

    isActive (item) {
      if (item.items) {
        return item.items.some(i => i.link === this.active)
      }
      return this.active === item.link
    },

    toggleMenu (item) {
      if (!this.expanded) this.expanded = true
      item.open = !item.open
    },

    toggleParentMenu (item) {
      if (!this.expanded) this.expanded = true
      if (item.open){
        item.open = !item.open
      } else {
        if (item.pin) {
          this.openPin = true
          this.pinItem = item
        }else{
          item.open = !item.open
        }
      }
    },

    async checkPin () {
      const action = (await api.action("Pin", "new", "CheckSPin", { pin: this.pinCode })).action
      if(action.pin) {
        this.pinFailed = false
        this.openPin = false
        this.pinCode = ''
        if (!this.expanded) this.expanded = true
        this.pinItem.open = !this.pinItem.open
      }else{
        this.pinFailed = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$widthm: calc(100% - 8px);
nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 65px;
  height: 100vh;
  background-color: #4d00be;
  transition: all ease-in-out 0.2s;
  z-index: 9;
  .overflow {
    width: calc(100% + 8px);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ul {
    li {
      display: block;
      width: $widthm;
      min-height: 50px;
      transition: all ease-in-out .1s;
      position: relative;
      .item {
        display: block;
        width: 100%;
        height: 50px;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        .main {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
        .dropdown {
          display: block;
        }
        .icon {
          display: inline-block;
          vertical-align: top;
          width: 60px;
          height: 50px;
          background-position: center;
          background-repeat: no-repeat;
          &.dashboard {
            background-image: url(/assets/images/sidebar/dashboard-2.png);
          }
          &.calc {
            background-image: url(/assets/images/sidebar/calc-2.png);
          }
          &.client {
            background-image: url(/assets/images/sidebar/client-2.png);
          }
          &.clock {
            background-image: url(/assets/images/sidebar/clock-2.png);
          }
          &.cubes {
            background-image: url(/assets/images/sidebar/cubes-2.png);
          }
          &.profile {
            background-image: url(/assets/images/sidebar/profile-2.png);
          }
          &.settings {
            background-image: url(/assets/images/sidebar/settings-2.png);
          }
          &.store {
            background-image: url(/assets/images/sidebar/store-2.png);
          }
          &.thumbsup {
            background-image: url(/assets/images/sidebar/thumbsup-2.png);
          }
          &.tools {
            background-image: url(/assets/images/sidebar/tools-2.png);
          }
          &.users {
            background-image: url(/assets/images/sidebar/users-2.png);
          }
        }
        span {
          display: none;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }
      }
      &:hover, &.open {
        background-color: #380089;
      }
      &.active {
        span {
          color: #383838;
        }
        &::after {
          content: '';
          width: calc(100% + 8px);
          height: 50px;
          background-image: url(/assets/images/menu-item-bg.png);
          background-repeat: no-repeat;
          background-position: right;
          position: absolute;
          top: 0;
          left: 0;
          transition: all ease-in-out 0.2s;
          z-index: 1;
        }
        .icon {
          &.dashboard {
            background-image: url(/assets/images/sidebar/dashboard.png);
          }
          &.calc {
            background-image: url(/assets/images/sidebar/calc.png);
          }
          &.client {
            background-image: url(/assets/images/sidebar/client.png);
          }
          &.clock {
            background-image: url(/assets/images/sidebar/clock.png);
          }
          &.cubes {
            background-image: url(/assets/images/sidebar/cubes.png);
          }
          &.profile {
            background-image: url(/assets/images/sidebar/profile.png);
          }
          &.settings {
            background-image: url(/assets/images/sidebar/settings.png);
          }
          &.store {
            background-image: url(/assets/images/sidebar/store.png);
          }
          &.thumbsup {
            background-image: url(/assets/images/sidebar/thumbsup.png);
          }
          &.tools {
            background-image: url(/assets/images/sidebar/tools.png);
          }
          &.users {
            background-image: url(/assets/images/sidebar/users.png);
          }
        }
      }
      .dropdown {
        background-color: #380089;
        border-top: 1px solid rgba(0,0,0,.1);
        .dropdown-item {
          display: block;
          font-size: 13px;
          padding: 8px 20px;
          color: #f2f2f2;
          text-decoration: none;
          &:hover, &.active {
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
  .logo {
    width: calc(100% - 8px);
    height: 75px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    i {
      width: 55px;
      height: 55px;
      background-image: url(/assets/images/plugg-logo.png);
      background-repeat: no-repeat;
      background-position: center;
      transition: all ease-in-out 0.2s;
    }
  }
  .hamgurger {
    width: $widthm;
    margin: 60px 0;
    display: flex;
    justify-content: center;
    opacity: .8;
    transition: all ease-in-out 0.2s;
    a {
      padding: 5px;
      cursor: pointer;
    }
    &:hover {
      opacity: 1;
    }
  }
  &.expanded {
    width: 195px;
    ul {
      li {
        span {
          display: inline-block;
          vertical-align: top;
          line-height: 50px;
        }
      }
    }
    .logo i {
      background-image: url(/assets/images/big-imgback.png);
      background-repeat: no-repeat;
      background-position: center;
      width: 185px;
      height: 75px;
    }
  }
}
</style>
