<template>
<div>
  <div class="buttons">
    <div>
      <template v-if="selected.length">
        <el-button size="mini" icon="el-icon-refresh" @click="newCode()">Reset Activation Code</el-button>
        <el-button size="mini" icon="el-icon-key" @click="resetPassword()">Reset Password</el-button>
        <el-button size="mini" type="danger" icon="el-icon-remove" :disabled="clientIsSelected" @click="block()">Block</el-button>
      </template>
    </div>
    <el-button v-if="userId && users && users.length < 4" size="small" type="primary" icon="el-icon-plus" @click="showAddUser = true">Create Sub-User</el-button>
  </div>

  <div class="table-wrapper">
    <el-table
      v-loading="loading"
      :data="users"
      style="min-width: 100%"
      stripe
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55" />
      <el-table-column label="First Name">
        <template slot-scope="scope">{{ scope.row.firstName }}</template>
      </el-table-column>

      <el-table-column label="Last Name">
        <template slot-scope="scope">{{ scope.row.lastName }}</template>
      </el-table-column>

      <el-table-column label="Email">
        <template slot-scope="scope">{{ scope.row.email }}</template>
      </el-table-column>

      <el-table-column label="Activation Code">
        <template slot-scope="scope">
          <template v-if="isActive(scope.row)">N/A</template>
          <template v-else>
            <template v-if="!isACExpired(scope.row)">{{ scope.row.activationCode }}</template>
            <el-tag v-else type="danger" effect="dark" size="mini">Expired</el-tag>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="Activation Code Expires">
        <template slot-scope="scope">
          <span v-if="!isActive(scope.row) && !isACExpired(scope.row) && activationExpires(scope.row)">{{ activationExpires(scope.row) | date }}</span>
          <span v-else>N/A</span>
        </template>
      </el-table-column>

      <el-table-column label="Activation Date">
        <template slot-scope="scope">
          <template v-if="isActive(scope.row)">{{ scope.row.activationDate | date('PD') }}</template>
          <template v-else>N/A</template>
        </template>
      </el-table-column>

      <el-table-column label="Reset pass Request">
        <template slot-scope="scope">
          <template v-if="scope.row.pwResetRequested">{{ scope.row.pwResetRequested | date('PD') }}</template>
          <template v-else>N/A</template>
        </template>
      </el-table-column>

      <el-table-column label="Reset Pass Completed">
        <template slot-scope="scope">
          <template v-if="scope.row.resetPwDate">{{ scope.row.resetPwDate | date('PD') }}</template>
          <template v-else>N/A</template>
        </template>
      </el-table-column>

      <el-table-column label="Blocked">
        <template slot-scope="scope">{{ scope.row.active === 'Blocked' ? 'Yes' : 'No' }}</template>
      </el-table-column>
    </el-table>
  </div>

  <client-add-user
    v-if="showAddUser"
    :id="userId"
    @save="onUserCreated()"
    @close="showAddUser = false" />
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ClientAddUser from './ClientAddUser'

export default {
  components: { ClientAddUser },

  data () {
    return {
      selected: [],
    
      showAddUser: false,
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      getUsersById: 'client/single/users/getUsersById' 
    }),

    clientIsSelected () {
      return this.selected.some(user => user.id === this.userId)
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    userId () {
      return this.user ? this.user.id : null
    },

    user () {
      return this.registration.userId || null
    },

    users () {
      if (!this.user) return []
      return [this.user].concat(this.getUsersById(this.userId) || [])
    }
  },

  created () {
    if (this.userId) this.load()
  },

  methods: {
    ...mapActions({
      get: 'client/single/get',
      getUsers: 'client/single/users/get'
    }),

    onUserCreated () {
      this.showAddUser = false
      this.load(true)
    },

    async loadUser () {
      this.loading = true
      await this.get({ id: this.registration.id, force: true })
      this.loading = false
    },

    async load (force = false) {
      this.loading = true
      await this.getUsers({ id: this.userId, force })
      this.loading = false
    },

    async block () {
      if (!this.clientIsSelected) {
        try {
          await this.$confirm(`Are you sure you want to block this Employees?`, 'Warning', {
            confirmButtonText: 'Block',
            cancelButtonText: 'Cancel',
            type: 'error'
          })
          this.loading = true
          const promises = []
          this.selected.forEach(user => {
            if (user.active === 'Yes') {
              promises.push(api.update('User', user.id, { active: 'Blocked' }, true))
            }
          })
          await Promise.all(promises)
          await this.load(true)
          this.loading = false
          this.$message.success(`Employee(s) successfully blocked.`)
        } catch {}
      }
    },

    async resetPassword () {
      try {
        await this.$confirm('Are you sure you want to reset password?', 'Warning', {
          confirmButtonText: 'Reset',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        this.loading = true
        const promises = []
        this.selected.forEach(user => {
          if (user.active === 'Yes') {
            promises.push(api.action('User', user.id, 'ResetPassword'))
          }
        })
        await Promise.all(promises)
        await this.load(true)
        this.loading = false
        this.$message.success(`Password reset successfully completed.`)
      } catch {}
    },
    
    async newCode () {
      try {
        await this.$confirm('Are you sure you want to reset Activation Code?', 'Warning', {
          confirmButtonText: 'Reset',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        this.loading = true
        const promises = []
        this.selected.forEach(user => {
          if (user.active === 'No') {
            promises.push(api.action('User', user.id, 'NewActivationCode'))
          }
        })
        await Promise.all(promises)
        await this.load(true)
        await this.loadUser()
        this.loading = false
        this.$message.success(`Activation Code reset successfully completed.`)
      } catch {}
    },

    activationExpires (user) {
      if (this.isACExpired(user) || !user.activationCodeExpires) return null
      return new Date(parseInt(user.activationCodeExpires) * 1000);
    },

    isACExpired (user) {
      if (!user || this.isActive(user) || (user && !user.activationCodeExpires)) return false
      return parseInt(user.activationCodeExpires) < moment().unix()
    },

    isBlocked (user) {
      return user.active === 'Blocked'
    },

    isActive (user) {
      return user.active !== 'No'
    },

    handleSelectionChange(val) {
      this.selected = val;
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.buttons {
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.red {
  color: #b43434 !important;
}
</style>
