<template>
  <modal
    title="Project Scope and Schedule A Pricing"
    :width="1200"
    @close="$emit('close')">
    <div class="tabs">
      <el-button
        :type="activeTab ? 'default' : 'primary'"
        class="tab"
        @click="activeTab = 0">
        Project Scope
      </el-button>
      <el-button
        :type="!activeTab ? 'default' : 'primary'"
        class="tab"
        @click="activeTab = 1">
        Schedule A Pricing
      </el-button>
    </div>

    <project-scope-create-form
      v-show="activeTab === 0"
      v-model="form"
      :next-id="data && data.id ? data.id : nextId"
      :validator="$v" />

    <schedule-pricing-form
      v-show="activeTab === 1"
      v-model="form"
      :validator="$v" />

    <div class="buttons">
      <el-button icon="el-icon-check" type="primary" @click="save()">Submit</el-button>
      <el-button @click="cancel()">Cancel</el-button>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import schedulePricingMixin from './schedule-pricing-mixin'

import Modal from '../../common/Modal'
import SchedulePricingForm from './SchedulePricingForm'
import ProjectScopeCreateForm from './ProjectScopeCreateForm'

export default {
  components: {
    Modal,
    SchedulePricingForm,
    ProjectScopeCreateForm
  },

  mixins: [ schedulePricingMixin ],

  props: {
    nextId: {
      type: Number,
      default: null
    },

    data: {
      type: Object,
      default: null
    }
  },


  data () {
    return {
      form: {
        build: null,
        build2: null,
        build3: null,
        name: null,
        start_date: null,
        end_date: null,
        scope_of_work: null,
        customizations: null,
        client_to_provide: null,
        additional_notes: null,
      },

      activeTab: 0
    }
  },

  created () {
    this.load()
  },

  methods: {
    ...mapActions({
      load: 'system/builds/load'
    }),

    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('submit', this.form)
      } else {
        const projectFields = ['build', 'name', 'start_date', 'end_date']
        const projectInvalid = projectFields.some(key => this.$v.form[key].$invalid)
        if (!projectInvalid && this.activeTab === 0) this.activeTab = 1
        if (projectInvalid && this.activeTab === 1) this.activeTab = 0
      }
    },

    cancel () {
      this.$emit('close')
    }
  },

  validations () {
    const result = {
      form: {
        build: { required },
        name: { required },
        start_date: { required },
        end_date: { required }
      }
    }
    this.prices.forEach(key => {
      result.form[key] = { required }
    })
    return result
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .tab {
    width: 100%;
  }
}
</style>
