<template>
  <div>
    <div v-for="type in contacts" :key="type">
      <div class="title">
        <h4>{{ contactTypes[type] }}</h4>
        <template v-if="['TechnicalContact', 'BillingContact'].includes(type)">
          <el-checkbox v-model="sameAs[type].mc" @change="updateContact($event, type, 'MainContact')">Same as Main Contact</el-checkbox>
          <el-checkbox v-model="sameAs[type].sc" @change="updateContact($event, type, 'SecondaryContact')">Same as Secondary Contact</el-checkbox>
        </template>
      </div>
      <div class="row">
        <div class="col">
          <ex-input v-model="form[type].firstName" label="First Name" :validator="v.form[type] ? v.form[type].firstName : null" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form[type].lastName" label="Last Name" :validator="v.form[type] ? v.form[type].lastName : null" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form[type].jobTitle" label="Title" :validator="v.form[type] ? v.form[type].jobTitle : null" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form[type].email" type="email" label="Email" :validator="v.form[type] ? v.form[type].email : null" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form[type].phone" label="Phone" :validator="v.form[type] ? v.form[type].phone : null" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form[type].ext" label="Ext" full no-style />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'

export default {
  components: {
    ExInput,
    ExSelect
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      contacts: ['MainContact', 'SecondaryContact', 'TechnicalContact', 'BillingContact'],
      contactKeys: ['firstName', 'lastName', 'jobTitle', 'email', 'phone', 'ext'],
      contactTypes: {
        MainContact: 'Main Contact',
        SecondaryContact: 'Secondary Contact',
        TechnicalContact: 'Technical Contact',
        BillingContact: 'Billing Contact'
      },

      sameAs: {
        TechnicalContact: {
          mc: false,
          sc: false
        },
        BillingContact: {
          mc: false,
          sc: false
        }
      },
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  created () {
    this.contacts.forEach(type => {
      const payload = {
        firstName: null,
        lastName: null,
        jobTitle: null,
        email: null,
        phone: null,
        ext: null
      }

      this.$set(this.form, type, payload)
    })
  },

  methods: {
    updateContact (value, type, key) {
      if (value) {
        this.sameAs[type][key === 'MainContact' ? 'sc' : 'mc'] = false
      }
      this.contactKeys.forEach(v => {
        this.form[type][v] = value ? this.form[key][v] : null
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  h4 {
    display: inline-block;
    width: 200px;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
</style>