var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-edit", type: "primary" },
              on: {
                click: function($event) {
                  _vm.completeForm = true
                }
              }
            },
            [_vm._v("New")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.scopes, stripe: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "Status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "status" }, [
                          _c("i", {
                            staticClass: "el-icon-s-claim",
                            class: {
                              success: _vm.isSigned(scope.row),
                              warning: !_vm.isSigned(scope.row)
                            }
                          })
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Scope #" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.scopeId) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Date Created" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(scope.row.createdAt)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Project Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.name) + "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Client Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.client.firstName) +
                            " " +
                            _vm._s(scope.row.client.lastName) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Signed Scope" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.isSigned(scope.row) && scope.row.signedFile
                          ? [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/download/ClientDocument/" +
                                      scope.row.signedFile.id,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.signedFile.file) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          : [_vm._v("N/A")]
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Options", width: "300", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.isSigned(scope.row)
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openEdit(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("\n              Edit\n            ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/client/scope/" + scope.row.id + "/pdf",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("el-button", { attrs: { size: "mini" } }, [
                                    _vm._v("View")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled: !scope.row,
                                    type: "primary",
                                    icon: "el-icon-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.$refs["upload" + scope.row.id].click()
                                    }
                                  }
                                },
                                [_vm._v("Upload signed")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false"
                                  }
                                ],
                                ref: "upload" + scope.row.id,
                                attrs: { type: "file" },
                                on: {
                                  change: function($event) {
                                    return _vm.uploadFile($event, scope.row.id)
                                  }
                                }
                              })
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isSigned(scope.row)
                          ? [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/download/ClientDocument/" +
                                      scope.row.signedFile.id,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("el-button", { attrs: { size: "mini" } }, [
                                    _vm._v("View Signed")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(scope.row.id)
                                    }
                                  }
                                },
                                [_vm._v("Remove Signed")]
                              )
                            ]
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.completeForm
        ? _c("project-scope-schedule-pricing-modal", {
            attrs: { data: _vm.edit, "next-id": _vm.nextId },
            on: {
              close: function($event) {
                return _vm.close()
              },
              submit: function($event) {
                return _vm.create($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }