var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-holder" },
    [
      _vm.image
        ? _c("span", {
            staticClass: "remove el-icon-error",
            on: {
              click: function($event) {
                return _vm.remove()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "image" }, [
        _vm.image
          ? _c("img", { attrs: { src: _vm.image } })
          : _c("img", {
              staticClass: "plugg",
              attrs: { src: "/assets/images/black-logo.png" }
            })
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { size: "mini", type: "primary", icon: "el-icon-picture" },
          on: {
            click: function($event) {
              return _vm.$refs.upload.click()
            }
          }
        },
        [_vm._v("Upload image")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "upload",
        attrs: { type: "file" },
        on: { change: _vm.uploadFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }