var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("sidebar", {
        attrs: { active: _vm.active },
        model: {
          value: _vm.expanded,
          callback: function($$v) {
            _vm.expanded = $$v
          },
          expression: "expanded"
        }
      }),
      _vm._v(" "),
      _c(
        "section",
        { class: { expanded: _vm.expanded } },
        [
          _c("main-header"),
          _vm._v(" "),
          _c("main", [_vm._t("default")], 2),
          _vm._v(" "),
          _c("main-footer", { staticClass: "footer" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }