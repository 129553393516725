<template>
  <div>
    <div class="table-wrapper">
      <div class="info-box">
        <h3>Client Agreement</h3>
      </div>
      <el-table
        v-loading="loading"
        :data="[null]" style="min-width: 100%" stripe>
        <el-table-column label="Status">
          <div class="status">
            <i :class="{
              'el-icon-s-claim': data,
              'el-icon-s-release': !data,
              warning: data && !signed,
              success: signed
            }" />
          </div>
        </el-table-column>

        <el-table-column v-if="signed" label="Filename">
          <a :href="signedFileUrl" target="_blank">{{ data.signedFile.file }}</a>
        </el-table-column>

        <el-table-column label="Name">
          Client Agreement
        </el-table-column>

        <el-table-column label="Created by">
          <template v-if="!data">N/A</template>
          <template v-else>
            {{ data.createdBy.firstName }} {{ data.createdBy.lastName }}
          </template>
        </el-table-column>

        <el-table-column label="Created at">
          <template v-if="!data">N/A</template>
          <template v-else>
            {{ data.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Signed at">
          <template v-if="!signed">N/A</template>
          <template v-else>
            {{ data.signedAt | date }}
          </template>
        </el-table-column>

        <el-table-column v-if="!registration.userId" label="Actions" align="right" width="350">
          <el-button v-if="!data" :disabled="!psspSigned" size="mini" type="primary" @click="create()">Create</el-button>
          <template v-else>
            <el-button size="mini" type="danger" @click="remove()">{{ !signed ? 'Remove' : 'Remove Signed' }}</el-button>
            <a :href="viewUrl" target="_blank">
              <el-button size="mini" :disabled="!data" icon="el-icon-document">View</el-button>
            </a>
            <el-button v-if="!signed" size="mini" :disabled="!data" icon="el-icon-edit" @click="$refs.upload.click()">Upload signed</el-button>
            <input v-show="false" ref="upload" type="file" @change="uploadFile" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import helperMixin from '../../common/helpers-mixin'

export default {
  name: 'ClientAgreement',

  mixins: [helperMixin],

  data () {
    return {
      type: 'ClientAgreement',
      loading: false
    }
  },

  computed: {
    data () {
      return this.registration[this.type]
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    signedFileUrl () {
      if (!this.data || !this.data.signedFile) return null
      return `/download/ClientDocument/${this.data.signedFile.id}`
    },

    signed () {
      return this.data && this.data.signedAt && this.data.signedFile
    },

    pssp () {
      return this.registration ? this.registration.ProjectScopeSchedulePricing : null
    },

    psspSigned () {
      return this.pssp && this.pssp.completedBy && this.pssp.signedFile
    },

    viewUrl () {
      if (!this.data || !this.data.file) return null
      return `/download/ClientDocument/${this.data.file.id}`
    }
  },

  methods: {
    ...mapActions({
      createChecklist: 'client/single/createChecklist',
      deleteChecklist: 'client/single/deleteChecklist',
      generateClientAgreementPdf: 'client/single/generateClientAgreementPdf',
      updateChecklist: 'client/single/updateChecklist',
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument',
      loadChecklist: 'client/single/loadChecklist'
    }),

    async remove () {
      if (this.signed) {
        const payload = {
          signedAt: null,
          signedFile: null
        }
        await this.updateChecklist({ id: this.data.id, type: this.type, payload })
      } else {
        await this.deleteChecklist({ type: this.type, id: this.data.id })
      }
    },

    async uploadFile ($e) {
      let file = $e.target.files[0]
      if (file) {
        this.loading = true
        file = this.changeFileName(file, `${this.registration.company} - Client Agreement`)
        const document = await this.createDocument({
          type: 'Client Agreement',
          clientRegId: this.registration.id,
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.uploadDocument({ id: document.id, file })
        await this.updateChecklist({
          id: this.data.id,
          type: this.type,
          payload: {
            signedAt: 'CURRENT_TIMESTAMP',
            signedFile: document.id,
          }
        })
        this.loading = false
      }
    },

    async create () {
      this.loading = true
      const payload = {
        clientRegId: this.registration.id,
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id,
        signedAt: null,
        signedFile: null
      }
      const id = (await this.createChecklist({ type: this.type, payload })).id
      await this.generateClientAgreementPdf(this.registration.id)
      await this.loadChecklist({ type: this.type, id: this.registration.id, force: true })
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>