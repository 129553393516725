var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("panel", { attrs: { title: _vm.currentPage.text } }, [
        _c("div", { staticClass: "scheduler-panel" }, [
          _c("div", { staticClass: "panel-title" }, [
            _c(
              "div",
              { staticClass: "panel-action" },
              [
                _c("calendar-date-select", {
                  attrs: { day: _vm.day, month: _vm.month, year: _vm.year },
                  on: {
                    "update:month": function($event) {
                      _vm.month = $event
                    },
                    "update:year": function($event) {
                      _vm.year = $event
                    },
                    day: function($event) {
                      _vm.day = $event
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "panel-date" },
              [
                _c(
                  "chButton",
                  {
                    attrs: { color: "gray", disabled: !_vm.selected },
                    on: {
                      click: function($event) {
                        return _vm.viewEvent()
                      }
                    }
                  },
                  [_vm._v("View Event")]
                ),
                _vm._v(" "),
                _c(
                  "chButton",
                  {
                    attrs: {
                      color: "gray",
                      disabled: !_vm.selected || _vm.eventNotCreatedByMe
                    },
                    on: {
                      click: function($event) {
                        return _vm.editEvent()
                      }
                    }
                  },
                  [_vm._v("Edit Event")]
                ),
                _vm._v(" "),
                _c(
                  "chButton",
                  {
                    attrs: {
                      color: "gray",
                      disabled: !_vm.selected || _vm.eventNotCreatedByMe
                    },
                    on: {
                      click: function($event) {
                        return _vm.deleteEvent()
                      }
                    }
                  },
                  [_vm._v("Delete Event")]
                ),
                _vm._v(" "),
                _c(
                  "chButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.addEvent()
                      }
                    }
                  },
                  [_vm._v("Add new Event")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "panel-body-scheduler events-panel-body" },
            [
              _c("h1", [_vm._v("Events")]),
              _vm._v(" "),
              _vm.events
                ? [
                    _vm.events.length
                      ? _c(
                          "ul",
                          { staticClass: "list" },
                          _vm._l(_vm.events, function(event) {
                            return _c(
                              "li",
                              {
                                key: event.id,
                                staticClass: "list-item",
                                class: { selected: _vm.selected === event },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleSelect(event)
                                  }
                                }
                              },
                              [
                                _c("div", {
                                  staticClass: "circle",
                                  class: event.color
                                    ? "event-" + event.color.toLowerCase()
                                    : "event-blue"
                                }),
                                _vm._v(" "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.formatEventTime(event)) + " "
                                  ),
                                  event.employees.length
                                    ? _c("a", { staticClass: "link" }, [
                                        _vm._v(
                                          " - created by " +
                                            _vm._s(event.createdBy.firstName)
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(
                                  ": " +
                                    _vm._s(event.subject) +
                                    "\n              "
                                ),
                                event.note
                                  ? _c("span", [
                                      _c("br"),
                                      _vm._v(_vm._s(event.note))
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                event.employees.length
                                  ? [
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "tags small" },
                                        _vm._l(event.employees, function(item) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: item.id,
                                              staticClass: "mr-5",
                                              attrs: {
                                                effect: "dark",
                                                size: "mini",
                                                closable:
                                                  event.createdBy.id ===
                                                    _vm.userId ||
                                                  item.user_id.id === _vm.userId
                                              },
                                              on: {
                                                close: function($event) {
                                                  return _vm.removeEmployee(
                                                    event,
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    item.user_id.firstName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    item.user_id.lastName
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          }),
                          0
                        )
                      : _c("span", [
                          _vm._v("There are no events for selected date.")
                        ])
                  ]
                : _c("div", [_vm._v("\n          Loading...\n        ")])
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("manageEventModal", {
        ref: "eventModal",
        attrs: {
          type: _vm.type,
          "preselected-date": _vm.add ? new Date(_vm.selectedDate) : null,
          event: _vm.add ? null : _vm.selected,
          edit: _vm.editingEvent,
          viewOnly: _vm.eventNotCreatedByMe
        },
        on: {
          setEdit: function($event) {
            _vm.editingEvent = $event
          },
          save: function($event) {
            return _vm.onSaveEvent($event)
          },
          "remove-tag": function($event) {
            return _vm.removeTag($event)
          },
          delete: function($event) {
            return _vm.onEventDelete($event)
          }
        },
        model: {
          value: _vm.showEvent,
          callback: function($$v) {
            _vm.showEvent = $$v
          },
          expression: "showEvent"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }