var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { active: _vm.active } }, [
    _c("div", { staticClass: "add-btn" }, [
      _c(
        "a",
        { attrs: { href: "/system/create-staff-users" } },
        [
          _c("el-button", {
            attrs: {
              size: "mini",
              type: "success",
              icon: "el-icon-circle-plus",
              circle: ""
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c(
              "el-table",
              {
                staticStyle: { "min-width": "100%" },
                attrs: { data: _vm.list, stripe: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Emp ID" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/system/employee/" + scope.row.id
                                }
                              },
                              [_c("b", [_vm._v(_vm._s(scope.row.uuid))])]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3588021313
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "First Name" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_vm._v(_vm._s(scope.row.firstName))]
                        }
                      }
                    ],
                    null,
                    false,
                    4200627136
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Last Name" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_vm._v(_vm._s(scope.row.lastName))]
                        }
                      }
                    ],
                    null,
                    false,
                    1746168496
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Email" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_vm._v(_vm._s(scope.row.email))]
                        }
                      }
                    ],
                    null,
                    false,
                    996098065
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Role" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v(_vm._s(_vm._f("role")(scope.row.role)))]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4016417791
                  )
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }