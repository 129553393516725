<template>
  <layout active="/clients/projects">
    <div>
      <ex-select v-model="status" :options="statusOptions" no-margin />
      <el-button size="mini" type="primary" @click="load()">Submit</el-button>
    </div>

    <div class="table-wrapper">
      <el-table v-loading="loading" :data="projects" stripe>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <el-tag effect="dark" size="mini">{{ scope.row.status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Project Build">
          <template slot-scope="scope">
            <a @click="openEdit(scope.row)">
              <b>99{{ scope.row.id }}</b>
            </a>
          </template>
        </el-table-column>
        <el-table-column label="Setup Date">
          <template slot-scope="scope">
            {{ scope.row.start_date | date }}
          </template>
        </el-table-column>
        <el-table-column label="Project Name">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="Client ID">
          <template slot-scope="scope">
            <a :href="`/clients/registrations/${scope.row.client.id}`">
              <b>{{ scope.row.client.userId.uuid }}</b>
            </a>
          </template>
        </el-table-column>
        <el-table-column label="Company Name">
          <template slot-scope="scope">
            {{ scope.row.client.company }}
          </template>
        </el-table-column>
        <el-table-column label="Tracker">
          <template slot-scope="scope">
            <template v-if="getTracker(scope.row)">
              {{ getTracker(scope.row) }}
            </template>
            <template v-else>N/A</template>
          </template>          
        </el-table-column>
        <el-table-column label="Options" width="100" align="right"></el-table-column>
      </el-table>
    </div>

    <manage-project
      v-if="edit"
      :edit="edit"
      @close="edit = null" />
  </layout>
</template>

<script>
import Panel from '../common/Panel'
import Layout from '../common/Layout'
import ExSelect from '../common/Select'
import ManageProject from '../clients/projects/ManageProject'

export default {
  name: 'Projects',

  components: {
    Panel,
    Layout,
    ExSelect,
    ManageProject
  },

 data () {
    return {
      edit: null,
      status: null,
      loading: false,
      data: []
    }
  },

  computed: {
    projects () {
      return this.$store.state.projects.data
    },

    statusOptions () {
      return Object.keys(window.projectStatuses).map(key => {
        return {
          id: key,
          text: `${key} - ${window.projectStatuses[key]}`
        }
      })
    }
  },

  created () {
    this.load()
  },

  methods: {
    async load () {
      this.loading = true
      const params = this.status ? { status: this.status } : null
      await this.$store.dispatch('projects/load', {
        params,
        order: ['id:DESC']
      })
      this.loading = false
    },

    async openEdit (project) {
      const loading = this.$loading()
      await this.$store.dispatch('client/single/get', { id: project.client.id, force: true })
      await this.$store.dispatch('client/single/loadChecklist', { type: 'ClientAssign', id: project.client.id })
      this.edit = project
      loading.close()
    },

    getTracker (project) {
      const tracker = project.trackers && project.trackers.length ? project.trackers[project.trackers.length - 1] : null
      if (!tracker) return 'N/A'
      return moment(tracker.createdAt).format('LLL')
    }
  }
}
</script>