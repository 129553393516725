var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message
    ? _c("div", { staticClass: "notifications" }, [
        _c("div", { staticClass: "flex" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "t-text" }, [
            _c(
              "h2",
              { ref: "overflow", class: { "none-overflow": _vm.readMore } },
              [_vm._v(_vm._s(_vm.message.message))]
            ),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm._f("date")(_vm.message.createdAt)))]),
            _vm._v(" "),
            _c("div", { staticClass: "arrows" }, [
              _c(
                "div",
                {
                  class: { active: this.messages.length > 1, arrow: true },
                  on: {
                    click: function($event) {
                      return _vm.prev()
                    }
                  }
                },
                [_c("img", { attrs: { src: "/assets/images/left-arrow.png" } })]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { active: this.messages.length > 1, arrow: true },
                  on: {
                    click: function($event) {
                      return _vm.next()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: "/assets/images/right-arrow-new.png" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "counter" }, [
                _vm._v(
                  "(" +
                    _vm._s(_vm.index + 1) +
                    " / " +
                    _vm._s(_vm.messages.length) +
                    ")"
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.isEllypsisActive
            ? _c("div", { staticClass: "read-more-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "read-more",
                    on: {
                      click: function($event) {
                        _vm.readMore = !_vm.readMore
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.readMore ? "Read less" : "Read more"))]
                )
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-t" }, [
      _c("img", { attrs: { src: "/assets/images/chat.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }