var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { active: "/tools/scheduler" } },
    [
      _c("scheduler-menu"),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: _vm.title } },
        [_c("scheduler-dashboard")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }